import InputError from "components/InputError";
import Label from "components/Label";
import { IBaseInputCommonProps } from "interface/component/Input";
import { InputText, InputTextProps } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

export interface IBaseInputProps extends InputTextProps, IBaseInputCommonProps {
  iconCenter?: boolean;
}

const BaseInput: React.FC<IBaseInputProps> = React.forwardRef(
  (props: IBaseInputProps, ref: any) => {
    const {
      hideLabel,
      label,
      labelClassNames,
      labelStyleObj,
      validation,
      hideErrorRow,
      errorClassNames,
      errorStyleObj,
      toolTipContent,
      toolTipClassName,
      inputIcon,
      iconCenter,
      inputIconHover,
      inputIconPos,
      ...PrimeReactProps
    } = props;

    const [iconHover, setIconHover] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const { placeholder, className, value, style, name, onChange } = PrimeReactProps;

    useEffect(() => {
      setIsEmpty(value === "");
    }, [value]);

    return (
      <div className="group grid">
        <span
          className={`p-float-label ${isEmpty ? "emptyInput" : ""} relative`}
          onMouseOver={() => setIconHover(true)}
          onMouseOut={() => setIconHover(false)}
        >
          <InputText
            placeholder={placeholder}
            style={style}
            value={value}
            name={name}
            ref={ref}
            className={className}
            onChange={onChange}
            {...PrimeReactProps}
          />

          {inputIcon ? (
            <div className={`absolute top-1.5 right-0 py-2 px-0.752 bg-white `}>
              <img
                src={iconHover ? inputIconHover : inputIcon}
                alt="input-icon"
                className="w-2.5 h-2.5"
              />
            </div>
          ) : null}

          {!hideLabel && (
            <div className="inputLabel">
              <Label
                label={label}
                labelClassNames={labelClassNames}
                labelStyleObj={labelStyleObj}
                isInvalid={validation}
                toolTipContent={toolTipContent}
                toolTipClassName={toolTipClassName}
              />
            </div>
          )}
        </span>

        {!hideErrorRow && (
          <InputError
            validation={validation}
            hideErrorRow={hideErrorRow}
            errorClassNames={errorClassNames}
          />
        )}
      </div>
    );
  }
);

export default BaseInput;
