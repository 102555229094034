import { BASE_PANEL_STYLE, DROP_DOWN_STYLES } from "assets/base-theme";
import BaseDropDown, { IBaseDropDownProps } from "components/base/BaseDropDown";
import { StyleObjectInterface } from "interface/common";
import { IInputCommonStyleProps } from "interface/component/Input";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateClasses } from "utils/Util";

interface IDropDownProps extends IBaseDropDownProps, IInputCommonStyleProps {
  panelStyleObj?: object;
}
const SimpleDropDownWithoutMemo: React.FC<IDropDownProps> = (props: IDropDownProps) => {
  const getClassNames = () => {
    const styleObject: StyleObjectInterface = {
      ...DROP_DOWN_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.className,
      isDisabled: props.disabled,
      isInvalid: props.validation ? true : false,
      removeStyleProperty: [],
      removeClasses: [],
    };

    let classNamesObject = generateClasses(styleObject);

    if (props.variant !== "form") {
      classNamesObject =
        classNamesObject +
        "border-primary-gray-300 border rounded-md px-2.8 py-0 focus-within:border-b active:border-b";
    }

    const panelStyleObject: StyleObjectInterface = {
      primaryStyle: BASE_PANEL_STYLE,
      disabledStyleProperty: [
        "inputDisabledBackgroundColor",
        "inputDisabledBorderColor",
        "inputDisabledTextColor",
        "inputDisabledBorderSize",
      ], // Property name for disable classes
      overrideStyle: props.panelStyleObj,
      removeStyleProperty: [],
      removeClasses: [],
    };

    const panelClassNamesObject = generateClasses(panelStyleObject);

    return [classNamesObject, panelClassNamesObject];
  };

  let getClassNameValues = getClassNames();
  let classNames = getClassNameValues[0];
  let panelClassNames = getClassNameValues[1];

  const {
    value,
    onChange,
    name,
    placeholder,
    label,
    options,
    variant,
    dataKey,
    filterInputAutoFocus,
  } = props;

  const { t } = useTranslation();
  let placeholderLabel = t(placeholder ?? "");

  return (
    <>
      <BaseDropDown
        {...props}
        variant={variant ?? "button"}
        className={classNames}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholderLabel}
        label={label}
        options={options}
        panelClassName={panelClassNames}
        dataKey={dataKey}
        filterInputAutoFocus={filterInputAutoFocus}
      />
    </>
  );
};

// ------------------ Memoization -------------------------- //
const SimpleDropDown = React.memo(SimpleDropDownWithoutMemo);

// ------------------ redux wiring ------------------------- //

// --------------------- exports --------------------------- //
export default SimpleDropDown;
