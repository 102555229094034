import { linkPortalAdminRoute } from "portalAdminRoutes";
import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";
import { linkAuthRoute } from "routes";

export interface RouteProps {
  navigate?: any;
  navigateNoAuth?: any;
  location?: any;
  navigateAsAdmin?: any;
  goBack?: any;
}
export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onNavigateWithAuth = (path: string, options?: NavigateOptions) => {
      navigate(`${linkAuthRoute}/${path}`, options);
    };

    const onNavigateAsAdmin = (path: string, options?: NavigateOptions) => {
      navigate(`${linkPortalAdminRoute}/${path}`, options);
    };

    const onNavigateBack = () => {
      navigate(-1);
    };

    return (
      <Component
        navigate={onNavigateWithAuth}
        navigateNoAuth={navigate}
        navigateAsAdmin={onNavigateAsAdmin}
        location={location}
        goBack={onNavigateBack}
        {...props}
      />
    );
  };

  return Wrapper;
};
