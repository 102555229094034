/**
 * Author : Harshavardhan
 * Created on : 20 Feb, 2023
 **/

import playIcon from "assets/img/common/paly_icon.svg";
import tertiaryButtonIcon from "assets/img/common/playIcon_tertiary.svg";
import tertiaryButtonIconHover from "assets/img/common/playIcon_tertiary_hover.svg";
import ButtonBox from "components/ButtonBox";
import ButtonIcon from "components/ButtonIcon";
import SkeletonCustom from "components/Skeleton";
import TertiaryButton from "components/TertiaryButton";
import Text from "components/Text";
import React from "react";

interface IOverviewCardProps {
  className?: string;
  title?: string;
  description?: string | React.ReactNode;
  overviewImage?: any;
  buttonLabel?: string;
  closeIcon?: boolean;
  onClick?: (e: any) => void;
  onCloseButtonClick?: (e: any) => void;
  primaryButton?: boolean;
  loader?: boolean;
}

const OverviewCardWithoutMemo: React.FC<IOverviewCardProps> = (props: IOverviewCardProps) => {
  const {
    className,
    title,
    description,
    overviewImage,
    buttonLabel,
    closeIcon,
    onClick,
    onCloseButtonClick,
    primaryButton,
    loader,
  } = props;

  if (loader) {
    return (
      <div
        className={`w-full flex flex-col-reverse sm:flex-row justify-between rounded-lg overflow-hidden bg-white ${
          className ?? ""
        }`}
      >
        <div className="mt-9 mx-6 mb-6 sm:w-3/5 lg:w-1/2 lg:ml-6 lg:mr-9 lg:my-0 flex flex-col justify-center text-left">
          <div className="text-h3 uppercase font-BrownRegular mb-3">
            <SkeletonCustom height="2.5rem" width="25%" borderRadius="8px" />
          </div>

          <div className="font-BrownLight text-body-copy-1 mb-6">
            <SkeletonCustom height="1rem" width="100%" borderRadius="8px" />
          </div>

          <div className="w-30">
            <SkeletonCustom height="2.5rem" width="100%" borderRadius="21px" />
          </div>
        </div>

        <div className="w-full sm:w-2/5 lg:w-1/2">
          <div className="relative w-full">
            {closeIcon ? (
              <div className="absolute w-6 h-6 bg-white rounded-full top-6 right-6">
                <ButtonIcon type="CLOSE" onClick={onCloseButtonClick} />
              </div>
            ) : null}
            <SkeletonCustom height="15rem" width="100%" />
            {/* <img src={overviewImage} alt="Overview" className="w-full h-60 object-cover" /> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`w-full flex flex-col-reverse sm:flex-row justify-between rounded-lg overflow-hidden bg-white ${
        className ?? ""
      }`}
    >
      <div className="mt-9 mx-6 mb-6 sm:w-3/5 lg:w-1/2 lg:ml-6 lg:mr-9 lg:my-0 flex flex-col justify-center text-left">
        <div className="text-h3 uppercase font-BrownRegular mb-3">
          <Text label={title} />
        </div>

        <div className="font-BrownLight text-body-copy-1 mb-6">
          <Text label={description} />
        </div>

        <div className="w-30">
          {primaryButton ? (
            <ButtonBox
              label={buttonLabel}
              icon={
                <div>
                  <img src={playIcon} alt="Button-icon" />
                </div>
              }
              iconPos="left"
              variant="contained"
              onClick={onClick}
            />
          ) : (
            <TertiaryButton
              label={buttonLabel}
              className="font-BrownRegular"
              icon={
                <div>
                  <img src={tertiaryButtonIcon} alt="Button-icon" />
                </div>
              }
              iconHover={
                <div>
                  <img src={tertiaryButtonIconHover} alt="Button-icon" />
                </div>
              }
              onClick={onClick}
            />
          )}
        </div>
      </div>

      <div className="w-full sm:w-2/5 lg:w-1/2">
        <div className="relative w-full">
          {closeIcon ? (
            <div className="absolute w-6 h-6 bg-white rounded-full top-6 right-6">
              <ButtonIcon type="CLOSE" onClick={onCloseButtonClick} />
            </div>
          ) : null}

          <img src={overviewImage} alt="Overview" className="w-full h-60 object-cover" />
        </div>
      </div>
    </div>
  );
};

// ----------- memoization -------------------- //
const OverviewCard = React.memo(OverviewCardWithoutMemo);

// ----------- redux wiring ------------------- //

// ----------- exports ------------------------ //
export default OverviewCard;
