import InputError from "components/InputError";
import Label from "components/Label";
import { IBaseInputCommonProps } from "interface/component/Input";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import React from "react";

export interface IBaseDropDownProps extends DropdownProps, IBaseInputCommonProps {
  variant?: string;
}

const BaseDropDown: React.FC<IBaseDropDownProps> = (props: IBaseDropDownProps) => {
  const {
    hideLabel,
    label,
    labelStyleObj,
    validation,
    labelClassNames,
    hideErrorRow,
    errorClassNames,
    toolTipContent,
    toolTipClassName,
    editable,
    ...PrimeReactProps
  } = props;

  const {
    placeholder,
    className,
    value,
    panelClassName,
    disabled,
    inputId,
    optionLabel,
    options,
    optionValue,
    onChange,
    filterInputAutoFocus,
    dataKey,
  } = PrimeReactProps;

  return (
    <div className="group grid">
      <span className="p-float-label">
        <Dropdown
          appendTo="self"
          className={className}
          disabled={disabled}
          inputId={inputId ? inputId : ""}
          optionLabel={optionLabel}
          options={options}
          optionValue={optionValue}
          panelClassName={panelClassName}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          dataKey={dataKey}
          filterInputAutoFocus={filterInputAutoFocus}
          editable={editable}
          {...PrimeReactProps}
        />

        {!hideLabel && (
          <div className="inputLabel">
            <Label
              label={label}
              labelClassNames={labelClassNames}
              labelStyleObj={labelStyleObj}
              isInvalid={validation}
              toolTipContent={toolTipContent}
              toolTipClassName={toolTipClassName}
            />
          </div>
        )}
      </span>

      {!hideErrorRow && (
        <InputError
          validation={validation}
          hideErrorRow={hideErrorRow}
          errorClassNames={errorClassNames}
        />
      )}
    </div>
  );
};

BaseDropDown.defaultProps = {
  variant: "button",
};

export default BaseDropDown;
