import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/RootReducer";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist-indexeddb-storage";

const encrypt = encryptTransform({
  secretKey: "omg-this-is-some-secret-stuff",
});
const persistConfig = {
  key: "root",
  storage: storage("myDB"),
  transforms: [encrypt],
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};
// Only when persist is needed
// const store = createStore(persistedReducer, initialState, applyMiddleware(thunk)); //reducer, initialState, middleware

const store = createStore(rootReducer, initialState, applyMiddleware(thunk)); //reducer, initialstate, middleware

// Only when persist is needed
// export const persistedStore = persistStore(store);

store.subscribe(
  //called each time store is changed
  () => {
    console.log("Store Updated..");
    // console.log(store.getState());
  }
);

export default store;
