//eslint-disable-next-line
import * as portalAdminRoutes from "portalAdminRoutes";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import * as routes from "routes";
import { getEnvVariableValue } from "utils/CommonUtil";
import Loader from "./components/Loader";
import store from "./store";

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));
const DefaultAdminPortalLayout = React.lazy(() => import("./containers/DefaultAdminPortalLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/Page404"));
const PageLogin = React.lazy(() => import("./views/PageLogin"));
const PageAdminLogin = React.lazy(() => import("./views/PageAdminLogin"));
const PageSignUp = React.lazy(() => import("./views/PageSignUp"));
const PageVerifyOTP = React.lazy(() => import("./views/PageVerifyOTP"));
const PageBillingIndividual = React.lazy(
  () => import("./views/PageBillingIndividual/PageBillingIndividual")
);
const PageBillingOrganisation = React.lazy(() => import("./views/PageBillingOrganisation"));
const PageForgotPassword = React.lazy(() => import("./views/PageForgotPassword"));
const PageResetPassword = React.lazy(() => import("./views/PageResetPassword"));
const PagePaymentSuccessful = React.lazy(() => import("./views/PagePaymentSuccessful"));
const PageUnsubscribeSuccessful = React.lazy(() => import("./views/PageUnsubscribeSuccessful"));

/* eslint-disable */

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const pageContainer = document.getElementById("page-container");
    if (pageContainer) {
      pageContainer.scrollTop = 0;
    }
  }, [pathname]);

  return null;
}

function App(props: any) {
  const [isPlatformAdmin] = useState(getEnvVariableValue("REACT_APP_PLATFORM_ADMIN"));
  return (
    <Provider store={store}>
      {/* <PersistGate persistor={persistedStore}> */}
      <Router basename={routes.linkBasePath}>
        <React.Suspense fallback={<Loader />}>
          <ScrollToTop />
          <Routes>
            <Route path="*" element={<Page404 />} />
            <Route path={routes.linkPageBase} element={<PageLogin />} />
            <Route path={routes.linkPageLogin} element={<PageLogin />} />

            <Route path={routes.linkPageSignUpIndividual} element={<PageSignUp />} />
            <Route path={routes.linkPageSignUpOrganisation} element={<PageSignUp />} />
            <Route path={routes.linkPageVerifyOTP} element={<PageVerifyOTP />} />
            <Route path={routes.linkPageBillingIndividual} element={<PageBillingIndividual />} />
            <Route
              path={routes.linkPageBillingOrganisation}
              element={<PageBillingOrganisation />}
            />
            <Route path={routes.linkPagePaymentSuccessful} element={<PagePaymentSuccessful />} />
            <Route path={routes.linkPageForgotPassword} element={<PageForgotPassword />} />
            <Route path={routes.linkPageResetPassword} element={<PageResetPassword />} />
            <Route path={routes.linkPageChangePassword} element={<PageResetPassword />} />
            <Route
              path={routes.linkPageUnsubscribeSuccessful}
              element={<PageUnsubscribeSuccessful />}
            />

            <Route path={`${routes.linkAuthRoute}/*`} element={<DefaultLayout />} />
            {/* <Route path={routes.linkPageBase} element={<PageAdminLogin />} /> */}
            <Route path={portalAdminRoutes.linkAdminLogin} element={<PageAdminLogin />} />
            <Route
              path={`${portalAdminRoutes.linkPortalAdminRoute}/*`}
              element={<DefaultAdminPortalLayout />}
            />
          </Routes>
        </React.Suspense>
      </Router>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
