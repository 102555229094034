import {
  LEARNING_MENU_LIST_BEGIN,
  LEARNING_MENU_LIST_SUCCESS,
  LEARNING_MENU_LIST_FAILURE,
} from "../actions/1_ActionConstants";

const initialState = {
  learningMenus: [],
  loading: false,
  error: null,
};

export default function LearningReducer(state = initialState, action) {
  switch (action.type) {
    case LEARNING_MENU_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LEARNING_MENU_LIST_SUCCESS:
      console.log(state);
      return {
        ...state,
        loading: false,
        learningMenus: action.payload,
      };

    case LEARNING_MENU_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
