import closeIcon from "assets/img/common/close_icon.svg";
import closeIconHover from "assets/img/common/close_icon_hover.svg";
import arrowDownHover from "assets/img/common/downArrow_hover.svg";
import arrowDown from "assets/img/common/downArrow_icon.svg";
import editIcon from "assets/img/common/edit_icon_blue.svg";
import editIconHover from "assets/img/common/edit_icon_hover.svg";
import menuIcon from "assets/img/common/kebab-menu.svg";
import menuIconHover from "assets/img/common/kebab-menu_hover.svg";
import nextIcon from "assets/img/common/next-btn.svg";
import prevIcon from "assets/img/common/previous-btn.svg";
import mappingCloseIconHover from "assets/img/mapping/close_icon_hover.svg";
import pauseIcon from "assets/img/mapping/pause_icon.svg";
import pauseIconHover from "assets/img/mapping/pause_icon_hover.svg";
import redoIcon from "assets/img/mapping/redo_icon.svg";
import redoIconHover from "assets/img/mapping/redo_icon_hover.svg";
import fullScreenIcon from "assets/img/mapping/fullScreen_icon.svg";
import fullScreenIconHover from "assets/img/mapping/fullScreen_icon_hover.svg";
import minimizeIcon from "assets/img/mapping/minimize_icon.svg";
import minimizeIconHover from "assets/img/mapping/minimize_icon_hover.svg";
import retryIcon from "assets/img/admin/retry_icon_active.svg";
import retryIconHover from "assets/img/admin/retry_icon_active_hover.svg";
import retryIconDisabled from "assets/img/admin/retry_icon_inactive.svg";
import loadingIcon from "assets/img/admin/loading_icon.svg";
import Text from "components/Text/Text";
import { IButtonIcon } from "interface/component";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";

const BUTTON_TYPE = Object.freeze({
  EDIT_LABEL: {
    icon: editIcon,
    iconHover: editIconHover,
    size: " w-full h-full",
    label: "Edit details",
  },
  MENU: {
    icon: menuIcon,
    iconHover: menuIconHover,
    iconDisabled: menuIcon,
    size: " w-full h-full",
  },
  DOWN: {
    icon: arrowDown,
    iconHover: arrowDownHover,
    size: "w-full h-full",
  },
  NEXT: {
    icon: nextIcon,
    size: "w-full h-full",
  },

  PREV: {
    icon: prevIcon,
    size: "w-full h-full",
  },
  CLOSE: {
    icon: closeIcon,
    iconHover: closeIconHover,
    size: "w-full h-full",
  },
  MAPPING_CLOSE: {
    icon: closeIcon,
    iconHover: mappingCloseIconHover,
    size: "w-full h-full",
  },
  PAUSE: {
    icon: pauseIcon,
    iconHover: pauseIconHover,
    size: "w-full h-full",
  },
  REDO: {
    icon: redoIcon,
    iconHover: redoIconHover,
    size: "w-full h-full",
  },
  FULL_SCREEN: {
    icon: fullScreenIcon,
    iconHover: fullScreenIconHover,
    size: "w-full h-full",
  },
  MINIMIZE: {
    icon: minimizeIcon,
    iconHover: minimizeIconHover,
    size: "w-full h-full",
  },
  RETRY: {
    icon: retryIcon,
    iconHover: retryIconHover,
    iconDisabled: retryIconDisabled,
    size: "w-full h-full",
  },
  LOADING: {
    icon: loadingIcon,
    iconHover: loadingIcon,
    iconDisabled: loadingIcon,
    size: "w-full h-full",
  },
});

const ButtonIcon: React.FC<IButtonIcon> = (props: IButtonIcon) => {
  const {
    type,
    disabled,
    toggle,
    sizeFull,
    iconClassName,
    className,
    blinkStyles,
    ...primeReactProps
  } = props;
  const [iconHover, setIconHover] = useState(false);
  const [blinkStyle, setBlinkStyle] = useState("");

  useEffect(() => {
    let intervalId: any = null;
    let shouldSetBlinkStyle = true;

    if (blinkStyles) {
      intervalId = setInterval(() => {
        setIconHover((prevIconHover) => !prevIconHover);

        if (shouldSetBlinkStyle && blinkStyles) {
          setBlinkStyle(blinkStyles);
        } else {
          setBlinkStyle("");
        }

        // Invert the flag for the next iteration
        shouldSetBlinkStyle = !shouldSetBlinkStyle;
      }, 500);

      setTimeout(() => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        setIconHover(false);
        setBlinkStyle("");
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [blinkStyles]);

  const getClassNames: any = () => {
    let classNames = "";
    return classNames;
  };

  const getIcon: any = (buttonType: any) => {
    let icon = BUTTON_TYPE[buttonType as keyof typeof BUTTON_TYPE];
    return icon;
  };

  let classNames: string = getClassNames();

  let icon: any = getIcon(type);

  const getBrightness: any = () => {
    return toggle
      ? ""
      : ` group-focus:brightness-75 ${iconClassName ? iconClassName : ""} w-3.5 h-3.5`;
  };

  const getImage = () => {
    if (iconHover) {
      return icon.iconHover;
    } else if (disabled) {
      return icon.iconDisabled;
    } else {
      return icon.icon;
    }
  };

  return (
    <div
      className={`${icon.size} flex items-center justify-center ${
        !disabled ? "cursor-pointer" : "rounded-full bg-secondary-baseConcrete"
      } ${blinkStyle}`}
      onMouseOver={() => setIconHover(!disabled && true)}
      onMouseOut={() => setIconHover(!disabled && false)}
    >
      <Button className={classNames || (className ?? "")} disabled={disabled} {...primeReactProps}>
        <img
          src={getImage()}
          className={
            disabled
              ? getBrightness()
              : `${!sizeFull ? ` ${iconClassName ? iconClassName : ""} w-3.5 h-3.5` : ""} `
          }
          alt=""
        />
        {icon.label && (
          <Text
            label={icon.label}
            className="ml-3 text-body-copy-1 text-secondary-blueAzure-500 font-BrownMedium cursor-pointer hover:text-primary-pText-900"
          />
        )}
      </Button>
    </div>
  );
};

export default ButtonIcon;
