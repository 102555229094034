/**
 * Author : Kaviraj RM
 * Created on : 5 April, 2023
 **/

import ImageIcon from "assets/img/admin/ImageIcon.svg";
import ButtonIcon from "components/ButtonIcon";
import Text from "components/Text";
import React from "react";

interface IAlertCardProps {
  className?: string;
  badgeValue?: string;
  title?: string;
  subTitle?: string;

  description: string | React.ReactNode;
  alertImage: string;
  onClick?: (e: any) => void;
  buttonIconType?: string;
}

const BeginnerCardWithoutMemo: React.FC<IAlertCardProps> = (props: IAlertCardProps) => {
  const {
    className,
    badgeValue,
    title,
    subTitle,
    description,
    alertImage,
    onClick,
    buttonIconType,
  } = props;

  return (
    <div
      className={`w-full py-6 px-6 flex flex-col-reverse sm:flex-row justify-between rounded-lg bg-white gap-6 ${
        className ?? ""
      }`}
    >
      <div className="w-full sm:w-3/5 lg:w-1/2  flex flex-col justify-center text-left">
        {badgeValue ? (
          <div className="px-2.5 py-1 rounded-lg bg-primary-gray-100 font-BrownLight flex items-center max-w-fit text-xxs text-primary-gray-700">
            <Text label={badgeValue} />
          </div>
        ) : null}

        <div className="flex">
          {title ? (
            <div className="text-3xl font-FreightProBook mb-6 mt-6 sm:mt-0">
              <Text label={title} />
            </div>
          ) : null}

          {subTitle ? (
            <div className="flex items-center mx-4 pl-2 mb-6 cursor-pointer">
              {buttonIconType && (
                <div className="w-28 h-3.5 ">
                  <ButtonIcon type={buttonIconType} onClick={onClick} className="flex" />
                </div>
              )}
            </div>
          ) : null}
        </div>

        <div className="font-BrownLight text-body-copy-1 mb-6 alert-card-description text-justify">
          <div>
            <div dangerouslySetInnerHTML={{ __html: description as string }} />
          </div>
        </div>
      </div>

      <div className="w-full sm:w-2/5 lg:w-1/2 flex sm:justify-end justify-center items-start">
        {alertImage ? (
          <img
            src={alertImage}
            alt="Alert"
            className="h-auto max-h-[240px] object-cover w-full rounded-lg"
          />
        ) : (
          <div className="bg-secondary-blueAzure-50 h-30 rounded-lg sm:w-100 flex justify-center items-center">
            <div className="w-12 h-12 bg-white opacity-70 rounded-full p-3 ">
              <img src={ImageIcon} alt="Information logo" className="w-6 h-6 " />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// ----------- memoization -------------------- //
const BeginnerCard = React.memo(BeginnerCardWithoutMemo);

// ----------- redux wiring ------------------- //

// ----------- exports ------------------------ //
export default BeginnerCard;
