import Text from "components/Text";
import React from "react";
import { IInputErrorProps } from "interface/component/Input";
import { COMMON_ERROR_STYLE } from "assets/base-theme";
import { generateClasses } from "utils/Util";

const InputError: React.FC<IInputErrorProps> = (props: IInputErrorProps) => {
  const { errorClassNames, errorStyleObj, hideErrorRow, validation, errorMessage } = props;
  const getClassNames = () => {
    const styleOBJECT = {
      primaryStyle: COMMON_ERROR_STYLE,
      overrideStyle: errorStyleObj,
      overrideClasses: errorClassNames,
    };

    return generateClasses(styleOBJECT);
  };

  return (
    <React.Fragment>
      {hideErrorRow ? (
        <></>
      ) : validation || errorMessage ? (
        <div
          className={`w-full truncate flex flex-row text-sm items-center h-4 mt-errorMargin ${getClassNames()}`}
        >
          <div className={`text-left text-primary-red-600 leading-tight truncate`}>
            <Text label={validation || errorMessage} className={`leading-4`} />
          </div>
        </div>
      ) : (
        <div className="h-4 text-sm text-left text-error leading-tight truncate mt-errorMargin" />
      )}
    </React.Fragment>
  );
};

export default InputError;
