import { TOAST_STYLES } from "assets/base-theme";
import close from "assets/img/common/Toast/Close.svg";
import checkFail from "assets/img/common/Toast/Fail.svg";
import checkInfo from "assets/img/common/Toast/Info.svg";
import check from "assets/img/common/Toast/Success.svg";
import checkWarning from "assets/img/common/Toast/Warning.svg";
import BaseToast, { IToastProps } from "components/base/BaseToast";
import Text from "components/Text";
import React from "react";
import { INPUT_ERROR_TYPE } from "utils/Constant";
import { generateClasses } from "utils/Util";
import i18n from "i18n";

interface ICToastProps extends IToastProps {
  variant?: string;
  toastText?: any;
  textClassName?: string;
  toastLife?: number;
}

const ToastWithoutMemo = React.forwardRef((props: any, ref: any) => {
  const getClassNames = () => {
    const styleObject = {
      ...TOAST_STYLES,
      overrideStyle: props.styleObj,
      overrideClasses: props.className,
      isDisabled: props.disabled,
      applyErrorStyle: props.validation && props.errorType === INPUT_ERROR_TYPE.BORDER,
      removeStyleProperty: [],
      removeClasses: [],
    };

    return generateClasses(styleObject, props.variant);
  };
  const onClose = (ref: any) => {
    ref?.clear();
  };
  React.useImperativeHandle(ref, () => ({
    showFunction() {
      let checkIcon, closeIcon;
      switch (props.variant) {
        case "PRIMARY":
          checkIcon = check;
          closeIcon = close;
          break;
        case "SECONDARY":
          checkIcon = checkInfo;
          closeIcon = close;
          break;
        case "TERTIARY":
          checkIcon = checkWarning;
          closeIcon = close;
          break;
        case "QUATERNARY":
          checkIcon = checkFail;
          closeIcon = close;
          break;
        case "QUINARY":
          checkIcon = checkWarning;
          closeIcon = close;
          break;
        default:
          checkIcon = check;
          closeIcon = close;
      }
      ref.show({
        life: props.toastLife ? props.toastLife : 3000,
        summary: (
          <div className="flex justify-between items-center my-auto">
            <div className="my-auto h-6 justify-center items-center contents">
              <img src={checkIcon} alt="check-icon" />

              {props.customContent ? (
                props.toastText()
              ) : (
                <div className="my-auto ml-4.5 w-57 flex ">
                  <Text
                    className={`text-xss font-light tracking-normal max-w-fit font-BrownLight text-primary-gray-700 line-clamp-3 ${
                      props.textClassName ?? ""
                    }`}
                    label={i18n.t(props.toastText)}
                  />
                </div>
              )}
            </div>
            <div
              className="w-6 h-6 flex justify-center items-center cursor-pointer"
              onClick={() => {
                onClose(ref);
                if (props.onRemove) {
                  props.onRemove();
                }
              }}
            >
              <img src={closeIcon} alt="close-icon" />
            </div>
          </div>
        ),
        contentClassName: getClassNames() + "min-w-75 ",
        //sticky: true,
      });
    },
  }));

  const { position, onRemove, onShow } = props;

  return (
    <>
      <BaseToast position={position} onRemove={onRemove} ref={(el) => (ref = el)} onShow={onShow} />
    </>
  );
});

// Memoization

const ToastCustom = React.memo(ToastWithoutMemo);
// redux wiring

// exports
export default ToastCustom;
