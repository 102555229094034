import { Skeleton, SkeletonProps } from "primereact/skeleton";
import React from "react";

const SkeletonCustom: React.FC<SkeletonProps> = (props: SkeletonProps) => {
  const { className, shape, size, width, height, borderRadius, animation } = props;

  return (
    <div className="group grid w-full">
      <Skeleton
        className={className}
        shape={shape}
        size={size}
        width={width}
        height={height}
        borderRadius={borderRadius}
        animation={animation}
      />
    </div>
  );
};

export default SkeletonCustom;
