import { getLocalStorage, getEnvVariableValue } from "utils/CommonUtil";
import { LOCAL_STORAGE, USER_TYPE } from "utils/Constant";

class HelperService {
  getUserInfo() {
    if (getLocalStorage(LOCAL_STORAGE.USER_INFO))
      return JSON.parse(getLocalStorage(LOCAL_STORAGE.USER_INFO));
    return false;
  }

  isAdmin() {
    if (getLocalStorage(LOCAL_STORAGE.USER_INFO)) {
      let userDetails = JSON.parse(getLocalStorage(LOCAL_STORAGE.USER_INFO));
      return userDetails.userType === USER_TYPE.PLATFORM_ADMIN;
    }
    return false;
  }

  getHeadersWithAuth() {
    if (this.isAdmin()) {
      return this.getHeadersNoTenantKey();
    }
    return {
      headers: {
        Authorization: "Bearer " + this.getUserInfo().accessToken,
        "content-type": "application/json",
        ...this.getTenantKey(),
      },
    };
  }

  getHeadersNoTenantKey() {
    return {
      headers: {
        Authorization: "Bearer " + this.getUserInfo().accessToken,
        "content-type": "application/json",
      },
    };
  }

  getHeaders(contentType?: string): object {
    return {
      "content-type": contentType ?? "application/json",
    };
  }

  getTenantKey() {
    let headers = {
      "x-tenant-key": getEnvVariableValue("REACT_APP_IL_TENANT_KEY"),
    };
    if (getLocalStorage(LOCAL_STORAGE.TENANT_KEY)) {
      headers = {
        "x-tenant-key": getLocalStorage(LOCAL_STORAGE.TENANT_KEY) ?? "",
      };
    }
    return headers;
  }
}

export default new HelperService();
