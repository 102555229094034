/**
 * Author : Harshavardhan
 * Created on : 20 Feb, 2023
 **/

import { Button, ButtonProps } from "primereact/button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface ITertiaryButtonProps extends ButtonProps {
  className?: string;
  iconHover?: any;
  args?: any;
  path?: string;
  iconClassName?: string;
  onClick?: (e: any) => void;
  handleFileDownload?: (e: any) => void;
}

const TertiaryButtonWithoutMemo: React.FC<ITertiaryButtonProps> = (props: ITertiaryButtonProps) => {
  const {
    className,
    iconHover,
    onClick,
    args,
    path,
    iconClassName,
    handleFileDownload,
    ...PrimeReactProps
  } = props;
  const { label, icon, iconPos, disabled, loading, loadingIcon } = PrimeReactProps;
  const [over, setOver] = useState(false);

  const { t } = useTranslation();
  let translatedLabel = t(label!, args);

  return (
    <div
      className={`w-full h-full flex justify-center items-center text-xss font-BrownLight ${
        disabled
          ? "text-primary-gray-700 hover:text-primary-gray-700"
          : "text-secondary-blueAzure-500 hover:text-primary-pText-900"
      }  ${className ?? ""}`}
      onMouseOver={() => setOver(true)}
      onMouseOut={() => setOver(false)}
    >
      <Button
        className={`${icon ? `gap-2.5 ${iconClassName ?? ""}` : ""}`}
        label={translatedLabel}
        icon={over && !disabled ? iconHover : icon}
        iconPos={iconPos}
        disabled={disabled}
        loading={loading}
        loadingIcon={loadingIcon}
        onClick={(e) => {
          if (handleFileDownload) {
            handleFileDownload(e);
          } else if (onClick) {
            onClick(e);
          }
        }}
      />
    </div>
  );
};

// ----------- memoization -------------------- //
const TertiaryButton = React.memo(TertiaryButtonWithoutMemo);

// ----------- redux wiring ------------------- //

// ----------- exports ------------------------ //
export default TertiaryButton;
