/*
 *   Author     : Akash Appanna
 *   created on : Dec 17, 2022
 */

import { BUTTON_DEFAULT } from "assets/base-theme";
import BaseButton, { IBaseButtonProps } from "components/base/BaseButton";
import { IButtonCommonStyleProps } from "interface/component/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { generateClassesForButton } from "utils/Util";

interface IButtonBoxProps extends IBaseButtonProps, IButtonCommonStyleProps {
  loader?: boolean;
}
const SimpleButtonWithoutMemo: React.FC<IButtonBoxProps> = (props: IButtonBoxProps) => {
  let [showLoader, setShowLoader] = useState(false);

  let className: string | undefined = props.className;

  // ------------------------ generate default styles from base-theme.ts -------------------------------- //
  const getClassNames = () => {
    let styleObject = {
      ...BUTTON_DEFAULT,
      isDisabled: props.disabled || props.loader || showLoader,
    };

    return generateClassesForButton(
      styleObject,
      className,
      props.variant ?? "text",
      props.color ?? "primary",
      props.size
    );
  };

  const { color, variant, onClickWithLoader, args, loader, ...primeReactProps } = props;

  const { value, name, label, iconPos, onClick, disabled, loadingIcon, icon } = primeReactProps;

  const { t } = useTranslation();
  let translatedLabel = t(label!, args);

  const onHandlerClick = async (e: any) => {
    if (onClick) {
      onClick(e);
    } else if (onClickWithLoader) {
      let res = onClickWithLoader();
      if (res && Promise.resolve(res) === res) {
        setShowLoader(true);
        res
          .then((message: any) => {
            setShowLoader(false);
          })
          .catch((err) => {
            setShowLoader(false);
          });
      }
    }
  };

  return (
    <>
      <BaseButton
        {...primeReactProps}
        color={color}
        className={getClassNames()}
        value={value}
        name={name}
        label={translatedLabel}
        loadingIcon={loadingIcon}
        iconPos={iconPos ?? "right"}
        icon={icon}
        onClick={onHandlerClick}
        loading={loader || showLoader}
        disabled={loader || showLoader || disabled}
      />
    </>
  );
};

// -------------------- Memoization ---------------------------- //
const ButtonBox = React.memo(SimpleButtonWithoutMemo);

// -------------------- redux wiring --------------------------- //

// -------------------- exports -------------------------------- //
export default ButtonBox;
