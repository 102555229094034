import { getLocalStorage, setLocalStorage } from "utils/CommonUtil";

export const updateDefaultLocaleSetting = (key: any, value: any) => {
  let defaultLocale = JSON.parse(getLocalStorage("defaultLocale") || "");
  defaultLocale = {
    ...defaultLocale,
    [key]: value,
  };
  setLocalStorage("defaultLocale", JSON.stringify(defaultLocale));
  window.location.reload();
};

// BorderStyle for BaseTheme

export const generateBorderStyle = (inputBorderWidth: any, inputBorderDirection: any) => {
  return (
    inputBorderWidth.inputBorderWidth.substring(0, 6) +
    inputBorderDirection.inputBorderDirection +
    inputBorderWidth.inputBorderWidth.substring(6, inputBorderWidth.inputBorderWidth.length)
  );
};

interface STYLE_OBJECT {
  primaryStyle?: object;
  secondaryStyle?: object;
  tertiaryStyle?: object;
  quaternaryStyle?: object;
  quinaryStyle?: object;
  errorStyle?: object;
  overrideStyle?: object;
  overrideClasses?: string;
  disabledStyleProperty?: string[];
  removeStyleProperty?: string[];
  removeClasses?: string[];
  isDisabled?: boolean;
  isInvalid?: boolean;
  [key: string]: any; // additional property Allowed (for ternaryStyle or Other)
}

/**
 *
 * @param styleObject: Contains Style Object from base-theme, override classes etc.
 * @param variant: Contains variant from props.
 * @returns all properties stored in className variable
 */
export const generateClasses = (
  styleObject: STYLE_OBJECT,
  variant: string = "primary",
  color?: string
) => {
  let type = `${variant.toLowerCase()}TypeStyle`;
  variant = `${variant.toLowerCase()}Style`;
  color = `${color?.toLowerCase()}Style`;

  let styleClassObj = styleObject[variant] ? styleObject[variant] : styleObject["primaryStyle"];
  let typeClassObj = styleObject[type] && styleObject[type];

  const {
    overrideClasses = "",
    overrideStyle = {},
    removeStyleProperty = [],
    removeClasses = [],
    isInvalid,
    isDisabled,
  } = styleObject;

  styleClassObj = { ...styleClassObj, ...overrideStyle };
  if (typeClassObj) {
    styleClassObj = { ...styleClassObj, ...typeClassObj[color] };
  }

  if (isInvalid && styleClassObj.error)
    styleClassObj = { ...styleClassObj, ...styleClassObj.error };
  if (isDisabled && styleClassObj.disabled)
    styleClassObj = { ...styleClassObj, ...styleClassObj.disabled };

  let classes: string = "";
  //Add classes to classes and disabled classes to disabledClasses
  for (let styleClassKey in styleClassObj) {
    const currClass: any = styleClassObj[styleClassKey];
    if (
      !currClass ||
      removeStyleProperty.includes(styleClassKey) ||
      removeClasses.includes(currClass)
    ) {
      continue;
    }

    if (typeof currClass === "string") {
      classes += ` ${currClass} `;
    }
    if (typeof currClass === "function") {
      classes += ` ${currClass()} `;
    }
  }

  classes += ` ${overrideClasses} `;

  return classes;
};

// ----------------------- do not delete this -----------------------------------------//

// export const generateClassesForButton = (
//   styleObject: STYLE_OBJECT,
//   variant: string = "primary",
//   color?: string
// ) => {
//   variant = `${variant.toLowerCase()}`;
//   color = `${color?.toLowerCase()}`;

//   console.log("variant :", variant);
//   console.log("color :", color);

//   let variantClassObj = styleObject[variant] ? styleObject[variant] : styleObject["primary"];
//   let styleClassObj = variantClassObj[color] ? variantClassObj[color] : variantClassObj["default"];

//   console.log("variantClassObj :", variantClassObj);
//   console.log("styleClassObj :", styleClassObj);

//   const {
//     overrideClasses = "",
//     overrideStyle = {},
//     removeStyleProperty = [],
//     removeClasses = [],
//     isInvalid,
//     isDisabled,
//   } = styleObject;

//   console.log("overrideStyle :", overrideStyle);

//   styleClassObj = { ...styleClassObj, ...overrideStyle };

//   if (isInvalid && styleClassObj.error)
//     styleClassObj = { ...styleClassObj, ...styleClassObj.error };
//   if (isDisabled && styleClassObj.disabled)
//     styleClassObj = { ...styleClassObj, ...styleClassObj.disabled };

//   let classes: string = "";

//   //Add classes to classes and disabled classes to disabledClasses
//   for (let styleClassKey in styleClassObj) {
//     const currClass: any = styleClassObj[styleClassKey];
//     if (
//       !currClass ||
//       removeStyleProperty.includes(styleClassKey) ||
//       removeClasses.includes(currClass)
//     ) {
//       continue;
//     }

//     if (typeof currClass === "string") {
//       classes += ` ${currClass} `;
//     }
//     if (typeof currClass === "function") {
//       classes += ` ${currClass()} `;
//     }
//   }

//   classes += ` ${overrideClasses} `;

//   return classes;
// };

// ---------------------------------------------- colors -------------------------------------------------------- //
const BUTTON_COLORS = {
  primary: {
    contained:
      " bg-secondary-blueAzure-500 text-white hover:bg-secondary-blueAzure-400 focus:bg-secondary-blueAzure-500 focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow",
    outlined:
      " bg-white border border-secondary-blueAzure-500 text-secondary-blueAzure-500 hover:bg-secondary-blueAzure-400  hover:border-secondary-blueAzure-400 hover:text-white focus:bg-white focus:shadow-buttonOutlinedBorder active:bg-white active:shadow-buttonOutlinedBorder focus:text-secondary-blueAzure-500 active:text-secondary-blueAzure-500",
    text: " text-primary-pText-900 bg-white hover:bg-primary-gray-50 hover:text-secondary-blueAzure-500 border-none",
    neutral:
      " border-none bg-none text-secondary-blueAzure-500 hover:text-primary-pText-900 !font-BrownLight ",
    potential:
      " bg-mapping-primary-p600 hover:bg-mapping-primary-p400 text-white focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow ",
  },
  secondary: {
    contained: " bg-primary-green-400 text-primary-pText-900 ",
    outlined:
      " outline outline-offset-2 outline-1 outline-primary-pText-600 text-primary-pText-600 ",
    text: " text-white hover:bg-secondary-blueAzure-100 hover:text-secondary-blueAzure-500 ",
    neutral:
      " border-none bg-none text-primary-gray-700 hover:text-primary-pText-900 !font-BrownLight ",
  },
  success: {
    contained: " bg-primary-green-400 text-primary-pText-900 ",
    outlined: " border-2 border-primary-green-400 ",
    text: " primary-green-400 ",
    neutral: "",
  },
  error: {
    contained: " bg-primary-red-600 text-white ",
    outlined: " outline outline-offset-2 outline-1 outline-primary-red-600 ",
    text: " primary-red-600 ",
    neutral: "",
  },
  potential: {
    contained:
      " bg-mapping-primary-p600 hover:bg-mapping-primary-p400 text-white focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow ",
    outlined: "",
    text: "",
    neutral: "",
  },
  usual: {
    contained:
      " bg-mapping-primary-u600 hover:bg-mapping-primary-u400 text-white focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow ",
    outlined: "",
    text: "",
    neutral: "",
  },
  limited: {
    contained:
      " bg-mapping-primary-l600 hover:bg-mapping-primary-l400 text-white focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow ",
    outlined: "",
    text: "",
    neutral: "",
  },
  gold: {
    contained:
      " bg-mapping-primary-cr600 hover:bg-mapping-primary-cr400 text-white focus:shadow-buttonContainedShadow active:shadow-buttonContainedShadow ",
    outlined: "",
    text: "",
    neutral: "",
  },
};

// ---------------------------------------------- SIZE ----------------------------------------------------------- //

const BUTTON_SIZE = {
  small: "",
  large: "",
};

// ---------------------------------------------- variants ------------------------------------------------------ //
function getButtonVariants(variant?: string, color?: string) {
  // to get styles for the variant
  const BUTTON_VARIANTS = {
    text: `${BUTTON_COLORS?.[color as keyof typeof BUTTON_COLORS].text}`,
    contained: `${BUTTON_COLORS?.[color as keyof typeof BUTTON_COLORS].contained}`,
    outlined: `${BUTTON_COLORS?.[color as keyof typeof BUTTON_COLORS].outlined}`,
    neutral: `${BUTTON_COLORS?.[color as keyof typeof BUTTON_COLORS].neutral}`,
  };

  return BUTTON_VARIANTS[variant as keyof typeof BUTTON_VARIANTS];
}

export function generateClassesForButton(
  styleObject?: any, // default styles from base-theme.ts
  className?: string, // to override the styles
  variant?: string, // "text" is the default variant
  color?: string, // "primary" is the default color
  size?: string // medium is the default size   // ----- have to implement ----- //
) {
  // ---- convert the object to strings ---- //
  let classes: string = "";

  const { isDisabled } = styleObject;
  let disabled: any = {};

  function updateDisableObj(variant: string) {
    if (variant === "contained") {
      disabled = {
        backgroundColor: "!bg-primary-gray-300 ",
        buttonTextColor: "!text-secondary-disabledGrey ",
      };
    } else if (variant === "outlined") {
      disabled = {
        buttonBorderColor: "!border-primary-gray-300 ",
        backgroundColor: "!bg-white",
        buttonTextColor: "!text-secondary-disabledGrey ",
      };
    } else if (variant === "neutral") {
      disabled = {
        buttonTextColor: "!text-secondary-disabledGrey ",
        backgroundColor: "!bg-transparent ",
      };
    } else {
      disabled = {
        inputBackgroundColor: "!bg-white",
        buttonTextColor: "!text-secondary-disabledGrey ",
      };
    }
  }

  if (isDisabled) {
    updateDisableObj(variant ?? "text");
    styleObject = { ...styleObject, ...disabled };
  }

  for (let styleClassKey in styleObject) {
    const currClass: any = styleObject[styleClassKey];
    if (typeof currClass === "string") {
      classes += ` ${currClass}`;
    }
  }

  classes += getButtonVariants(variant ?? "text", color ?? "primary");
  classes += className ?? "";
  return classes;
}

// -------------------- update price -------------------------- //
export function updatePrice(value: string) {
  if (value === "") {
    return 1;
  } else {
    return parseInt(value);
  }
}

export function getMinutes(seconds: number) {
  if (isNaN(seconds)) return "0";
  return Math.floor(seconds / 60).toString();
}
