const ALL_CONST = {
  "label.common.app": "INTELLIGENT LIFE OASIS",
  // -------------------------------------------- COMMON ---------------------------------------------//

  welcome: "Welcome to Viamagus' Kaiju React Boilerplate!",
  message: " Hello!! This is Home - VIEW-1!",
  "label.view1.post_heading": "Post Heading",
  "label.view1.post_description": "Post Description",
  "label.view2.user_name": "Enter Name",
  "label.user_name": "Enter Name",
  "message.success": "Enter Name",
  "validator.mandatory": "This is a mandatory field",
  "component.button.save": "Save",
  "component.button.confirmChange": "Confirm change",
  "component.button.continue": "Continue",
  "component.button.gotoDashboard": "Go to Dashboard",
  "component.button.cancel": "Cancel",
  "component.button.close": "Close",
  "component.button.confirm": "Confirm",
  "component.button.goBack": "Go back",
  "component.button.submit": "Submit",
  "component.button.back": "Back",
  "component.button.logIn": "Log In",
  "component.button.logOut": "Log out",
  "component.button.next": "Next",
  "component.button.previous": "Previous",
  "component.button.resetPassword": "Reset password",
  "component.button.setPassword": "Set password",
  "component.button.dashboard": "Take me to Dashboard",
  "component.button.link.skip": "Skip overview",
  "component.button.watchVideo": "Watch video",
  "component.multiselect.selectedItems": "items selected",
  "component.menu.delete": "Delete",
  "component.button.retryUpdate": "Retry Update",
  "component.button.updating": "Updating",
  "component.button.downloadCsv": "Download CSV",

  "component.text.done": "Done",
  "component.text.search": "Search",
  "component.button.account": "Account",
  "component.button.home": "Home",
  "component.dialog.header.areYouSure": "Are you sure?",
  "component.dialog.header.accountExists": "User account already exists",

  // ------------------------------------------- End COMMON --------------------------------------------------- //

  // --------------------------------------------- AUTH --------------------------------------------------- //

  "label.auth.signUp": "Sign up",
  "label.auth.signUp.emailId": "Email ID *",
  "label.auth.signUp.createPassword": "Create password *",
  "label.auth.signUp.organisationButton": "Sign up as an organisation",
  "label.auth.signUp.individualButton": "Sign up as an individual",
  "label.auth.signUp.as.an": "Sign up as an",
  "label.auth.signUp.individual": "individual",
  "label.auth.signUp.organisation": "organisation",
  "label.auth.signUp.existing": "Existing member?",
  "label.text.resend.otp": "Resend OTP",
  "label.text.otp.expiry": "OTP expires in ",
  "label.text.otp.expired": "OTP has expired",
  "label.text.otp.incorrect": "Incorrect OTP",
  "label.text.noPlanSelected": "No Plan selected",
  "label.text.noPlanSelected.summary": "You need to select a plan from the website",

  "label.auth.logIn": "Log in",
  "label.auth.logIn.email": "Email",
  "label.auth.logIn.password": "Password",
  "label.auth.logIn.individual": "Individual",
  "label.auth.logIn.platformAdmin": "Platform Admin",
  "label.auth.logIn.organisation": "Organisation",
  "label.auth.logIn.forgotPassword": "Forgot password?",

  "label.auth.logIn.button": "Log in",
  "label.auth.logIn.notMember": "Not a member yet?",
  "label.auth.logIn.signUp": "Sign up",

  "label.auth.forgotPassword.title": "Forgot your password?",
  "label.auth.forgotPassword.subtitle":
    "Enter your Email Id and we will send you an OTP to reset the password",
  "label.auth.forgotPassword.button": "Send me the OTP",

  "label.auth.changePassword.title": "Set password",

  "label.auth.resetPassword.title": "Reset new password",
  "label.auth.resetPassword.titleContent":
    "First please enter the OTP that has been sent to {{email}}",
  "label.auth.resetPassword.password": "Create password *",

  "label.auth.successfulReset.title": "Password has been reset",
  "label.auth.successfulReset.button": "Continue to log in",

  "label.auth.enter.otp": "Enter OTP",
  "label.auth.enter.otp.subText": "Please enter the OTP that has been sent to {{email}}",
  "label.auth.enter.otp.resetMessage": "OTP resent. Check your spam as well",
  "label.auth.enter.otp.sentMessage": "OTP sent. Check your spam as well",

  "label.text.billing": "Billing information",
  "label.text.firstName": "First name *",
  "label.text.lastName": "Last name *",
  "label.text.contactNumber": "Contact number *",
  "label.text.address.line1": "Address line 1 *",
  "label.text.address.line2": "Address line 2 (Optional)",
  "label.text.town/city": " Town / City *",
  "label.text.region/province/state": "Region / Province / State",
  "label.text.country": "Country *",
  "label.text.postcode/ZIP": "Postcode / ZIP *",
  "button.text.proceed.pay": "Proceed to pay {{noOfLearners}}",
  "button.text.proceed.pay.inCart": "Proceed to pay {{totalPrice}}",
  "label.text.organisation": "ORGANISATION",
  "label.text.organisation.admin": "ADMIN",
  "label.text.organisation.organisationName": "Organisation name *",
  "label.text.organisation.learners": "No. of learners in your team *",
  "label.text.organisation.contactNumber": "Organisation contact number *",
  "label.text.organisation.addressLine1": "Organisation address line 1 *",
  "label.text.organisation.addressLine2": "Organisation address line 2 (Optional)",
  "label.text.organisation.adminContact": "Admin contact number *",

  "label.text.summary.purchase": "Purchase summary",
  "label.text.summary.selectedPlan": "Selected plan",
  "label.text.summary.selectedPlan.value1": "Tree",
  "label.text.summary.selectedPlan.value2": "Sapling",
  "label.text.summary.selectedPlan.payment": "Payment per month",
  "label.text.summary.learners": "No. of learners",
  "label.text.summary.months": "No. of months (minimum)",
  "label.text.summary.total": "Total",
  "label.text.summary.dueNow": "Due now",
  "label.text.summary.subscriptionInfo":
    "The subscription is for a minimum period of {{month}} months, and will continue to be renewed monthly until cancelled",

  "toolTip.text.learners.in.team":
    "Each member who logs in to Intelligent Life from your organisation with a ‘learner’ role, Is considered as one subscription. \n E.g If access is given to 25 members with ‘Learner’ role, then it is considered as 25 subscriptions.",

  "tooltip.text.averageScore.adminPortalAnalytics": "Based on input from all learners",

  "label.text.paymentSuccessful": "Payment successful",
  "label.text.paymentSuccessful.setUpProfile": "Let’s set up your profile",
  "label.text.paymentSuccessful.continue": "Continue",

  //-------Password----------//

  "label.text.password.header": "Password strength",
  "label.text.password.weak": "Weak",
  "label.text.password.good": "Good",
  "label.text.password.strong": "Strong",
  "label.text.password.suggestions": "Suggestions",
  "label.text.password.characters": "At least 8 characters",
  "label.text.password.smallLetter": "At least one small letter",
  "label.text.password.capitalLetter": "At least one capital letter",
  "label.text.password.number": "At least one number",

  // ---------------------Subscription Cancel-------------------- //

  "label.text.unsubscribe.title": "Thank You",
  "label.text.unsubscribe.description":
    "You have successfully unsubscribed. You will no longer be charged for Intelligent life oasis",
  "label.text.unsubscribe.btn": "Go back to website",

  // ----------------------------------------- End AUTH ----------------------------------------//

  // ----------------------------------------- Default layout --------------------------------- //

  "label.header.text.fusion": "fusion360",
  "label.header.text.viamagus": "Viamagus Technologies",

  // --------------------------------------- Onboarding SetUp Profile ---------------------------------- //

  "label.text.onboarding.setupProfile": "Set up profile",
  "label.text.onboarding.setupProfile.heading": "Set up your profile",
  "label.text.onboarding.setupProfile.headingText": "We would like to know you better",
  "label.text.onboarding.setupProfile.firstName": "First name*",
  "label.text.onboarding.setupProfile.lastName": "Last name*",
  "label.text.onboarding.setupProfile.organisationName": "Name of organisation*",
  "label.text.onboarding.setupProfile.jobTitle": "Your job title *",
  "label.text.onboarding.setupProfile.roleInOrg": "Your role in the organisation *",
  "label.text.onboarding.setupProfile.residence": "Country of residence *",
  "label.text.onboarding.setupProfile.timeZone": "Your time zone *",
  "label.text.onboarding.setupProfile.timeZone.toolTip":
    "This will help us schedule events and sessions based on your availability",
  "label.text.onboarding.setupProfile.hearAboutIl": "How did you hear about Intelligent Life? *",
  "label.text.onboarding.setupProfile.friends": "Friends / Colleagues",
  "label.text.onboarding.setupProfile.ilWebsite": "Intelligent Life website",
  "label.text.onboarding.setupProfile.socialMedia": "Social media",
  "label.text.onboarding.setupProfile.other": "Other",
  "placeholder.text.onboarding.setupProfile.specifyOther": "Specify other",
  "label.text.onboarding.setupProfile.nextButton": "Next",
  "label.text.onboarding.quick.overview": "Quick overview",

  // --------------------------------------------- Onboarding Invite Team -------------------------------------- //

  "label.text.onboarding.invite.team": "Invite team",
  "label.text.onboarding.inviteTeam.heading": "Invite your team",
  "label.text.onboarding.inviteTeam.headingText":
    "Set up your team now or add them later in settings",
  "label.text.onboarding.inviteTeam.firstName": "First name",
  "label.text.onboarding.inviteTeam.lastName": "Last name",
  "label.text.onboarding.inviteTeam.email": "Email",
  "label.text.onboarding.inviteTeam.role": "Role",
  "label.text.onboarding.inviteTeam.role.toolTip":
    "Each member who logs in to Intelligent Life from your organisation with a ‘learner’ role, Is considered as one subscription. \n E.g If access is given to 25 members with ‘Learner’ role, then it is considered as 25 subscriptions.",
  "label.text.onboarding.inviteTeam.admin": "Admin",
  "label.text.onboarding.inviteTeam.adminLearner": "Admin & Learner",
  "label.text.onboarding.inviteTeam.learner": "Learner",
  "label.text.onboarding.inviteTeam.addMember": "Add another member",
  "label.text.onboarding.inviteTeam.admins": "Admins",
  "label.text.onboarding.inviteTeam.learners": "Learners",
  "label.text.onboarding.inviteTeam.role.admin": "Admin",
  "label.text.onboarding.inviteTeam.role.learner": "Learner",
  "label.text.onboarding.inviteTeam.role.adminLearner": "Admin & Learner",
  "label.text.onboarding.inviteTeam.invitation": "An Invitation has been sent to your team members",

  // -------------------------------------- Onboarding Overview --------------------------------------------------- //

  "label.text.onboarding.overview.title": "Quick Overview",
  "label.text.onboarding.overview.asAdmin": "As an admin, you can :",
  "label.text.onboarding.overview.manage": "Manage your team ",
  "label.text.onboarding.overview.track": "Track team progress",
  "label.text.onboarding.overview.billing": " Take care of billing",
  "label.text.onboarding.overview.switch": "Switch between admin and learner",

  // ----- carousel contents ------- //

  "label.text.onboarding.cItemFeatures": "THE FEATURES OF THE OASIS",
  "label.text.onboarding.cItem.mapping": "MAPPING",
  "label.text.onboarding.cItem.mappingContent":
    "The integrated mapping app is a diagnostic and measurement tool, to track our thinking and behaviours as we grow and change. It can be used for individual or group assessment",
  "label.text.onboarding.cItem.learning": "LEARNING",
  "label.text.onboarding.cItem.learningContent":
    "The learning modules help you understand, experience and implement Natural Intelligence in any setting",
  "label.text.onboarding.cItem.tutorials": "TUTORIALS",
  "label.text.onboarding.cItem.tutorialsContent":
    "The tutorial series investigates how our being works, offers new insights into the mechanics of human experience and provides practical solutions to change our thinking and behaviour",
  "label.text.onboarding.cItem.library": "LIBRARY",
  "label.text.onboarding.cItem.libraryContent":
    "The library houses a collection of Intelligent Life materials, including articles, meditations, event recordings and the tutorial archive",

  // ------------ Dashboard Contents --------------//
  "label.text.mainDashboard.cart": "Cart",
  "label.text.mainDashboard.menu.dashboard": "Dashboard",
  "label.text.mainDashboard.menu.mapping": "Mapping",
  "label.text.mainDashboard.menu.foundation": "Foundation",
  "label.text.mainDashboard.menu.modules": "Modules",
  "label.text.mainDashboard.menu.tutorials": "Tutorials",
  "label.text.mainDashboard.menu.library": "Library",
  "label.text.mainDashboard.menu.myStudy": "My Study",
  "label.text.mainDashboard.menu.analytics": "Analytics",
  "label.text.mainDashboard.menu.teamAnalytics.team": "Team",
  "label.text.mainDashboard.menu.teamAnalytics.analytics": "Team Analytics",
  "label.text.mainDashboard.menu.users": "Users",
  "label.text.mainDashboard.menu.organizations": "Organisations",

  //------------Account Side Menu------------------//
  "label.text.account.sideMenu.profile": "My Profile",
  "label.text.account.sideMenu.plan": "Plan & Billing",

  //------------------Home Page Content-------------//

  "label.text.home.getStarted": "Get Started",
  "label.text.home.welcomeBack": "Welcome back",
  "label.text.home.oasisGuide": "Oasis Guide",
  "label.text.home.oasisOverview": "OASIS OVERVIEW",
  "label.text.home.overviewCardDesc":
    "Explore the Intelligent Life Oasis, Learn how the tools and curriculum work together, and how to view your progress",
  "label.text.home.watchVideo": "Watch video",
  "label.text.home.mappingCardDesc": "After you have watched the video, begin Mapping",
  "label.text.home.mappingCardDescContinue": "After you have watched the video, continue Mapping",

  "label.text.home.beginMapping": "Begin Mapping",
  "label.text.home.completeMapping": "Complete Mapping",
  "label.text.home.performMapping": "Perform Mapping",

  "label.text.home.card.title.mapping": "MAPPING",
  "label.text.home.card.title.performMapping": "PERFORM MAPPING",
  "label.text.home.card.descPerformMapping":
    "You have completed the {Foundation course, Now perform the Mapping exercise to assess your understanding",
  "label.text.home.card.descStartMapping": "Start the Mapping exercise",
  "label.text.home.card.descMappingProgress": "Mapping in-progress",

  "label.text.home.basicPlan.card.title.upgradePlan": "UPGRADE PLAN",
  "label.text.home.basicPlan.card.descUpgradePlan":
    "You have completed your first mapping exercise. Upgrade now and gain access to Foundation.",
  "label.text.home.basicPlan.card.btn.upgrade": "Upgrade Now",
  "label.text.home.basicPlan.card.descLocked":
    "Foundation & Modules are currently locked. Upgrade Now to gain complete access",
  "label.text.home.basicPlan.card.title.planUpgraded": " FULL ACCESS UNLOCKED",
  "label.text.home.basicPlan.card.descPlanUpgraded": "Begin your learning journey with Foundation",

  "label.text.home.card.btn.continue": "Continue learning",
  "label.text.home.card.btn.beginModule": "Begin Modules",
  "label.text.home.card.btn.moduleCompleted": "MODULE COMPLETED",
  "label.text.home.card.btn.gotoStudy": "Go to My study",
  "label.text.home.card.btn.gotoModules": "Go to Modules",

  "label.text.home.card.beginFoundation": "BEGIN FOUNDATION",
  "label.text.home.card.beginFoundationDesc":
    "You have completed your first Mapping exercise, Now start the Foundation course",
  "label.text.home.card.foundationInProgress": "FOUNDATION IN PROGRESS",

  "label.text.home.card.beginModule": "BEGIN MODULES",
  "label.text.home.card.beginModuleDesc":
    "You have completed the Foundation course. Now start the Modules",
  "label.text.home.card.moduleInProgress": "MODULES IN PROGRESS",

  "label.text.home.learning": "Learning",
  "label.text.home.foundationAndModules": "FOUNDATION AND MODULES",
  "label.text.home.curriculum.title": "The Oasis Curriculum",
  "label.text.home.foundation": "Foundation",
  "label.text.home.modules": "Modules",
  "label.text.home.card.module": "Module",
  "label.text.home.viewEntireCurriculum": "View entire curriculum",
  "label.text.home.upcomingTutorials": "UPCOMING TUTORIALS",
  "label.text.home.exploreTutorial": "Explore tutorial archives",
  "label.text.home.library": "LIBRARY",
  "label.text.home.exploreLibrary": "Explore full library",

  "label.text.home.progress": "Progress",
  "label.text.home.progress.myMapping": "MY MAPPING",
  "label.text.home.progress.myMapping.desc": "You have not created any maps yet",
  "label.text.home.progress.gotoMyMapping": "Go to Mapping",
  "label.text.home.progress.learningStats": "LEARNING STATS",
  "label.text.home.progress.learningStats.details": "View detailed",
  "label.text.home.progress.learningStats.analytics": "Analytics",
  "label.text.home.progress.library": "Library",
  "label.text.home.progress.library.cardDesc": "Accessed out of",
  "label.text.home.progress.library.cardTitle.archives": "Tutorial Archives",
  "label.text.home.progress.library.cardTitle.courses": "Courses",
  "label.text.home.progress.library.cardTitle.event": "Event",
  "label.text.home.progress.library.cardTitle.events": "Events",
  "label.text.home.progress.library.cardTitle.meditations": "Meditations",
  "label.text.home.progress.library.cardTitle.articles": "Articles",
  "label.text.home.progress.library.cardTitle.podcast": "Podcasts",

  "label.text.home.faq": "Frequently asked questions",

  // ------------ Foundation Contents --------------//
  "label.text.foundation.foundationHeading": "Foundation",
  "label.text.foundation.overviewDescription":
    "The foundation course takes you through the structure, nature and function of life, and how to navigate it with understanding",
  "label.text.foundation.actionCard.text": "Begin Foundation today",
  "label.text.foundation.actionCard.button": "Begin Foundation",
  "label.text.foundation.topics.heading": "TOPICS",
  "label.text.foundation.topics.text": "{{count}} Topics",
  "label.text.foundation.topics.recommended": "{{count}} Weeks (Recommended)",
  "label.text.foundation.progress.heading": "PROGRESS",
  "label.text.foundation.progress.topicsCompleted": "Topics completed",

  "label.text.foundation.concept": "Concept",
  "label.text.foundation.practice": "Practice",
  "label.text.foundation.application": "Application",

  "label.error.Begin": "Begin Foundation Failed",

  "label.text.learning.redoConcept": "Redo Concept",
  "label.text.learning.redoPractice": "Redo Practice",

  // ------------ Modules Contents --------------//
  "label.text.modules.modulesHeading": "Modules",
  "label.text.modules.chartHeading": "MODULES",
  "label.text.modules.moduleNumber": "8 Modules",
  "label.text.modules.curriculumButtonText": "View entire curriculum",
  "label.text.modules.topicTitle.stability": "Stability",
  "label.text.modules.topicTitle.ease": "Ease",
  "label.text.modules.topicTitle.confidence": "Confidence",
  "label.text.modules.topicTitle.discernment": "Discernment",
  "label.text.modules.topicTitle.resilience": "Resilience",
  "label.text.modules.topicTitle.security": "Security",
  "label.text.modules.topicTitle.intuition": "Intuition",
  "label.text.modules.topicTitle.wisdom": "Wisdom",
  "label.text.modules.individualModule.breadcrumb": "Module 1 : Stability",

  // ------------ Individual Modules Details Content --------------//
  "label.text.moduleDetail.moduleHeading": "Stability",

  // ------------ Curriculum Table Contents --------------//
  "label.text.dashboard.topic": "Topic",
  "label.text.dashboard.moduleCurriculum": "Modules Curriculum",
  "label.text.dashboard.foundation": "FOUNDATION",
  "label.text.dashboard.modules": "MODULES",
  "label.text.dashboard.stability": "STABILITY",
  "label.text.dashboard.ease": "EASE",
  "label.text.dashboard.confidence": "CONFIDENCE",
  "label.text.dashboard.discernment": "DISCERNMENT",
  "label.text.dashboard.resilience": "RESILIENCE",
  "label.text.dashboard.surity": "SURITY",
  "label.text.dashboard.intuition": "INTUITION",
  "label.text.dashboard.wisdom": "WISDOM",

  // -----------------Page Tutorials--------------//
  "label.text.tutorials.card.heading.sessions": "SESSIONS",
  "label.text.tutorials.card.totalSessions": "Sessions",
  "label.text.tutorials.card.sessions.showAll": "Show all",
  "label.text.tutorials.noTutorialsInfo": "Tutorials are not available currently",

  // ------------Page Learning --------------//
  "label.text.tabMenu.guide": "Guide",
  "label.text.tabMenu.concept": "Concept",
  "label.text.tabMenu.practice": "Practice",
  "label.text.tabMenu.application": "Application",

  "label.text.learning.guide.textLight": "To create the optimal learning environment…",
  "label.text.learning.guide1.textRegular": "Be in a quiet space",
  "label.text.learning.guide2.textRegular":
    "Use headphones so you can hear the explanation and guidance clearly",
  "label.text.learning.guide3.textRegular":
    "Make sure you have at least 30 minutes to complete the topic",

  "label.text.learning.markComplete": "Mark as Complete",
  "label.text.learning.viewSelfReflection": "View self reflection",
  "label.text.learning.heading.description": "DESCRIPTION",
  "label.text.learning.heading.transcript": "TRANSCRIPT",

  "label.text.learning.selfReflection": "Self reflection",
  "label.text.learning.selfReflection.btn.backToVideo": "Back to video",
  "label.text.learning.selfReflection.btn.backToAudio": "Back to audio",
  "label.text.learning.selfReflection.btn.gotoPractice": "Submit and go to Practice",
  "label.text.learning.selfReflection.btn.gotoApplication": "Submit and go to Application",

  "label.text.learning.application.internalChanges": "Internal changes",
  "label.text.learning.application.externalChanges": "External changes",
  "label.text.learning.application.btn.submit": "Submit and complete topic",

  "label.error.guidedCompletion": "Guided Video Completion Failed",
  "label.error.learning.topicDetail": "Failed fetching topic details",
  "label.error.learning.topicStarted": "Failed to start topic",
  "label.error.learning.conceptStart": "Failed to start concept",
  "label.error.learning.practiceStart": "Failed to start practice",
  "label.error.learning.applicationStart": "Failed to start application",
  "label.error.learning.selfReflection": "Failed to submit answer",
  "label.error.learning.fetchQuestion": "Failed to fetch question",

  // -----------Page Analytics Foundation, Modules and Scores ---------------- //
  "label.text.analytics.myAnalytics": "Analytics",
  "label.text.analytics.breadcrumb.myAnalytics": "My Analytics",
  "label.text.analytics.breadcrumb.foundation": "Foundation",
  "label.text.analytics.breadcrumb.modules": "Modules",
  "label.text.analytics.viewAll": "View all",
  "label.text.analytics.memberScoreReport": "Member score report",
  "label.text.analytics.moduleMemberReport": "Modules / Member score report",
  "label.text.analytics.foundationMemberReport": "Foundation / Member score report",
  "label.text.analytics.foundationAverageScore": "Foundation average score",
  "label.text.analytics.modulesAverageScore": "Modules average score",
  "label.text.analytics.foundationReports": "Foundation reports",
  "label.text.analytics.modulesReports": "Modules reports",
  "label.text.analytics.moduleUnderstanding": "MODULE UNDERSTANDING",

  "label.text.analytics.section.heading.foundation": "FOUNDATION",
  "label.text.analytics.section.heading.modules": "MODULES",

  "label.text.analytics.section.heading.billing": "BILLING",
  "label.text.analytics.section.billing.individual": "Total Billing per month",
  "label.text.analytics.section.billing.individualSubtext": "(Individual users)",
  "label.text.analytics.section.billing.badge.annual": "Annual",
  "label.text.analytics.section.billing.organisation": "Total Billing per month",
  "label.text.analytics.section.billing.organisationSubtext": "(Organisations)",

  "label.text.analytics.section.heading.summary": "SUMMARY",
  "label.text.analytics.section.summary.activeMembers": "Total active members",
  "label.text.analytics.section.summary.activeOrganisation": "Active Organisations",
  "label.text.analytics.section.summary.activeIndividual": "Active Individual Members",

  "label.text.analytics.chart.heading.Progress": "Progress",
  "label.text.analytics.chart.heading.payments": "Payments",
  "label.text.analytics.chart.heading.averageScore": "Average Score",
  "label.text.analytics.chart.heading.topicPerformance": "Topic performance",
  "label.text.analytics.chart.heading.scoreband": "Score band",
  "label.text.analytics.chart.heading.showallLearners": "Show all Learners",

  "label.text.analytics.chart.payments.paymentMissed": "Missed payments",

  "label.text.analytics.chart.heading.progress": "Overall progress",
  "label.text.analytics.chart.heading.understanding": "Overall understanding",
  "label.text.analytics.chart.heading.engagement": "Engagement over time",
  "label.text.analytics.chart.heading.topic": "Topic understanding",
  "label.text.topicRepeated.title": "No. of topics repeated",
  "label.text.topicRepeated.topicName": "Topic name",
  "label.text.topicRepeated.number": "No. of times",
  "label.text.topicRepeated.timesCompleted": "times completed",
  "label.text.topicAvgCount.title": "Average number of times each topic is completed",
  "label.text.topicDateInfo.title": "Last topic completed on",

  "label.text.analytics.seeReport": "See all reports",
  "label.text.analytics.card.heading.mapping": "MAPPING",
  "label.text.analytics.card.showAllMaps": "Show all maps",
  "label.text.analytics.mapping.card.mappingCompleted": "Mappings Completed",

  "label.text.analytics.card.heading.library": "LIBRARY",
  "label.text.analytics.card.heading.itemsAccessed": "Items accessed",

  "label.text.analytics.score.tableColumn.emailID": "Email ID",

  "label.text.analytics.score.heading": "Scores",
  "label.text.analytics.score.detailedScore": "View detailed scores",
  "label.text.analytics.score.foundationReports": "Foundation reports",
  "label.text.analytics.score.moduleReports": "Module reports",
  "label.text.analytics.score.input.learnerName": "Learner name",
  "label.text.analytics.score.input.learnerType": "Learner type",
  "label.text.analytics.score.input.organisation": "Organisation",
  "label.text.analytics.score.input.selectlearnerType": "Select Learner type",
  "label.text.analytics.score.input.selectOrganisation": "Select Organisation",
  "label.text.analytics.score.input.learnerName.placeholder": "Search for learner name",
  "label.text.analytics.score.input.module": "Module",
  "label.text.analytics.score.input.module.placeholder": "Select Module",
  "label.text.analytics.score.input.topic": "Topic",
  "label.text.analytics.score.input.topicNo": "Topic No",
  "label.text.analytics.score.input.topic.placeholder": "Select Topic",
  "label.text.analytics.score.input.scoreBand": "Score band",
  "label.text.analytics.score.input.scoreBand.placeholder": "Select Score band",
  "label.text.analytics.score.table.averageScore": "Average score",
  "label.text.analytics.score.button.applyFilter": "Apply filter",
  "label.text.analytics.score.button.clear": "Clear",

  "label.text.analytics.team.inviteText":
    "Invite new members or make changes to access control here",
  "label.text.analytics.team.button.inviteMember": "Invite Member",
  "label.text.analytics.team.status": "Status",
  "label.text.analytics.team.edit": "Edit",
  "label.text.analytics.team.view": "View",

  "label.text.analytics.team.unpublished" : "Deactivate",
  "label.text.analytics.team.activate" : "Activate",
  
  "label.text.analytics.team.doughnutChart.overAllProgress": "Overall progress",
  "label.text.analytics.team.doughnutChart.currentlyLearning": "Currently learning",
  "label.text.analytics.team.doughnutChart.completedFoundation": "Completed Foundation",
  "label.text.analytics.team.doughnutChart.overAllUnderstanding": "Overall understanding",

  "label.text.analytics.team.active": "Active",
  "label.text.analytics.team.inActive": "Inactive",
  "label.text.analytics.team.inviteForm.inviteMember": "Invite member",
  "label.text.analytics.team.inviteForm.button.sendInvite": "Send Invite",
  "label.text.analytics.team.inviteForm.role": "Role *",
  "label.text.analytics.team.inviteForm.toast": "Invitation has been sent to team member",
  "label.text.analytics.team.editForm.toast": "Information edited successfully",

  "label.text.analytics.team.editForm.editMember": "Edit team member",
  "label.text.analytics.team.editForm.deactivate": "Deactivate User",
  "label.text.analytics.team.editForm.activate": "Activate User",
  "label.text.analytics.team.editForm.deactivateUser": "Deactivate user?",
  "label.text.analytics.team.editForm.activateUser": "Activate user?",

  // ----------- Cart ---------------- //
  "label.text.cart.heading": "My cart",
  "label.text.cart.itemsInCart": " items in cart )",
  "label.text.cart.itemInCart": " item in cart )",
  "label.text.cart.items": " items ) : ",
  "label.text.cart.item": " item ) : ",
  "label.text.cart.subtotal": "Subtotal ",
  "label.text.cart.checkoutButton": "Proceed to checkout",
  "label.text.cart.removeFromCart": "Remove from cart",
  "label.text.cart.toastMessage": "Items removed from cart",
  "label.text.cart.empty.title": "Your cart is empty",
  "label.text.cart.empty.description":
    "Must add items in the cart before you proceed to the checkout",
  "label.text.cart.empty.button": "Go to Library",

  "label.error.cart.removeItem": "Items removed from cart",

  // ----------- Billing Cart ---------------- //
  "label.text.billingCart.title": "Billing information",
  "label.text.billingCart.noOfItems": "No. of items",
  "label.text.billingCart.subTotal": "Subtotal",
  "label.text.billingCart.successMsg.heading": "Payment successful",
  "label.text.billingCart.successMsg.description": "The items have been added to My Study",
  "label.text.billingCart.successMsg.buttonText": "Go to My Study",
  "label.text.billingCart.successMsg.linkText": "See other Tutorials",

  "label.error.checkout.createOrder": "Failed to create order",
  "label.error.checkout.orderSummary": "Failed in fetching order summary",
  "label.error.checkout.orderSubmit": "Failed to check out",

  // ----------- Library ---------------- //
  "label.text.library.heading": "Library",
  "label.text.library.tutorialsHeading": "TUTORIAL ARCHIVE",
  "label.text.library.tutorialsText": "Tutorial Archive",
  "label.text.library.coursesHeading": "COURSES",
  "label.text.library.courseText": "Courses",
  "label.text.library.eventsHeading": "EVENTS",
  "label.text.library.eventsText": "Events",
  "label.text.library.meditationsHeading": "MEDITATIONS",
  "label.text.library.meditationsText": "Meditations",
  "label.text.library.articlesHeading": "ARTICLES",
  "label.text.library.articlesText": "Articles",
  "label.text.library.podcastsHeading": "PODCASTS",
  "label.text.library.podcastsText": "Podcasts",
  "label.text.library.searchResults": "SEARCH RESULTS",
  "label.text.library.searchResultsAbsent.title": "No results found",
  "label.text.library.searchResultsAbsent.description":
    "There were no items found matching your search",
  "label.text.library.tutorialsLink": "See all tutorial archive",
  "label.text.library.coursesLink": "See all courses",
  "label.text.library.eventsLink": "See all events",
  "label.text.library.meditationsLink": "See all meditations",
  "label.text.library.articlesLink": "See all articles",
  "label.text.library.podcastsLink": "See all podcasts",
  "label.text.library.notAddedTutorials": "Tutorials have not been added",
  "label.text.library.notAddedCourses": "Courses have not been added",
  "label.text.library.notAddedEvents": "Events have not been added",
  "label.text.library.notAddedMeditations": "Meditations have not been added",
  "label.text.library.notAddedArticles": "Articles have not been added",
  "label.text.library.notAddedPodcasts": "Podcasts have not been added",
  "label.text.library.notAddedItems": "Items have not been added",
  "label.text.library.button.addToCart": "Add to cart",
  "label.text.library.button.addToMyStudy": "Add to My Study",
  "label.text.library.button.addedToCart": "Added to cart",
  "label.text.library.button.addedToMyStudy": "Added to My Study",
  "label.text.library.bundleDeal": "Bundle deal",

  // ----------- Library Individual Section ---------------- //
  "label.text.librarySection.loadMore": "Load more",

  // ----------- Library Individual Card Details ---------------- //
  "label.text.libraryCardDetail.topicCard.session.title": "SESSIONS",
  "label.text.libraryCardDetail.topicCard.session.singular.title": "Session",
  "label.text.libraryCardDetail.topicCard.session.subTitle": "Sessions",
  "label.text.libraryCardDetail.topicCard.topics.title": "TOPICS",
  "label.text.libraryCardDetail.topicCard.topics.singular.title": "Topic",
  "label.text.libraryCardDetail.topicCard.topics.subTitle": "Topics",
  "label.text.libraryCardDetail.topicCard.episodes.title": "EPISODES",
  "label.text.libraryCardDetail.topicCard.episodes.singular.title": "Episode",
  "label.text.libraryCardDetail.topicCard.episodes.subTitle": "Episodes",
  "label.text.libraryCardDetail.topicCard.events.title": "EVENTS",
  "label.text.libraryCardDetail.topicCard.events.singular.title": "Event",
  "label.text.libraryCardDetail.topicCard.events.subTitle": "Events",
  "label.text.libraryCardDetail.toast.sessionAddedToCart": "Sessions added to cart",
  "label.text.libraryCardDetail.toast.sessionAddedToCartIndividual": "Session added to cart",
  "label.text.libraryCardDetail.toast.itemsAddedToCart": "Items added to cart",
  "label.text.libraryCardDetail.toast.itemsAddedToStudy": "Items added to My Study",
  "label.text.libraryCardDetail.toast.itemAddedToStudy": "Item added to My Study",
  "label.text.libraryCardDetail.comingSoon": "“ Coming soon ”",

  // ----------- Tutorials ---------------- //
  "label.text.tutorials.selectButton.all": "All",
  "label.text.tutorials.selectButton.upComing": "Upcoming",
  "label.text.tutorials.selectButton.past": "Past",
  "label.text.tutorials.linkButtonText.access": "Access from Tutorial Archive",

  // ----------- My Study ---------------- //
  "label.text.myStudy.heading": "My study",
  "label.text.myStudy.selectButton.all": "All",
  "label.text.myStudy.selectButton.toDo": "To do",
  "label.text.myStudy.selectButton.InProgress": "In progress",
  "label.text.myStudy.selectButton.completed": "Completed",
  "label.text.myStudy.button.beginSession": "Begin session",
  "label.text.myStudy.button.resumeSession": "Resume session",
  "label.text.myStudy.button.RepeatSession": "Repeat session",
  "label.text.myStudy.button.beginTopic": "Begin topic",
  "label.text.myStudy.button.resumeTopic": "Resume topic",
  "label.text.myStudy.button.repeatTopic": "Repeat topic",
  "label.text.myStudy.button.beginEpisode": "Begin episode",
  "label.text.myStudy.button.resumeEpisode": "Resume episode",
  "label.text.myStudy.button.repeatEpisode": "Repeat episode",
  "label.text.myStudy.button.beginEvent": "Begin event",
  "label.text.myStudy.button.resumeEvent": "Resume event",
  "label.text.myStudy.button.repeatEvent": "Repeat event",

  // ------------------- User Accounts, Plan and Billing -------------------//

  "label.text.userAcc.pageTitle": "Account - My Profile",
  "label.text.userAcc.edit": "Edit",
  "label.text.userAcc.changePass": "Change Password",
  "label.text.userAcc.currPass": "Current password",
  "label.text.userAcc.newPass": "New password",

  "label.text.userAcc.firstName": "First name",
  "label.text.userAcc.lastName": "Last name",
  "label.text.userAcc.email": "Email ID",
  "label.text.userAcc.contact": "Contact number",
  "label.text.userAcc.country": "Country of residence",
  "label.text.userAcc.timeZone": "Your time zone",

  "label.text.userSub.pageTitle": "Account - Plan & Billing",

  "label.text.userSub.planDetails": "Plan details",
  "label.text.userSub.planDetails.cur": "Current Plan",
  "label.text.userSub.planDetails.type": "Plan type",
  "label.text.userSub.planDetails.upgrade": "Upgrade to Full Plan",
  "label.text.userSub.planDetails.cancelSub": "Cancel Subscription",
  "label.text.userSub.planDetails.descCancelSub":
    "If you cancel the subscription you won't be able to access any items from Intelligent Life Oasis",

  "label.text.userSub.dialog.upgrade": "Upgrade plan",
  "label.text.userSub.dialog.newPlan": "New plan",
  "label.text.userSub.dialog.basic": "Basic",
  "label.text.userSub.dialog.full": "Full",
  "label.text.userSub.dialog.permonth": "{{price}} / month",
  "label.text.userSub.dialog.content.mapping": "Mapping",
  "label.text.userSub.dialog.content.library": "Library content",
  "label.text.userSub.dialog.content.modules": "Modules",
  "label.text.userSub.dialog.content.foundation": "Foundation",
  "label.text.userSub.dialog.content.upgradeDesc":
    "Once you upgrade to Full plan, You will pay {{price}} / month starting {{billingCycle}}. You agree that your Intelligent Life subscription will continue and that we will charge the updated monthly fee until you cancel the subscription.",
  "label.text.userSub.dialog.congratulation": "Congratulations",
  "label.text.userSub.dialog.content.upgradedToFull":
    "You have successfully upgraded to Full plan for Intelligent Life Oasis",

  "label.text.userSub.mainTitle": "Account - Plan & Billing",
  "label.text.userSub.billingInfo": "Billing information",
  "label.text.userSub.noBillingInfo": `‘No information available’`,
  "label.text.userSub.billingInfo.country": "Country",
  "label.text.userSub.billingInfo.orgDetail": "Organisation details :",
  "label.text.userSub.billingInfo.personalDetail": "Personal details :",
  "label.text.userSub.billingInfo.myAdddress": "My address line 1",
  "label.text.userSub.billingInfo.orgAdd": "Organisation address line 1",
  "label.text.userSub.billingInfo.townOrCity": "Town / City",
  "label.text.userSub.billingInfo.region": "Region / Province / State",
  "label.text.userSub.billingInfo.postCode": "Postcode / ZIP",
  "label.text.userSub.billingInfo.cardInfo": "Card information",
  "label.text.userSub.billingInfo.updateCard": "Update card info",
  "label.text.userSub.payHistory": "Payment history",
  "label.text.userSub.payHistory.date": "Date",
  "label.text.userSub.payHistory.tid": "Transaction ID",
  "label.text.userSub.payHistory.dialog.paymentdetails": "Payment details",
  "label.text.userSub.payHistory.dialog.close": "Close",
  "label.text.userSub.payHistory.dialog.itemList": "Items",
  "label.text.userSub.payHistory.description": "Description",
  "label.text.userSub.payHistory.servicePeriod": "Service period",
  "label.text.userSub.payHistory.paymentMethod": "Payment method",
  "label.text.userSub.payHistory.total": "Total",
  "label.text.userSub.button.upgradePlan": "Upgrade to Full Plan",
  "label.text.datatable.rowPerPage": "Rows per page",
  "label.text.userSub.billingInfo.individualBasic": "INDIVIDUAL - BASIC",
  "label.text.userSub.billingInfo.individualFull": "INDIVIDUAL - FULL",
  "label.text.userSub.billingInfo.organisation": "ORGANISATION",
  "label.text.userSub.dialog.upgrade.title": "Update Plan ?",
  "label.text.userSub.dialog.upgrade.description":
    "You agree that your Intelligent Life subscription will continue and that we will charge the updated monthly fee until you cancel the subscription",

  "label.text.updateCard.updated": "Updated",
  "label.text.updateCard.updatedDesc": "You have successfully updated your card information",
  "label.text.updateCard.success.button": "Go to Dashboard",

  // -----------Watch video or read article ---------------- //
  "label.text.watchOrRead.videoDescription.title": "DESCRIPTION",
  "label.text.watchOrRead.article.read": "mins read",
  "label.text.watchOrRead.article.markAsComplete": "Mark as complete",
  "label.text.watchOrRead.article.markAsRead": "Mark as read",
  "label.text.watchOrRead.article.completed": "Completed ",
  "label.text.watchOrRead.article.time": " time",
  "label.text.watchOrRead.article.times": " times",

  // -----------Portal Admin ---------------- //

  // ------------ Organization Contents --------------//
  "label.text.organization.name": "Organisation name",
  "label.text.organization.userName": "User name",
  "label.text.organization.country": "Country",
  "label.text.organization.timeZone": "Time zone",
  "label.text.organization.status": "Status",
  "label.text.organization.activate": "Activate",
  "label.text.organization.deactivate": "Deactivate",
  "label.text.organization.activateHeader": "Activate Organisation ?",
  "label.text.organization.activateUserHeader": "Activate User ?",
  "label.text.organization.deactivateHeader": "Deactivate organisation?",
  "label.text.organization.deactivateUserHeader": "Deactivate User?",
  "label.text.organization.activateMessage":
    "All learners will under the organisation will be allowed to access The Oasis and billing will resume",
  "label.text.organization.deactivateButton": "Cancel Subscription",

  // ------------ user  Contents --------------//
  "label.text.user.userDetails": "User Details",
  "label.text.user.fullName": "Full name",
  "label.text.user.type": "Type",
  "label.text.user.planName": "Plan Name",
  "label.text.user.emailId": "Email ID",
  "label.text.user.contact": "Contact",
  "label.text.user.address": "Address",
  "label.text.user.userType": "User type",
  "label.text.user.adminDeactivateNotAllowed": "You cannot deactivate an admin",

  // -----------Modules ---------------- //

  "label.admin.portal.button.foundation.create": "Create Foundation",
  // -----------Modules ---------------- //
  "label.admin.portal.button.module.create": "Create Module",
  "label.admin.portal.button.module.edit": "Edit Module",
  "label.admin.portal.button.topic.create": "Create Topic",
  "label.admin.portal.button.topic.edit": "Edit Topic",
  "label.admin.portal.button.module.name": "Module Name *",
  "label.admin.portal.button.module.number": "Module No. *",
  "label.admin.portal.button.topic.name": "Topic Name *",
  "label.admin.portal.button.topic.number": "Topic No. *",
  "label.admin.portal.concept": "Concept",
  "label.admin.portal.duration": "Duration*",
  "label.admin.portal.practice": "Practice",
  "label.admin.portal.application": "Application",

  // ----------- Library ---------------- //
  "label.admin.portal.categoryName": "Category name",

  "label.admin.portal.button.tutorial.create": "Create Tutorial Archive",
  "label.admin.portal.button.tutorial.edit": "Edit Tutorial Archive",
  "label.admin.portal.tutorial.seriesTheme": "Series theme *",
  "label.admin.portal.tutorial.seriesNo": "Series year *",
  "label.admin.portal.tutorial.success": "Series Created",

  "label.admin.portal.button.session.create": "Create Session",
  "label.admin.portal.library.button.event.create": "Create Event",
  "label.admin.portal.library.button.episode.create": "Create Episode",
  "label.admin.portal.button.session.edit": "Edit Session",
  "label.admin.portal.button.episode.edit": "Edit Episode",
  "label.admin.portal.button.session.name": "Session Name *",
  "label.admin.portal.library.button.event.name": "Event Name *",
  "label.admin.portal.library.button.episode.name": "Episode Name *",
  "label.admin.portal.button.session.number": "Session No. *",
  "label.admin.portal.button.event.number": "Event No. *",
  "label.admin.portal.button.episode.number": "Episode No. *",
  "label.admin.portal.input.fullPrice": "Full Price *",
  "label.admin.portal.input.fullPrice.notMandatory": "Full Price",
  "label.admin.portal.input.discountedPrice": "Discounted Price (Optional)",
  "label.admin.portal.button.section.add": "Add Section",
  "label.admin.portal.session.paid": "Paid",
  "label.admin.portal.session.free": "Free",
  "label.admin.portal.section.add": "Add Section",
  "label.admin.portal.section.edit": "Edit Section",
  "label.admin.portal.section.name": "Section Name*",
  "label.admin.portal.section.contentType": "Content Type*",

  "label.admin.portal.button.course.create": "Create Course",
  "label.admin.portal.button.course.edit": "Edit Course",
  "label.admin.portal.button.course.name": "Course Name *",

  "label.admin.portal.button.meditation.create": "Create Meditation",
  "label.admin.portal.button.meditation.edit": "Edit Meditation",
  "label.admin.portal.button.meditation.name": "Meditation Name *",
  "label.admin.portal.button.tutorial.createTutorial": "Create Tutorial",
  "label.admin.portal.button.tutorial.editTutorial": "Edit Tutorial",

  "label.admin.portal.button.article.create": "Create Article",
  "label.admin.portal.button.article.edit": "Edit Article",
  "label.admin.portal.button.article.name": "Article Name *",
  "label.admin.portal.button.article.number": "Article No. *",
  "label.admin.portal.button.article.description": "Article Description *",

  "label.admin.portal.button.podcast.create": "Create Podcast",
  "label.admin.portal.button.podcast.edit": "Edit Podcast",

  "label.admin.portal.button.event.create": "Create Event",
  "label.admin.portal.button.event.edit": "Edit Event",
  "label.admin.portal.button.event.name": "Event Name",
  "label.admin.portal.name": "Name*",
  "label.admin.portal.startDate": "Start Date*",
  "label.admin.portal.endDate": "End Date*",
  "label.admin.portal.fromDate": "From Date*",
  "label.admin.portal.toDate": "To Date*",
  "label.admin.portal.tutorialArchive": "Tutorial Archive series",
  "label.admin.portal.selectSeries": "Select a series",
  "label.admin.portal.selectSession": "Select a Session",
  "label.admin.portal.linkToTutorials": "Link to Tutorial Archive",
  "label.admin.portal.library.tutorial.archives": "Tutorial Archives",
  "label.admin.portal.button.foundation.edit": "Edit Details",

  // ----------- Payments ---------------- //
  "label.admin.portal.payments": "Payments",
  "label.admin.portal.payments.form.user": "User",
  "label.admin.portal.payments.dropdown.paymentType": "Payment type",
  "label.admin.portal.payments.dropdown.paymentType.placeHolder": "Select type of payment",
  "label.admin.portal.payments.dropdown.dateRange": "Date range",
  "label.admin.portal.payments.dropdown.dateRange.placeholder": "Select a date range",
  "label.admin.portal.payments.dropdown.paymentOutcome": "Payment outcome",
  "label.admin.portal.payments.dropdown.paymentOutcome.placeholder": "Select a type",
  "label.admin.portal.payments.table.invoicedDate": "Invoiced date",
  "label.admin.portal.payments.table.paidDate": "Paid date",
  "label.admin.portal.payments.table.action": "Action",
  "label.admin.portal.payments.table.subscription": "Subscription",
  "label.admin.portal.payments.table.cart": "Cart",
  "label.admin.portal.payments.table.orgPlan": "Full plan monthly - {{users}} users",
  "label.admin.portal.payments.table.individualBasicPlan": "Individual basic monthly",
  "label.admin.portal.payments.table.individualFullPlan": "Individual full monthly",
  "label.admin.portal.payments.form.heading": "Payment details",
  "label.admin.portal.payments.form.paymentLink": "Resend Payment Link",
  "label.admin.portal.payments.form.orgName": "Organisation",
  "label.admin.portal.payments.form.description": "Description",
  "label.admin.portal.payments.form.items": "Items",
  "label.admin.portal.payments.form.total": "Total",
  "label.admin.portal.payments.toast.text": "Resent Payment Link to ",

  // ----------- Mapping ---------------- //
  "label.text.mapping.heading": "Mapping",
  "label.text.mapping.overview.heading": "Overview",
  "label.text.mapping.overview.mappingVideos": "MAPPING VIDEOS",
  "label.text.mapping.results.heading": "Results",
  "label.text.mapping.results.latestResults": "Latest Mapping result",
  "label.text.mapping.results.showAllMaps": "Show all maps",
  "label.text.mapping.allMaps.heading": "All Maps",
  "label.text.mapping.allMaps.mappingResult": "Mapping result",

  "label.text.mappingTool.tooltip.redo": "Redo",

  "label.text.mappingTool.questionCard.optionLabel.constantly": "Constantly",
  "label.text.mappingTool.questionCard.optionLabel.regularly": "Regularly",
  "label.text.mappingTool.questionCard.optionLabel.occasionally": "Occasionally",
  "label.text.mappingTool.questionCard.question": "How often do you experience it ?",

  "label.text.mappingExploration.heading": "Exploration",

  "label.text.mappingTool.yAxisTitle.unity": "UNITY",
  "label.text.mappingTool.yAxisTitle.coCreate": "CO-CREATION",
  "label.text.mappingTool.yAxisTitle.mission": "MISSION",
  "label.text.mappingTool.yAxisTitle.connection": "CONNECTION",
  "label.text.mappingTool.yAxisTitle.power": "POWER",
  "label.text.mappingTool.yAxisTitle.security": "SECURITY",
  "label.text.mappingTool.yAxisTitle.survival": "SURVIVAL",

  "label.text.mappingTool.indices": "INDICES",
  "label.text.mappingTool.questions": "QUESTION",
  "label.text.mappingTool.answerAs": "ANSWER AS",
  "label.text.mappingTool.answerSelf.potentialSelf": "Potential Self",
  "label.text.mappingTool.answerSelf.potentialSelf.description":
    "When I move towards positive possibilities",
  "label.text.mappingTool.answerSelf.usualSelf": "Usual Self",
  "label.text.mappingTool.answerSelf.usualSelf.description": "When I stay in my comfort zone",
  "label.text.mappingTool.answerSelf.limitedSelf": "Limited Self",
  "label.text.mappingTool.answerSelf.limitedSelf.description": "When I give way to fear and doubt",

  "label.text.mappingExercise.successDialog.title": "Mapping complete",
  "label.text.mappingExercise.successDialog.description":
    "You have successfully completed your mapping exercise",
  "label.text.mappingExercise.successDialog.buttonLabel": "Go to Mapping Results",
  "label.text.mappingExercise.pauseDialog.title": "Mapping paused",
  "label.text.mappingExercise.pauseDialog.description": "Your Mapping exercise is currently paused",
  "label.text.mappingExercise.pauseDialog.buttonLabel": "Resume Mapping",
  "label.text.mappingExercise.closeDialog.title": "Are you sure?",
  "label.text.mappingExercise.closeDialog.description":
    "If you stop mapping all the mapped points so far will be lost",
  "label.text.mappingExercise.closeDialog.primaryButtonLabel": "Stop Mapping",
  "label.text.mappingExercise.redoDialog.description":
    "If you restart mapping all the mapped points so far will be lost",
  "label.text.mappingExercise.redoDialog.primaryButtonLabel": "Restart Mapping",

  "label.text.mappingResults.lineGraph.heading": "3 states of being",
  "label.text.mappingResults.bubbleGraph.heading": "Attention by level",
  "label.text.mappingResults.scatterGraph.heading": "Attention over time",

  "label.text.mappingResults.noMaps.heading": "No Maps created yet",
  "label.text.mappingResults.noMaps.description":
    "Must complete Mapping Exercise to see the results",

  "label.text.mappingInstructions.heading": "Guidance prior to Mapping Exercise",
  "label.text.mappingInstructions.subHeading1": "MAP LEVELS AND INDICATORS",
  "label.text.mappingInstructions.subHeading2": "PRE-MAPPING MINDFULNESS",
  "label.text.mappingInstructions.subHeading3": "EMBRACE INNER CONFIDENCE",
  "label.text.mappingInstructions.subHeading4": "MATCHING CELL EXPERIENCES",
  "label.text.mappingInstructions.subHeading5": "AVOIDING HASTY ASSUMPTIONS",
  "label.text.mappingInstructions.subHeading6": "HONEST SELF-ASSESSMENT",
  "label.text.mappingInstructions.description1":
    "Review the 7 levels and 12 indicators on the map. Familiarise yourself with the content of the cells.",
  "label.text.mappingInstructions.description2":
    "Sit in a quiet space and be present for at least 5 minutes before beginning the mapping exercise. Just breathe, feel yourself present in this moment.",
  "label.text.mappingInstructions.description3":
    "Allow a sense of inner calmness, capability and confidence. The main advice here is not to be worried about filling it in (for the perfectionists amongst you) as this will affect your ability to assess the answers dispassionately.",
  "label.text.mappingInstructions.description4":
    "For each indicator try to find the quality in the cell that most closely matches your experience. If the quality listed is not an exact fit, choose the one that most closely resembles your experience. (Hint: the level it occurs in is more indicative than the indicator it appears in so if you get stuck, review the levels and choose the one that best expresses your situation)",
  "label.text.mappingInstructions.description5":
    "Be careful not to let the mind stand in place of your deeper knowingness, and give you quick ‘ready-made’ answers as these will contain some element of assumption or judgement, however subtle, and will be counter-productive to the purpose of the exercise.",
  "label.text.mappingInstructions.description6":
    "Be really honest with yourself. Don’t try to get the answers you ‘want’ to have. You are looking to find out how you actually think and behave, rather than what you would like to be the case. The way to double-check this is to ask yourself if a video camera were to film your daily life over the course of a week / month, what would it actually show. (If you tend to suppress your inner thoughts and feelings so that your behaviour does not match your inner life, then you might need to imagine that an x-ray camera is recording your inner life to get an accurate read!)",

  "label.text.mapping.analytics.mapsCompleted": "Maps Completed",
  "label.text.mapping.analytics.avgMapsCompleted": "Average Maps Completed",
  "label.text.mapping.analytics.noMapsCompleted": "No Mappings have been completed yet",

  // ----------- Pricing Plans ---------------- //
  "label.admin.portal.pricingPlans": "Pricing Plans",
  "label.admin.portal.pricingPlans.homeCard.text": "Price per month",
  "label.admin.portal.pricingPlans.homeCard.individualBasic": "INDIVIDUAL - BASIC",
  "label.admin.portal.pricingPlans.homeCard.individualFull": "INDIVIDUAL - FULL",
  "label.admin.portal.pricingPlans.dialog.orgPlan.heading": "Organisation Plan",
  "label.admin.portal.pricingPlans.dialog.indBasicPlan.heading": "Individual Basic Plan",
  "label.admin.portal.pricingPlans.dialog.indFullPlan.heading": "Individual Full Plan",
  "label.admin.portal.pricingPlans.dialog.button.updatePlan": "Update Plan",
  "label.admin.portal.pricingPlans.dialog.button.updatePlan.text":
    "The update will reflect to all the users including existing users, all users will be notified about the price change.",
  "label.admin.portal.pricingPlans.dialog.details": "Details",
  "label.admin.portal.pricingPlans.dialog.planDescription": "Plan Description",
  "label.admin.portal.pricingPlans.dialog.changeLog": "Price Change Log",
  "label.admin.portal.pricingPlans.dialog.updateStatus": "View Update Status",
  "label.admin.portal.pricingPlans.dialog.updateStatus.statusbar": "view update status.",
  "label.admin.portal.pricingPlans.dialog.table.updatedOn": "Updated On",
  "label.admin.portal.pricingPlans.dialog.table.price": "Price/Month",
  "label.admin.portal.pricingPlans.dialog.table.referenceNum": "Reference Number",
  "label.admin.portal.pricingPlans.dialog.status.updatingText":
    "Updating plan. This may take a while.",
  "label.admin.portal.pricingPlans.dialog.status.updatingText.pageLevel":
    "Updating plan. This may take a while. You can view the update status once this process is complete.",
  "label.admin.portal.pricingPlans.dialog.status.successText": "Plan Updated",
  "label.admin.portal.pricingPlans.dialog.status.successText.page":
    "Update successful,",
  "label.admin.portal.pricingPlans.dialog.header.org": "Update Organisation Plan ?",
  "label.admin.portal.pricingPlans.dialog.header.indBasic": "Update Individual Basic Plan ?",
  "label.admin.portal.pricingPlans.dialog.header.indFull": "Update Individual Full Plan ?",
  "label.admin.portal.pricingPlans.details.customerName": "Customer Name",
  "label.admin.portal.pricingPlans.details.orgName": "Organisation Name",
  "label.admin.portal.pricingPlans.details.email": "Email id",
  "label.admin.portal.pricingPlans.details.planStatus": "Update Status",
  "label.admin.portal.pricingPlans.details.tableHeading": "Update status listing",
  "label.admin.portal.pricingPlans.details.planStatus.success": "Success",
  "label.admin.portal.pricingPlans.details.planStatus.fail": "Failed",
  "label.admin.portal.pricingPlans.details.update.dialog.title": "Update Status",
  "label.admin.portal.pricingPlans.details.update.dialog.descriptionTitle": "Failure Details",
  "label.admin.portal.pricingPlans.details.update.dialog.button": "Copy Message",
  "label.admin.portal.pricingPlans.retryUpdate.toastSuccessMsg":
    "Plan Updated. Email has been sent to notify all users",
  "label.admin.portal.pricingPlans.retryUpdate.organizations.toastSuccessMsg":
    "Plan Updated. Email has been sent to notify all organisations",
  "label.admin.portal.pricingPlans.retryUpdate.individual.toastSuccessMsg":
    "Plan Updated. Email has been sent to customer",
  "label.admin.portal.pricingPlans.retryUpdate.organisation.toastSuccessMsg":
    "Plan Updated. Email has been sent to organisation",
  "label.admin.portal.pricingPlans.saveDescription.toastSuccessMsg": "Plan description updated",
  "label.admin.portal.pricingPlans.savePrice.toastSuccessMsg": "Plan price updated",

  "label.admin.portal.pricingPlans.planInfo.basicDetails": "Basic Details",
  "label.admin.portal.pricingPlans.planInfo.planDesc": "Plan Description",
  "label.admin.portal.pricingPlans.planInfo.planDesc.compulsory": "Plan Description *",
  "label.admin.portal.pricingPlans.planInfo.price": "Price per month *",
  "label.admin.portal.pricingPlans.planInfo.pricing": "Pricing",

  "label.admin.portal.pricingPlans.planInfo.discardDialog.heading": "Discard Changes?",
  "label.admin.portal.pricingPlans.planInfo.discardDialog.button": "Discard",
  "label.admin.portal.pricingPlans.planInfo.discardDialog.message":
    "Discarding changes will lead to loss of unsaved changes done to the plan.",

  "label.admin.portal.pricingPlans.planInfo.updateDesc.heading": "Update Plan Description ?",
  "label.admin.portal.pricingPlans.planInfo.updateDesc.desc":
    "The updated plan description will be reflected on Stripe as well",

  "label.admin.portal.pricingPlans.planDetails.organizations": "Organisations",
  "label.admin.portal.pricingPlans.planDetails.organization": "Organisation",
  "label.admin.portal.pricingPlans.planDetails.customers": "Customers",
  "label.admin.portal.pricingPlans.planDetails.customer": "Customer",
  "label.admin.portal.pricingPlans.planDetails.success": "Success",
  "label.admin.portal.pricingPlans.planDetails.failed": "Failed",
  "label.admin.portal.pricingPlans.planDetails.toast.errorMessage.success":
    "Failure details copied successfully",
};

export default ALL_CONST;
