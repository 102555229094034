import { BREADCRUMB_STYLE, MENU_ITEM_STYLE } from "assets/base-theme";
import BeginnerCard from "components/BeginnerCard/BeginnerCard";
import ButtonBox from "components/ButtonBox/ButtonBox";
import { Accordion, AccordionTab } from "primereact/accordion";
import InputTextArea from "components/InputTextArea/InputTextArea";
import InputNumber from "components/InputNumber/InputNumber";
import ButtonIcon from "components/ButtonIcon/ButtonIcon";
import MenuBox from "components/MenuBox/MenuBox";
import OverviewCard from "components/OverviewCard/OverviewCard";
import PageHeading from "components/PageHeading/PageHeading";
import SimpleBreadCrumb from "components/SimpleBreadCrumb";
import SimpleTable from "components/SimpleTable/SimpleTable";
import Tab from "components/Tab/Tab";
import Text from "components/Text/Text";
import { RouteProps, withRouter } from "hoc/withRouter";
import { linkLibraryTutorialCreateSession, linkPageTutorialList } from "portalAdminRoutes";
import queryString from "query-string";
import { Component } from "react";
import ModuleService from "services/ModuleService";
import TopicService from "services/TopicService";
import { validateGetAPIResponse } from "utils/CommonUtil";
import { CATEGORY_NAME, IS_ACTIVE, STATUS_TYPE } from "utils/Constant";
import PageCreateTutorial from "../PageCreateTutorial/PageCreateTutorial";
import SimpleDropDown from "components/SimpleDropDown/SimpleDropDown";
import TertiaryButton from "components/TertiaryButton/TertiaryButton";
import DialogBox from "components/DialogBox/DialogBox";
// -------------------------------------------------- IProps ----------------------------------------------------- //
interface IProps extends RouteProps {}

// -------------------------------------------------- IStates ---------------------------------------------------- //

interface IState {
  sessionListTable: {
    limit: number;
    start: number;
    loading: boolean;
    totalRecords: number;
    search: string;
  };
  searchObj: {
    statusType: string;
    active: string;
  };
  revisedPrice?: {
    price?:number,
    currency?:string,
  };
  revisedNote?: string;
  deactivateDialog: boolean;
  currentArticleUid: string | null;
  currentItemActive: boolean;
  activateDialog: boolean;
  currentItemIsDraft: boolean;
  moduleListOriginalResponse: any[];
  moduleDetailsOriginalResponse: any[];
  moduleDetailsVisible: boolean;
  isEditDetail: boolean;

  tutorialUid: string;
  headerLoader: boolean;
}

// -------------------------------------------------- Class Component -------------------------------------------------- //
class ExtendedComponent extends Component<IProps, IState> {
  [key: string]: any;
  inviteTeamCardRef?: any;
  inviteTeamCardMenu?: any;
  breadCrumbList?: any[];
}

class PageSessionListing extends ExtendedComponent {
  /*
  --------------------------------------------------------------------------------------------------------------------
  Constructor:
  --------------------------------------------------------------------------------------------------------------------
  */
  constructor(props: IProps) {
    super(props);
    this.state = {
      sessionListTable: {
        limit: 30,
        start: 0,
        loading: false,
        totalRecords: 0,
        search: ``,
      },
      revisedPrice: undefined,
      revisedNote: "",
      headerLoader: false,
      moduleListOriginalResponse: [],
      moduleDetailsOriginalResponse: [],
      moduleDetailsVisible: false,
      isEditDetail: false,
      tutorialUid: "",
      activateDialog: false,
      searchObj: {
        statusType: "",
        active: "",
      },
      deactivateDialog: false,
      currentArticleUid: null,
      currentItemActive: true,
      currentItemIsDraft: true,
    };
    this.breadCrumbList = [];
    this.inviteTeamCardMenu = [
      {
        items: [
          {
            label: "label.text.analytics.team.edit",
            className: MENU_ITEM_STYLE.default,
          },
          {
            label: "label.text.analytics.team.unpublished",
            className: MENU_ITEM_STYLE.default,
          },
        ],
      },
    ];
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.renderActive = this.renderActive.bind(this);
    this.renderTutorialTables = this.renderTutorialTables.bind(this);
    this.renderSearchFilters = this.renderSearchFilters.bind(this);
    this.statusTypeOptions = this.statusTypeOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onApplyFilter = this.onApplyFilter.bind(this);
    this.onClearFilters = this.onClearFilters.bind(this);
    this.onToggleDeactivateDialog = this.onToggleDeactivateDialog.bind(this);
    this.deactivateItem = this.deactivateItem.bind(this);
    this.activateItem = this.activateItem.bind(this);
    this.onToggleActivateDialog = this.onToggleActivateDialog.bind(this);
  }

  /*
    ---------------------------------------------------------------------------------------------------------------------
    LifeCycle Methods: 
    ---------------------------------------------------------------------------------------------------------------------
  */
  componentDidMount() {
    this.init();
  }

  init = () => {
    let parsed: {
      tutorialUid?: string;
    } = queryString.parse(this.props.location.search);

    if (parsed.tutorialUid && parsed.tutorialUid !== "undefined") {
      this.setState(
        {
          tutorialUid: parsed.tutorialUid,
        },
        () => {
          this.getTutorialInformation(this.state.tutorialUid);
        }
      );
    }
  };

  /*
    ---------------------------------------------------------------------------------------------
    Service Calls: For Getting Data from service/API
    ---------------------------------------------------------------------------------------------
  */

  getTutorialInformation = (uid: string) => {
    this.setState({
      headerLoader: true,
    });
    ModuleService.getAdminModuleInformation(uid)
      .then((res) => {
        if (validateGetAPIResponse(res)) {
          this.setState(
            {
              moduleDetailsOriginalResponse: res.data?.results,
            },
            () => {
              this.breadCrumbList = [
                {
                  label: "label.text.mainDashboard.menu.tutorials",
                  className: BREADCRUMB_STYLE.previous,
                  command: () => {
                    this.props.navigateAsAdmin(linkPageTutorialList);
                  },
                },
                {
                  label: `${this.state.moduleDetailsOriginalResponse[0].topicName}  `,
                  //label: "label.text.modules.individualModule.breadcrumb",
                  className: BREADCRUMB_STYLE.active,
                },
              ];
              let search = `&groupL0Code:eq=library&groupL1Code:eq=${CATEGORY_NAME.TUTORIAL_ARCHIVE}&groupL2Code:eq=${this.state.moduleDetailsOriginalResponse[0].topicName}&sort={"orderNo": "ASC"}`;
              this.setState(
                {
                  headerLoader: false,
                  sessionListTable: {
                    ...this.state.sessionListTable,
                    search,
                  },
                },
                () => {
                  this.dataTableRef.search();
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* 
    -----------------------------------------------------------------------------------------------------------------------
   On Click Functions
    -----------------------------------------------------------------------------------------------------------------------
  */

  onClickCreateSession = () => {
    this.props.navigateAsAdmin(
      `${linkLibraryTutorialCreateSession}?tutorialUid=${this.state.tutorialUid}&groupL0Code=${this.state.moduleDetailsOriginalResponse[0].groupL0Code}&groupL1Code=${this.state.moduleDetailsOriginalResponse[0].groupL1Code}&groupL2Code=${this.state.moduleDetailsOriginalResponse[0].topicName}`
    );
  };

  onHideModuleDetails = () => {
    this.setState({
      isEditDetail: false,
    });
  };

  renderSession = (topicData: any) => {
    this.props.navigateAsAdmin(
      `${linkLibraryTutorialCreateSession}?tutorialUid=${this.state.tutorialUid}&sessionUid=${topicData.uid}`
    );
  };

  onToggleMenu(e: any, rowData: any) {
    const isActive = rowData.isActive;
    const isDraft = rowData.status === "draft";
    this.setState({ currentItemActive: isActive, currentItemIsDraft: isDraft });

    this.inviteTeamCardMenu[0].items[0].command = () => {
      this.renderSession(rowData);
    };
    this.inviteTeamCardMenu[0].items[1] = isActive
      ? {
          label: "label.text.analytics.team.unpublished",
          className: `${MENU_ITEM_STYLE.default} ${isDraft ? "disabled-menu-item" : ""}`,
          command: () => {
            if (this.state.currentItemIsDraft) {
              return;
            }
            this.setState({ currentArticleUid: rowData.uid }, this.onToggleDeactivateDialog);
          },
        }
      : {
          label: "label.text.analytics.team.activate",
          className: MENU_ITEM_STYLE.default,
          command: () => {
            console.log("Setting currentArticleUid to:", rowData.uid);
            this.setState({ currentArticleUid: rowData.uid }, this.onToggleActivateDialog);
          },
        };

    this.inviteTeamCardRef.toggle(e);
  }

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Helper Functions:
    -----------------------------------------------------------------------------------------------------------------------
  */

  onToggleActivateDialog() {
    this.setState((prevState) => ({ activateDialog: !prevState.activateDialog }));
  }

  onToggleDeactivateDialog() {
    this.setState((prevState) => ({ deactivateDialog: !prevState.deactivateDialog,revisedPrice: undefined,
      revisedNote: "" }));
  }

  async activateItem() {
    if (this.state.currentArticleUid) {
      TopicService.activateLibraryItem(this.state.currentArticleUid)
        .then((res: any) => {
          this.setState({ activateDialog: false });

          this.dataTableRef.search();
        })
        .catch((error) => {
          console.error("Error Activating library item:", error);
        });
    } else {
      this.setState({ activateDialog: false });
    }
  }

  async deactivateItem() {
    const { currentItemIsDraft, revisedPrice, revisedNote } = this.state;
  
    if (currentItemIsDraft) {
      return;
    }
    if (this.state.currentArticleUid) {
      const data = {
        revisedPrice: revisedPrice?? undefined,
        revisedNote: revisedNote,
      };
  
      TopicService.deactivateLibraryItem(this.state.currentArticleUid, data, '-1')
        .then((res: any) => {
          this.setState({ deactivateDialog: false });
          this.dataTableRef.search();
        })
        .catch((error) => {
          console.error("Error deactivating library item:", error);
        });
    } else {
      this.setState({ deactivateDialog: false ,revisedPrice: undefined,
        revisedNote: ""});
    }
  }

  handleChange(e?: any) {
    this.setState({
      searchObj: { ...this.state.searchObj, [e.target.name]: e.target.value },
    });
  }

  statusTypeOptions() {
    let statusType: any = STATUS_TYPE.map((status: any) => {
      return { ...status };
    });

    return statusType;
  }

  activeOptions() {
    let statusType: any = IS_ACTIVE.map((status: any) => {
      return { ...status };
    });

    return statusType;
  }

  onClearFilters = () => {
    const { moduleDetailsOriginalResponse } = this.state;
    this.setState(
      {
        searchObj: {
          statusType: "",
          active: "",
        },
        sessionListTable: {
          ...this.state.sessionListTable,
          search: `&groupL0Code:eq=library&groupL1Code:eq=${CATEGORY_NAME.TUTORIAL_ARCHIVE}&groupL2Code:eq=${this.state.moduleDetailsOriginalResponse[0].topicName}&sort={"orderNo": "ASC"}`,
        },
      },
      () => {
        this.dataTableRef.search();
      }
    );
  };

  onApplyFilter() {
    const { statusType, active } = this.state.searchObj;

    let searchParams = [
      `&groupL0Code:eq=library`,
      `groupL1Code:eq=${CATEGORY_NAME.TUTORIAL_ARCHIVE}`,
      `groupL2Code:eq=${this.state.moduleDetailsOriginalResponse[0].topicName}`,
    ];

    if (statusType && statusType !== "all") {
      searchParams.push(`&status:eq=${statusType}`);
    }

    if (active && active !== "all") {
      searchParams.push(`isActive=${active.toLowerCase() === "active"}`);
    }

    searchParams.push(`sort={"orderNo": "ASC"}`);

    const search = searchParams.join("&");

    this.setState(
      {
        sessionListTable: {
          ...this.state.sessionListTable,
          search: search,
        },
      },
      () => {
        this.dataTableRef.search();
      }
    );
  }

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    sectional-render
    -----------------------------------------------------------------------------------------------------------------------
  */
  //sectional renders of the table
  moduleNo = (rowData: any) => {
    return (
      <>
        <div onClick={() => {}} className="text-primary-pText-900 font-BrownLight text-left ">
          {rowData?.orderNo}
        </div>
      </>
    );
  };

  renderModule = (rowData: any) => {
    return (
      <>
        <div onClick={() => {}} className="text-primary-pText-900 font-BrownLight text-left ">
          {rowData?.topicName}
        </div>
      </>
    );
  };

  renderStatus = (rowData: any) => {
    const statusText =
      rowData.status === "publish"
        ? "Published"
        : rowData.status === "draft"
        ? "Draft"
        : rowData.status;
    const textStyle = rowData.status === "draft" ? { color: "#E78600" } : {};
    return (
      <>
        <div
          onClick={() => {}}
          style={textStyle}
          className="text-primary-pText-900 font-BrownLight text-left "
        >
          {statusText}
        </div>
      </>
    );
  };

  renderCreated = (rowData: any) => {
    const date = new Date(rowData?.createdAt);
    const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, " / ");
    return (
      <>
        <div onClick={() => {}} className="text-primary-pText-900 font-BrownLight text-left ">
          {formattedDate}
        </div>
      </>
    );
  };

  renderModified = (rowData: any) => {
    const date = new Date(rowData?.updatedAt);
    const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, " / ");
    return (
      <>
        <div onClick={() => {}} className="text-primary-pText-900 font-BrownLight text-left ">
          {formattedDate}
        </div>
      </>
    );
  };

  renderPublished = (rowData: any) => {
    if (rowData.status === "publish") {
      const date = new Date(rowData?.updatedAt);
      const formattedDate = date.toLocaleDateString("en-GB").replace(/\//g, " / ");
      return (
        <>
          <div
            onClick={() => {
              this.renderSession(rowData);
            }}
            className="text-primary-pText-900 font-BrownLight text-left "
          >
            {formattedDate}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  renderEdit = (rowData: any, index: any) => {
    return (
      <>
        <div className="w-full h-full text-primary-pText-900 font-BrownLight text-left">
          <ButtonIcon
            type="MENU"
            iconClassName="w-6 h-6"
            onClick={(e: any) => this.onToggleMenu(e, rowData)}
          />
        </div>
      </>
    );
  };

  renderActive(rowData: any) {
    const isActive = rowData.isActive;
    return (
      <div>
        {isActive ? (
          <div className="bg-[#F1FAF5] h-6 w-16 border-solid border-[#8CDAAF] border-[1px] text-black px-[12px] py-[2px] rounded-md">
            Active
          </div>
        ) : (
          <div className="bg-[#FDF7EE] h-6 w-16 border-solid border-[#DA8C8C] border-[1px] text-gray-600 px-[8px] py-[2px] rounded-md">
            Inactive
          </div>
        )}
      </div>
    );
  }

  renderSearchFilters() {
    const { statusType, active } = this.state.searchObj;
    return (
      <div className="flex justify-between">
        <div className=" flex gap-8 w-54 text-xss font-BrownLight">
          <div>
            <SimpleDropDown
              name="statusType"
              label={"Stage"}
              placeholder={"Select an item"}
              value={statusType}
              optionLabel="label"
              optionValue="value"
              options={this.statusTypeOptions()}
              onChange={this.handleChange}
              className="input-placeholder module-check"
              variant="form"
            />
          </div>
          <div>
            <SimpleDropDown
              name="active"
              label={"Status"}
              placeholder={"Select an item"}
              value={active}
              onChange={this.handleChange}
              optionLabel="label"
              optionValue="value"
              className="input-placeholder module-check"
              variant="form"
              options={this.activeOptions()}
            />
          </div>
        </div>
        <div className="flex gap-6 mb-6">
          <div className="w-36 text-xss">
            <ButtonBox label="Apply filter" variant="outlined" onClick={this.onApplyFilter} />
          </div>
          <div className="max-w-fit text-xss">
            <TertiaryButton label="Clear" onClick={this.onClearFilters} />
          </div>
        </div>
      </div>
    );
  }

  renderTutorialTables() {
    return (
      <div className="bg-white h-full w-full rounded-lg pt-2 flex-grow mb-4">
        <SimpleTable
          //value={this.state.teamsDataTable.records}
          standardDataFormatPromise={TopicService.getTopicList}
          ref={(el) => (this.dataTableRef = el)}
          variant="tertiary"
          breakpoint="767px"
          className="border border-secondary-concrete px-0 overflow-x-auto mt-4 rounded-lg analytics-datatable flex flex-col"
          tableObj={this.state.sessionListTable}
          search={this.state.sessionListTable?.search}
          dataKey="id"
          //paginator
        >
          <Tab
            className="text-primary-ptext-900 text-left text-body-copy-1 w-2/12 p-1 h-12 pl-6"
            header="Session No"
            body={this.moduleNo}
            style={{ width: "10%" }}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1 w-2/12  p-1 h-12 pl-6"
            header="Session name"
            body={this.renderModule}
            //style={{ width: "29%" }}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1 w-[10%]  p-1 h-12"
            header="Status"
            body={this.renderStatus}
            //style={{ width: "14%" }}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1  w-2/12  p-1 h- pl-6"
            header="Created"
            body={this.renderCreated}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1 w-2/12  p-1 h-12"
            header="Modified"
            body={this.renderModified}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1  w-2/12  p-1 h-12"
            header="Published"
            body={this.renderPublished}
          />
          <Tab
            className="text-primary-ptext-900 text-left text-body-copy-1  w-1/12  p-1 h-12"
            header="Status"
            body={this.renderActive}
          />
          <Tab
            className="text-primary-ptext-900 text-right text-body-copy-1  w-2/12  p-1 h-12"
            header=""
            body={this.renderEdit}
          />
        </SimpleTable>
      </div>
    );
  }

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    render: The Main of the class will write here
    -----------------------------------------------------------------------------------------------------------------------
  */
  render() {
    return (
      <>
        <div>
          <div className="flex mx-6 my-6 items-center">
            <PageHeading className="max-w-fit " label="" onBackButtonClick />
            <div className="text-xss">
              <SimpleBreadCrumb model={this.breadCrumbList} />
            </div>
          </div>

          {this.state.headerLoader ? (
            <div className="mb h-50">
              <OverviewCard className="lg:w-full !h-50" loader />
            </div>
          ) : (
            <div>
              <BeginnerCard
                className="shadow w-full"
                title={this.state.moduleDetailsOriginalResponse[0]?.topicName}
                description={this.state.moduleDetailsOriginalResponse[0]?.description}
                alertImage={this.state.moduleDetailsOriginalResponse[0]?.thumbImageUrl}
                subTitle="Edit details"
                buttonIconType="EDIT_LABEL"
                onClick={() => {
                  this.setState({
                    isEditDetail: true,
                  });
                }}
              />
            </div>
          )}
          <div className="p-6 mt-3 flex flex-col lg:h-150 bg-white rounded-lg">
            <div className="flex justify-between">
              <div>
                <Text
                  label="label.text.libraryCardDetail.topicCard.session.title"
                  className="font-BrownRegular text-h3 text-left"
                />
              </div>
              <div className="w-36">
                <ButtonBox
                  label="label.admin.portal.button.session.create"
                  variant="contained"
                  className=" px-6"
                  onClick={() => {
                    this.onClickCreateSession();
                  }}
                />
              </div>
            </div>

            <div className="p-6 w-full flex-flex-flex-col bg-white rounded-lg mt-4">
              {this.renderSearchFilters()}
              {this.renderTutorialTables()}
            </div>

            <MenuBox
              className="editButton bg-white font-BrownLight shadow-buttonContainedShadow text-body-copy-1 text-primary-pText-1300 py-1.5 rounded-lg mt-0.5 flex justify-center "
              model={this.inviteTeamCardMenu}
              ref={(el) => (this.inviteTeamCardRef = el)}
            />
            <PageCreateTutorial
              visible={this.state.isEditDetail}
              isEditModule={true}
              editModuleDetails={this.state.moduleDetailsOriginalResponse[0]}
              onHide={(isRefresh: boolean) => {
                if (isRefresh) {
                  this.getTutorialInformation(this.state.tutorialUid);
                }
                this.onHideModuleDetails();
              }}
            />
          </div>

          <DialogBox
            visible={this.state.activateDialog}
            dialogClassName="sm:w-105"
            title={"Activate Library item?"}
            onHide={this.onToggleActivateDialog}
            message={
              "Activating this item will activate the library for all users. Previously purchased users will retain it in their My Study"
            }
            primaryButtonText="Activate"
            primaryButtonStyles=""
            primaryButtonHandler={this.activateItem}
            secondaryButtonHandler={this.onToggleActivateDialog}
          />

          <DialogBox
            title={"Deactivate Library item?"}
            visible={this.state.deactivateDialog}
            dialogClassName="sm:w-105"
            primaryButtonText="Deactivate"
            primaryButtonStyles="bg-primary-red-500 hover:bg-primary-red-400 focus:bg-primary-red-400"
            primaryButtonColor="error"
            message={
              <div>
                <p>
                  Deactivating this item will remove it from the library for all users. Previously
                  purchased users will retain it in their My Study
                </p>
                <Accordion className="mt-4 deactivate_accordion">
                  <AccordionTab header="Update Tutorial Session Price">
                    <div className="text-left mt-3">
                      <label htmlFor="revisedPrice" className="block mb-2">
                        Revised Price
                      </label>
                      <InputNumber
                        id="revisedPrice"
                        value={this.state.revisedPrice?.price}
                        onValueChange={(e) => this.setState({ revisedPrice:{price: e.value??undefined,currency:"GBP"} })}
                        mode="currency"
                        currency="GBP"
                        locale="en-US"
                        className=""
                        placeholder="Enter revised price"
                      />
                    </div>
                    <div className="mt-3">
                      <InputTextArea
                        label="Reason for Revision"
                        value={this.state.revisedNote}
                        onChange={(e) => this.setState({ revisedNote: e.target.value })}
                      />
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
            }
            onHide={this.onToggleDeactivateDialog}
            primaryButtonHandler={this.deactivateItem}
            secondaryButtonHandler={this.onToggleDeactivateDialog}
          />
        </div>
      </>
    );
  }
}

export default withRouter(PageSessionListing);
