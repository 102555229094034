import SimpleDropDown from "components/SimpleDropDown";
import Text from "components/Text";
import { DataTable } from "primereact/datatable";
import { Component } from "react";

/**
 * this.props.standardDataFormatPromise -> the promise that the getDefaultDataLoaderFunction uses
 * the standardDataFormatPromise must be a function(start, limit, search) that returns a promise
 * this.props.search -> the search text that the standardDataFormatPromise takes as input
 */

class SimpleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesOption: [5, 7, 10, 20, 30, 50],
      dataTable: {
        limit: props.tableObj?.limit ?? 5,
        loading: props.tableObj?.loading ?? false,
        totalRecords: props.tableObj?.totalRecords ?? 0,
        start: props.tableObj?.start ?? 0,
        records: [],
        search: props.tableObj?.search ?? "",
        page: props.tableObj?.page ?? 0,
      },
      startOffset: 0,
    };

    this.getDefaultDataLoaderFunction = this.getDefaultDataLoaderFunction.bind(this);
    this.emptyMessageRender = this.emptyMessageRender.bind(this);
    this.search = this.search.bind(this);
  }

  search() {
    this.getDefaultDataLoaderFunction();
  }

  componentDidMount() {
    if (this.props.standardDataFormatPromise) {
      this.getDefaultDataLoaderFunction();
    }
  }

  getDefaultDataLoaderFunction(e) {
    if (this.props.customQuery && this.props.runCustomQuery) {
      this.props
        .customQuery(e)
        .then((res) => {
          if (res.data) {
            console.log("POSTS", res.data);
            this.setState({
              dataTable: {
                ...this.state.dataTable,
                loading: false,
                records: res.data.results ?? (res.data.data ? res.data.data : []),
                totalRecords: res.data.totalRecords,
                start: res.data.start,
                search: "",
              },
            });
          } else {
            this.showError("You are unauthorized or might need to log in again.", "error");
          }
        })
        .catch((err) => {
          this.setState({
            dataTable: {
              ...this.state.dataTable,
              loading: false,
              records: [],
              start: 0,
              totalRecords: 0,
            },
          });
        });
      return;
    }

    if (this.props.standardDataFormatPromise) {
    } else {
      return;
    }

    var searchParams = {
      start: this.props.avoidStartOffset
        ? this.state.dataTable.start
        : this.state.dataTable.start === 0
        ? this.state.startOffset
        : this.state.dataTable.start,
      limit: this.props.rows ? this.props.rows : this.state.dataTable.limit,
      search: "",
    };

    if (e) {
      let page = e.page ? e.page : 0;
      let rows = e.rows ? e.rows : 0;
      let first = page * rows;
      first = this.props.avoidStartOffset ? first : first + this.state.startOffset;
      searchParams.start = first;
    }
    if (this.props.search) {
      searchParams.search = this.props.search;
    }

    if (this.props.tableObj.search) {
      searchParams.search = this.props.tableObj.search;
    }

    this.setState({
      dataTable: {
        ...this.state.dataTable,
        //start: searchParams.start,
        //search: searchParams.search,
        loading: true,
      },
    });

    var searchFn =
      this.props.search && this.props.standardDataFormatSearchPromise
        ? this.props.standardDataFormatSearchPromise
        : this.props.standardDataFormatPromise;
    searchFn(searchParams.start, searchParams.limit, searchParams.search)
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              dataTable: {
                ...this.state.dataTable,
                loading: false,
                records: res.data.results ?? res.data.data ?? [],
                totalRecords: res.data.totalRecords,
                start: searchParams.start,
                search: searchParams.search,
              },
            },
            () => {
              if (this.props.onUpdateCallback) {
                this.props.onUpdateCallback();
              }

              //Scroll to top
              if (this.props.scrollToTopOnUpdate) {
                let firstRow = document.querySelector(".p-datatable-scrollable-body tr");
                firstRow && firstRow.scrollIntoView({ behavior: "smooth" });
              }
            }
          );
        } else {
          this.showError("You are unauthorized or might need to log in again.", "error");
        }
      })
      .catch((err) => {
        this.setState({
          dataTable: {
            ...this.state.dataTable,
            loading: false,
            records: [],
            start: 0,
            totalRecords: 0,
          },
        });
      });
  }

  emptyMessageRender() {
    return (
      <div className="flex flex-col w-full h-16 justify-center items-center">
        <div className="text-sm pt-3 font-BrownMedium">
          {this.props.showLoader ? (
            <div>Loading...</div>
          ) : (
            this.props.emptymessage ?? "No data found."
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      avoidStartOffset,
      responsive,
      variant,
      simpleTableClasses,
      customQuery,
      dataLoaderFunction,
      standardDataFormatSearchPromise,
      standardDataFormatPromise,
      isRowsPerPageRequired,
      ...primeReactProps
    } = this.props;
    const { selectionMode, selection, dataKey, onRowSelect, paginator } = primeReactProps;

    const variantClasses = variant === "tertiary" ? " insight-table" : "";

    const paginatorLeft = (
      <Text
        label={`${
          this.state.dataTable.start + 1 > this.state.dataTable.totalRecords
            ? this.state.dataTable.totalRecords
            : this.state.dataTable.start + 1
        }-${
          this.state.dataTable.start + this.state.dataTable.limit <
          this.state.dataTable.totalRecords
            ? this.state.dataTable.start + this.state.dataTable.limit
            : this.state.dataTable.totalRecords
        } of ${this.state.dataTable.totalRecords}`}
        className="text-primary-gray-700 text-xss font-BrownLight ml-4"
      />
    );

    const onChange = (e) => {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          limit: e.value,
        },
      });
    };

    const template1 = {
      layout: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown ",

      RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 50, value: 50 },
          // { label: "All", value: options.totalRecords },
        ];
        return (
          <div className="flex ml-auto items-baseline">
            {!isRowsPerPageRequired ? (
              <>
                <div>
                  <Text
                    label={"label.text.datatable.rowPerPage"}
                    className="text-primary-gray-700 text-xss font-BrownLight ml-4"
                  />
                </div>
              </>
            ) : null}

            <SimpleDropDown
              value={options.value}
              options={dropdownOptions}
              onChange={onChange}
              className="border-none !w-15 !text-primary-gray-700 !text-xss !font-BrownLight !flex !items-start !pt-4"
              //disabled={options.value > options.totalRecords}
            />
          </div>
        );
      },
    };

    return (
      <div className={variantClasses}>
        <DataTable
          className="text-primary-pText-900 text-left align-text-top"
          emptyMessage={this.emptyMessageRender}
          search={""}
          dataLoaderFunction={this.getDefaultDataLoaderFunction}
          value={this.state.dataTable.records}
          first={this.state.dataTable.start}
          rows={this.state.dataTable.limit}
          totalRecords={this.state.dataTable.totalRecords}
          // totalRecords={100}
          onPage={this.getDefaultDataLoaderFunction}
          loading={this.state.dataTable.loading}
          selection={selection}
          onRowSelect={(e) => (onRowSelect ? this.props.selectThisRow(e) : "")}
          selectionMode={selectionMode}
          dataKey={dataKey}
          resizableColumns={false}
          paginator={paginator}
          paginatorTemplate={template1}
          paginatorLeft={paginatorLeft}
          lazy
          rowClassName={this.props.rowClassName}
          responsiveLayout="scroll"
          breakpoint="768px"
          onRowClick={this.props.onRowClick}
          bodyClassName={this.props.bodyClassName}
          tableClassName={this.props.tableClassName}
          ref={this.props.ref}
          {...primeReactProps}
        />
      </div>
    );
  }
}

export default SimpleTable;
