import { Uploader } from "utils/Uploader";
import BaseApiService from "./BaseApiService";

const DEFAULT_PATH = "/storage/api";

class FileUploadService extends BaseApiService {
  saveImage = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    // Generate a unique object name
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const objectName = `${timestamp}_${randomString}`;
    let url = `${DEFAULT_PATH}/awsS3/false/${objectName}/upload`;

    return this.makePostRequestWithAuth(url, formData, {
      "Content-Type": "multipart/form-data",
    });
  };

  saveVideo = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    // Generate a unique object name
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const objectName = `${timestamp}_${randomString}`;
    let url = `${DEFAULT_PATH}/:provider/false/${objectName}/upload`;

    if (file) {
      let percentage: any = undefined;

      const videoUploaderOptions = {
        fileName: file.name,
        file: file,
      };
      const uploader = new Uploader(videoUploaderOptions);
      //setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            //setProgress(percentage);
            console.log("percentage", `${percentage}%`);
            const progressElement = document.getElementById("progress");
            if (progressElement) {
              progressElement.textContent = `Uploading... ${percentage}%`;
            }
          }
        })
        .onError((error: any) => {
          //setFile(undefined);
          console.error(error);
        });

      await uploader.start();
      const finalUrl = await uploader.getFinalUrl();
      console.log(finalUrl);
      const { data, ...rest } = finalUrl;
      const transformedFinalUrl = {
        data: { videoUrl: data },
        ...rest,
      };
      return transformedFinalUrl;
    }

    // return this.makePostRequestWithAuth(url, formData, {
    //   "Content-Type": "multipart/form-data",
    // });
  };

  getSignedURL(objectUrl: string, expiry: any) {
    let url = `${DEFAULT_PATH}/awsS3/signed/url?objectUrl=${objectUrl}&expiry=${expiry}`;
    return this.makeGetRequestWithAuth(url);
  }
}

export default new FileUploadService();
