import { GROUP_L0_CODE } from "utils/Constant";
import BaseApiService from "./BaseApiService";
const DEFAULT_PATH = "/custom/api/user";
const DEFAULT_ADMIN_PATH = "/custom/api";

class ModuleService extends BaseApiService {
  getInformation() {
    let url = `${DEFAULT_PATH}/module/search`;
    return this.makeGetRequestWithAuth(url);
  }

  getModuleInformation(topicUid: string) {
    let url = `${DEFAULT_PATH}/module/search?topicUid=${topicUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  getModuleCardInformation() {
    let url = `${DEFAULT_PATH}/topic/module/state?groupL0Code=${GROUP_L0_CODE.MODULE}`;
    return this.makeGetRequestWithAuth(url);
  }

  getModuleTopicInformation(topicName: String) {
    let url = `${DEFAULT_PATH}/topic/module/state?groupL0Code=${GROUP_L0_CODE.MODULE}&topicName=${topicName}`;
    return this.makeGetRequestWithAuth(url);
  }

  getAllTopics(foundationUid: string) {
    let url = `${DEFAULT_PATH}/topic/search?topicUid=${foundationUid}`;
    return this.makeGetRequestWithAuth(url);
  }
  getAll(start = 0, limit = 10, search = "") {
    let url = `${DEFAULT_PATH}`;
    this.makeGetRequest(url);
  }
  save(obj: any) {
    let url = this.getDefaultApiUrl();
    url = `${url}${DEFAULT_PATH}`;
    return this.getAxios().post(url, { ...obj });
  }

  //Admin APIs

  getModuleList = (start = 0, limit = 10, query = "") => {
    let url = `${DEFAULT_ADMIN_PATH}/topic/search?${query}&start=${start}&limit=${limit}&groupL0Code:eq=module&groupL1Code:null=`;
    return this.makeGetRequestWithAuth(url);
  };

  getTopicList = (start = 0, limit = 10, query = "") => {
    let url = `${DEFAULT_ADMIN_PATH}/topic/search?${query}&start=${start}&limit=${limit}&groupL0Code:eq=Module`;
    return this.makeGetRequestWithAuth(url);
  };

  getModuleTopicList = (start = 0, limit = 10, query = "") => {
    let url = `${DEFAULT_ADMIN_PATH}/topic/management/search?${query}&start=${start}&limit=${limit}`;
    return this.makeGetRequestWithAuth(url);
  };

  getAdminModuleInformation(topicUid: string) {
    let url = `${DEFAULT_ADMIN_PATH}/topic/search?uid=${topicUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  getAdminModuleInformationWithPlan(topicUid: string) {
    let url = `${DEFAULT_ADMIN_PATH}/topic/management/details/${topicUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  createModule = (payload: any) => {
    let endPoint = `${DEFAULT_ADMIN_PATH}/topic/management/publish`;
    return this.makePostRequestWithAuth(endPoint, payload);
  };

  updateModule = (payload: any, moduleUId: any) => {
    let endPoint = `${DEFAULT_ADMIN_PATH}/topic/management/publish/${moduleUId}`;
    return this.makePutRequestWithAuth(endPoint, payload);
  };

  update() {}
  delete() {}
  findById(id: string) {}
}

export default new ModuleService();
