import {
  MAPPING_ELLIPSE_DATA_BEGIN,
  MAPPING_ELLIPSE_DATA_SUCCESS,
  MAPPING_ELLIPSE_DATA_FAILURE,
} from "../actions/1_ActionConstants";

const initialState = {
  mappingEllipseData: [],
  loading: false,
  error: null,
};

export default function MappingEllipseDataReducer(state = initialState, action) {
  switch (action.type) {
    case MAPPING_ELLIPSE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case MAPPING_ELLIPSE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        mappingEllipseData: action.payload ?? [],
      };

    case MAPPING_ELLIPSE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
