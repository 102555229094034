import { LABEL_STYLES } from "assets/base-theme";
import SimpleToolTip from "components/SimpleToolTip/SimpleToolTip";
import Text from "components/Text";
import React from "react";
import { generateClasses } from "utils/Util";
import InfoIcon from "assets/img/common/Info_Logo.svg";
import { ILabelProps } from "interface/component";

const Label: React.FC<ILabelProps> = (props: ILabelProps) => {
  const {
    label,
    hideLabel,
    labelContainerClassname,
    labelClassNames,
    labelStyleObj,
    disabled,
    variant,
    isInvalid,
    toolTipContent,
    toolTipClassName,
  } = props;

  const getClassNames = () => {
    const styleObject = {
      ...LABEL_STYLES,
      overrideStyle: labelStyleObj,
      overrideClasses: labelClassNames,
      isInvalid: isInvalid,
      isDisabled: disabled,
    };

    return generateClasses(styleObject, variant);
  };

  return (
    <>
      {label ? (
        <div className={`flex items-end ${labelContainerClassname ?? ""}`}>
          <Text className={`${getClassNames()}`} label={label} />
          {toolTipContent! ? (
            <React.Fragment>
              <div className="ml-infoMargin cursor-pointer infoIcon pointer-events-auto">
                <img src={InfoIcon} alt="Information logo" />
              </div>
              <SimpleToolTip
                target={".infoIcon"}
                content={toolTipContent}
                position="top"
                className={toolTipClassName}
              />
            </React.Fragment>
          ) : null}
        </div>
      ) : hideLabel ? (
        <></>
      ) : (
        <div className={`h-4 mb-1 `} />
      )}
    </>
  );
};

export default Label;
