import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { IBaseInputCommonProps } from "interface/component/Input";
import Label from "components/Label";
import InputError from "components/InputError";

export interface IBaseInputTextAreaProps extends InputTextareaProps, IBaseInputCommonProps {
  variant?: "default" | "secondary";
  maxCount?: number;
}
const BaseInputTextArea: React.FC<IBaseInputTextAreaProps> = (props: IBaseInputTextAreaProps) => {
  const {
    variant,
    maxCount,
    hideLabel,
    label,
    labelClassNames,
    labelStyleObj,
    validation,
    hideErrorRow,
    errorClassNames,
    errorStyleObj,
    ...PrimeReactProps
  } = props;

  const { placeholder, className, value, name, onChange, rows } = PrimeReactProps;

  const [baseValue, setBaseValue] = useState<any>(value ?? "");
  const [charCount, setCharCount] = useState<any>(value?.toString().length);

  useEffect(() => {
    if (value) {
      setBaseValue(value);
      setCharCount(value?.toString().length);
    }

  }, [value])

  const handleChange = (event: any) => {
    const inputValue = event.target?.value;
    if (maxCount && inputValue.length <= maxCount) {
      setBaseValue(inputValue);
      setCharCount(inputValue.length);
      if (onChange) {
        onChange(event); // Call the provided onChange prop
      }
    } else if (!maxCount && onChange) {
      onChange(event); // Call the provided onChange prop
    }
  };

  return (
    <div className="flex flex-col">
      {!hideLabel && (
        <Label
          label={label}
          labelClassNames={labelClassNames}
          labelStyleObj={labelStyleObj}
          isInvalid={validation}
        />
      )}
      <InputTextarea
        {...PrimeReactProps}
        className={className}
        value={variant === "secondary" ? baseValue : value}
        onChange={variant === "secondary" ? handleChange : onChange}
        name={name}
        placeholder={placeholder}
        rows={rows}
        autoResize
      />
      {variant === "secondary" ? (
        <div className="w-full flex justify-between items-end">
          <InputError validation={validation} hideErrorRow={hideErrorRow} errorClassNames="" />

          <div className="text-xss font-BrownLight">
            {charCount}/{maxCount}
          </div>
        </div>
      ) : (
        <InputError validation={validation} hideErrorRow={hideErrorRow} errorClassNames="" />
      )}
    </div>
  );
};

export default BaseInputTextArea;
