import React from "react";
import BaseInputNumber, { IBaseInputNumberProps } from "components/base/BaseInputNumber";
import { INPUT_BOX_STYLES, INPUT_NUMBER_STYLES } from "assets/base-theme";
import { IInputCommonStyleProps } from "interface/component/Input";
import { generateClasses } from "utils/Util";
import { useTranslation } from "react-i18next";

interface IInputNumberProps extends IBaseInputNumberProps, IInputCommonStyleProps {}

const InputNumberWithoutMemo: React.FC<IInputNumberProps> = React.forwardRef(
  (props: IInputNumberProps, ref: any) => {
    const { variant, placeholder, styleObj, ...BaseInputProps } = props;

    // ----------- Get styles from base-theme.ts ---------------- //
    const getClassNames = () => {
      const styleObject = {
        ...INPUT_BOX_STYLES,
        overrideStyle: styleObj,
        overrideClasses: props.className,
        isDisabled: props.disabled,
        isInvalid: props.validation ? true : false,
        removeStyleProperty: [],
        removeClasses: [],
      };

      return generateClasses(styleObject, variant);
    };

    const { t } = useTranslation();
    let placeholderLabel = t(placeholder ?? "");

    return (
      <>
        <BaseInputNumber
          {...BaseInputProps}
          className={getClassNames()}
          placeholder={placeholderLabel}
          validation={props.validation}
          ref={ref}
        />
      </>
    );
  }
);

// memoization

const InputNumber = React.memo(InputNumberWithoutMemo);
// redux wiring

// exports
export default InputNumber;
