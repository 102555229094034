import BaseApiService from "./BaseApiService";
const DEFAULT_PATH = "/custom/api/user";
const DEFAULT_CUSTOM_PATH = "/custom/api";

class TopicService extends BaseApiService {
  getAllTopics(foundationUid: string) {
    let url = `${DEFAULT_PATH}/topic/search?topicUid=${foundationUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  deactivateLibraryItem(
    foundationUid: string,
    options?: {
      revisedPrice?: { price?: number; currency?: string };
      revisedNote?: string;
    },
    tenantKey?: string
  ) {
    let url = `/custom/api/topic/management/library/deactivate/${foundationUid}`;
    const additionalHeaders = tenantKey ? { "x-tenant-key": tenantKey } : {};
    return this.makePutRequestWithAuth(url, options, additionalHeaders);
  }

  activateLibraryItem(foundationUid: string) {
    let url = `/custom/api/topic/management/library/activate/${foundationUid}`;
    return this.makePutRequestWithAuth(url);
  }

  getCurriculumInformation() {
    let url = `/custom/api/topic/management/view/curriculum`;
    return this.makeGetRequestWithAuth(url);
  }

  getTopicInfo(topicUid: string) {
    let url = `${DEFAULT_PATH}/topic/search?topicUid=${topicUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  getState = (groupL0Code: string) => {
    let url = `${DEFAULT_PATH}/topic/state?groupL0Code=${groupL0Code}`;
    return this.makeGetRequestWithAuth(url);
  };

  getQuestions(sectionUid: string) {
    let url = `${DEFAULT_PATH}/question/${sectionUid}/search`;
    return this.makeGetRequestWithAuth(url);
  }

  getSignedURL(payload: any, uid: string | undefined) {
    let url = `${DEFAULT_PATH}/signed/url/${uid}`;
    return this.makePostRequestWithAuth(url, payload);
  }

  getAll(start = 0, limit = 10, search = "") {
    let url = `${DEFAULT_PATH}`;
    this.makeGetRequest(url);
  }
  save(obj: any) {
    let url = this.getDefaultApiUrl();
    url = `${url}${DEFAULT_PATH}`;
    return this.getAxios().post(url, { ...obj });
  }
  update() {}
  delete() {}
  findById(id: string) {}

  //Admin APIs
  getAdminTopicInformation(topicUid: string) {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/details/${topicUid}`;
    return this.makeGetRequestWithAuth(url);
  }

  updateDraftTopic(obj: any, topicUid: string) {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/draft/${topicUid}`;
    return this.makePutRequestWithAuth(url, obj);
  }
  
  updatePublishTopic(obj: any, topicUid: string) {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/publish/${topicUid}`;
    return this.makePutRequestWithAuth(url, obj);
  }
  createDraftTopic(obj: any) {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/draft`;
    return this.makePostRequestWithAuth(url, obj);
  }
  createPublishTopic(obj: any) {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/publish`;
    return this.makePostRequestWithAuth(url, obj);
  }

  getTopicList = (start = 0, limit = 10, search = "") => {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/search?start=${start}&limit=${limit}${search}`;
    return this.makeGetRequestWithAuth(url);
  };

  getLibraryTopicList = (start = 0, limit = 10, search = "") => {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/management/library/search?start=${start}&limit=${limit}&${search}`;
    return this.makeGetRequestWithAuth(url);
  };

  // only for articles
  getLibraryArticleList = (start = 0, limit = 10, search = "") => {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/search?start=${start}&${search}&limit=${limit}`;
    return this.makeGetRequestWithAuth(url);
  };

  getAdminTutorialsInformation = () => {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/search?groupL0Code:eq=Tutorial&groupL1Code:null=`;
    return this.makeGetRequestWithAuth(url);
  };

  getAdminFoundationInformation = () => {
    let url = `${DEFAULT_CUSTOM_PATH}/topic/search?groupL0Code:eq=foundation&groupL1Code:null=`;
    return this.makeGetRequestWithAuth(url);
  };
}

export default new TopicService();
