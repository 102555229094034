import axios from "axios";
import { getEnvVariableValue, getLocalStorage } from "utils/CommonUtil";
import { LOCAL_STORAGE, USER_TYPE } from "utils/Constant";
import HelperService from "./HelperService";

type HTTP_METHODS = "get" | "post" | "put" | "delete" | "patch";

const DEFAULT_API_PATH = getEnvVariableValue("REACT_APP_BASE_API_URL");
const DEFAULT_PORTAL_ADMIN_API_PATH = getEnvVariableValue("REACT_APP_PORTAL_ADMIN_API_URL");

class BaseApiService {
  constructor(props?: any) {}

  getDefaultApiUrl() {
    if (getLocalStorage(LOCAL_STORAGE.USER_INFO)) {
      let userInfo = JSON.parse(getLocalStorage(LOCAL_STORAGE.USER_INFO));
      return userInfo?.userType === USER_TYPE.PLATFORM_ADMIN
        ? DEFAULT_PORTAL_ADMIN_API_PATH
        : DEFAULT_API_PATH;
    } else {
      return DEFAULT_API_PATH;
    }
  }

  getAxios() {
    return axios;
  }

  makeGetRequestWithAuth(url: string, headersObj = {}) {
    url = `${this.getDefaultApiUrl()}${url}`;
    let headers = { ...HelperService.getHeadersWithAuth(), ...headersObj };
    return this.getAxios().get(url, headers);
  }
  makeGetRequest(url: string) {
    url = `${this.getDefaultApiUrl()}${url}`;
    return this.getAxios().get(url);
  }

  makePostRequestWithAuth(url: string, body?: any, headersObj = {}) {
    url = `${this.getDefaultApiUrl()}${url}`;
    let headers = HelperService.getHeadersWithAuth();
    return this.getAxios().post(url, body, headers);
  }
  makePostRequest(url: string, body?: any, headersObj = {}) {
    url = `${this.getDefaultApiUrl()}${url}`;
    let headers = {
      headers: {
        ...HelperService.getHeaders(),
        ...headersObj,
      },
    };
    console.log(headers);

    return this.getAxios().post(url, body, headers);
  }

  makePutRequestWithAuth(url: string, body?: any, additionalHeaders = {}) {
    url = `${this.getDefaultApiUrl()}${url}`;
    let headers = HelperService.getHeadersWithAuth();
    headers.headers = { ...headers.headers, ...additionalHeaders };
    return this.getAxios().put(url, body, headers);
  }

  makePutRequest(url: string, body?: any) {
    url = `${this.getDefaultApiUrl()}${url}`;
    return this.getAxios().put(url, body);
  }

  makeDeleteRequestWithAuth(url: string) {
    url = `${this.getDefaultApiUrl()}${url}`;
    let headers = HelperService.getHeadersWithAuth();
    return this.getAxios().delete(url, headers);
  }
  makeDeleteRequest(url: string) {
    url = `${this.getDefaultApiUrl()}${url}`;
    return this.getAxios().delete(url);
  }

  axiosConfig(
    url: string,
    method: HTTP_METHODS,
    headers: any = HelperService.getHeaders() ?? undefined,
    data?: any
  ) {
    let config = {
      url: `${this.getDefaultApiUrl()}${url}`,
      method,
      headers: {
        ...headers,
      },
      data: data,
    };
    //@ts-ignore
    return axios(config);
  }
  axiosConfigWithAuth(url: string, method: HTTP_METHODS, headers?: any, data?: any) {
    let config = {};
    if (headers) {
      config = {
        url: `${this.getDefaultApiUrl()}${url}`,
        method,
        headers: { ...HelperService.getHeadersWithAuth().headers, ...headers },
        data: data,
      };
    } else {
      config = {
        url: `${this.getDefaultApiUrl()}${url}}`,
        method,
        headers: { ...HelperService.getHeadersWithAuth().headers },
        data: data,
      };
    }

    // console.log("config.header ", config);
    //@ts-ignore
    return axios(config);
  }
}

export default BaseApiService;
