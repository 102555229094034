import {
    MAPPING_DATA_BEGIN,
    MAPPING_DATA_SUCCESS,
    MAPPING_DATA_FAILURE,
  } from "../actions/1_ActionConstants";
  
  const initialState = {
    mappingData: [],
    loading: false,
    error: null,
  };
  
  export default function MappingDataReducer(state = initialState, action) {
    switch (action.type) {
      case MAPPING_DATA_BEGIN:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case MAPPING_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          mappingData: action.payload,
        };
  
      case MAPPING_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: "FAILED TO LOAD,..,.", //action.payload.error,
        };
      default:
        // ALWAYS have a default case in a reducer
        return state;
    }
  }
  