import React, { Component } from "react";

class Tab extends Component {
  /**Just a dummy placeholder class since TabView needs it */
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <React.Fragment />;
  }
}

export default Tab;
