import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Languages from "lang";
import { LANGUAGES } from "utils/Constant";
import el from "simple-react-validator/dist/locale/el";
import es from "simple-react-validator/dist/locale/es";
import fa from "simple-react-validator/dist/locale/fa";
import id from "simple-react-validator/dist/locale/id";
import it from "simple-react-validator/dist/locale/it";
import ja from "simple-react-validator/dist/locale/ja";
import nl from "simple-react-validator/dist/locale/nl";
import pt from "simple-react-validator/dist/locale/pt";
import sl from "simple-react-validator/dist/locale/sl";
import sr from "simple-react-validator/dist/locale/sr";
import tr from "simple-react-validator/dist/locale/tr";
import fr from "simple-react-validator/dist/locale/fr";

const resources = Languages;
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window?.locale?.defaultLanguage || LANGUAGES.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
