import React from "react";
import { Trans } from "react-i18next";

interface IText {
  label?: any;
  className?: string;
  htmlFor?: string;
  onClick?: any;
  args?: any;
}
const Text = (props: IText) => {
  const { label, htmlFor, className, args, ...restProps } = props;
  return (
    <label htmlFor={htmlFor} className={` ${className ?? ""}`} {...restProps}>
      <Trans values={args}>{label}</Trans>
    </label>
  );
};

export default React.memo(Text);
