import { t } from "i18next";
import { IBaseButtonCommonProps } from "interface/component/Button";
import { Button, ButtonProps } from "primereact/button";
import React from "react";

export interface IBaseButtonProps extends ButtonProps, IBaseButtonCommonProps {
  args?: any;
}
const BaseButton: React.FC<IBaseButtonProps> = (props: IBaseButtonProps) => {
  const { className, iconPos, onClick, loadingIcon, icon, args, label } = props;
  let modifiedLabel: any = t(label ?? "", args);
  return (
    <div className="group w-full">
      <Button
        {...props}
        label={modifiedLabel}
        className={className}
        loadingIcon={loadingIcon}
        icon={icon}
        iconPos={iconPos}
        onClick={onClick}
      />
    </div>
  );
};

export default BaseButton;
