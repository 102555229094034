import SkeletonCustom from "components/Skeleton";
import { t } from "i18next";
import { IBreadCrumb, IPrimeReactMenuObj } from "interface/primereact";
import { BreadCrumb } from "primereact/breadcrumb";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      command: PropTypes.func,
      variant: PropTypes.string,
    })
  ),
};

interface ISimpleBreadCrumb extends IBreadCrumb {
  loader?: boolean;
  breadCrumbClassName?: string;
}
function SimpleBreadCrumb(props: ISimpleBreadCrumb) {
  const [updatedModel, setUpdatedModel] = useState(props.model);

  useEffect(() => {
    let tempModel: IPrimeReactMenuObj[] | undefined = props.model;
    let tempUpdatedModel: IPrimeReactMenuObj[] = [];
    tempModel?.forEach((item) => {
      let newObj: IPrimeReactMenuObj = {
        ...item,
        label: item.variant !== "secondary" ? t(item?.label ?? "") : item?.label,
      };
      tempUpdatedModel.push?.(newObj);
    });
    setUpdatedModel(tempUpdatedModel);
  }, [props.model]);

  const { home, loader, breadCrumbClassName } = props;
  if (loader) {
    return <SkeletonCustom height="1.25rem" width="50%" borderRadius="8px" />;
  }

  return (
    <>
      <BreadCrumb
        className={`breadcrumb-wrapper text-body-copy-1 ${breadCrumbClassName ?? ""}`}
        model={updatedModel}
        home={home}
      />
    </>
  );
}

SimpleBreadCrumb.propTypes = propTypes;

export default SimpleBreadCrumb;
