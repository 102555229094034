import {
  CART_COUNT_BEGIN,
  CART_COUNT_SUCCESS,
  CART_COUNT_FAILURE,
} from "../actions/1_ActionConstants";

const initialState = {
  cartCount: 0,
  loading: false,
  error: null,
};

export default function CartReducer(state = initialState, action) {
  switch (action.type) {
    case CART_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case CART_COUNT_SUCCESS:
      console.log(state);
      return {
        ...state,
        loading: false,
        cartCount: action.payload,
      };

    case CART_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
