import { AUTH_EMAIL_SUCCESS } from "../actions/1_ActionConstants";

const initialState = {
  loading: false,
  error: null,
  email: "example@punk.co.in",
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        email: action.payload,
      };
    default:
      return state;
  }
}
