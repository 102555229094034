import { combineReducers } from "redux";
import DemoArticleReducer from "./DemoArticleReducer";
import DemoListReducer from "./DemoListReducer";
import CounterReducers from "./CounterReducers";
import AuthReducer from "./AuthReducer";
import CartReducer from "./CartReducer";
import UserProfileReducer from "./UserProfileReducer";
import LearningReducer from "./LearningReducer";
import MappingDataReducer from "./MappingDataReducer";
import MappingEllipseDataReducer from "./MappingEllipseDataReducer";

const rootReducer = combineReducers({
  DemoArticleReducer,
  DemoListReducer,
  CounterReducers,
  AuthReducer,
  CartReducer,
  UserProfileReducer,
  LearningReducer,
  MappingDataReducer,
  MappingEllipseDataReducer,
});

export default rootReducer;
