import { IMenuBoxBaseProps } from "interface/component";
import { Menu, MenuProps } from "primereact/menu";
import React from "react";
import { useTranslation } from "react-i18next";

interface IMenuBoxProps extends IMenuBoxBaseProps, MenuProps {}

const MenuBoxWithoutMemo = React.forwardRef(
  (props: IMenuBoxProps, ref: any) => {
    const { model, id, className, appendTo } = props;
    const { t } = useTranslation();
    const translateOptions = (modal: any) => {
      if (Array.isArray(modal)) {
        let translatedOptionsTemp: any = [...modal];
        translatedOptionsTemp[0].items.forEach((option: any) => {
          if (option.label) {
            option.label = t(option.label);
          }
        });
        return translatedOptionsTemp;
      } else {
        return;
      }
    };

    let translatedOptions = translateOptions(model);

    return (
      <div>
        <Menu
          {...props}
          model={translatedOptions}
          className={className}
          popup
          ref={ref}
          id={id}
          appendTo={appendTo}
        />
      </div>
    );
  }
);

// ----------------------- Memoization -------------------------- //
const MenuBox = React.memo(MenuBoxWithoutMemo);

// -------------------------- redux ------------------------------ //

// --------------------------- export ---------------------------- //
export default MenuBox;
