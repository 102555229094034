import React from "react";
import { DIALOG_BOX_STYLES } from "assets/base-theme";
import { IWrapperInputProps } from "interface/component";
import { generateClasses } from "utils/Util";
import { Dialog, DialogProps } from "primereact/dialog";

const SidebarDialog = (props: any) => {
  const {
    onHide,
    visible,
    closable,
    appendTo,
    onShow,
    id,
    baseZIndex,
    className,
    contentClassName,
  } = props;
  const getClassName = () => {
    let classNames = "!absolute right-0 bg-white h-screen h-full";
    if (className) {
      classNames += classNames + className;
    }

    return classNames;
  };
  return (
    <div>
      <Dialog
        {...props}
        className={getClassName()}
        contentClassName={`pl-6 py-6 pr-3 sm:pr-4.5 sm:py-9 sm:pl-9 ${contentClassName ?? ""}`}
        onHide={onHide}
        onShow={onShow}
        visible={visible}
        closable={closable}
        appendTo={appendTo}
        baseZIndex={baseZIndex}
        modal
        id={id}
        position="right"
      />
    </div>
  );
};

// memoziation

// const DialogBox = React.memo(DialogWithoutMemo);
// redux wiring

// exports
export default SidebarDialog;
