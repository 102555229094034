import { Dialog, DialogProps } from "primereact/dialog";
import React from "react";
import ButtonBox from "components/ButtonBox";
import Text from "components/Text";
interface IDialogBoxProps extends DialogProps {
  title: string | React.ReactNode;
  headerVariant?: string;
  messageVariant?: string;
  message: string | React.ReactNode;
  secondaryLarge?: boolean;
  messageClassname?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  secondaryButtonVariant?: string;
  primaryButtonStyles?: string;
  secondaryButtonStyles?: string;
  primaryButtonColor?: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  primaryButtonHandler?: () => any;
  primaryButtonLoader?: boolean;
  secondaryButtonHandler?: () => any;
  dialogClassName?: string;
  messageDialog?: boolean;
  isTertiaryMessage?: boolean;
  secondaryFooterVariant?: boolean;
}

const DialogBox: React.FC<IDialogBoxProps> = (props: IDialogBoxProps) => {
  const {
    title,
    message,
    secondaryLarge,
    messageVariant,
    messageClassname,
    primaryButtonText,
    secondaryButtonText,
    secondaryButtonVariant,
    primaryButtonStyles,
    secondaryButtonStyles,
    primaryButtonColor,
    primaryButtonDisabled,
    secondaryButtonDisabled,
    primaryButtonLoader,
    primaryButtonHandler,
    secondaryButtonHandler,
    dialogClassName,
    headerVariant,
    messageDialog,
    isTertiaryMessage,
    secondaryFooterVariant,
    ...PrimeReactProps
  } = props;

  const { visible, style, contentClassName, headerClassName } = PrimeReactProps;

  const footerContent = !secondaryFooterVariant ? (
    <div className="flex flex-col-reverse gap-6 items-center sm:flex-row sm:gap-6">
      <div
        className={`w-full sm:w-40.5 text-xss ${messageDialog ? "mx-auto" : ""} ${
          secondaryLarge ? "!w-full" : ""
        }`}
      >
        <ButtonBox
          className={` ${secondaryButtonStyles ?? ""}`}
          label={`${secondaryButtonText ? secondaryButtonText : "component.button.cancel"}`}
          variant={`${secondaryButtonVariant ? secondaryButtonVariant : "outlined"}`}
          disabled={secondaryButtonDisabled}
          onClick={secondaryButtonHandler}
        />
      </div>
      {!messageDialog ? (
        <div className="w-full sm:w-40.5 text-xss">
          <ButtonBox
            className={` ${primaryButtonStyles ?? ""}`}
            label={primaryButtonText}
            variant="contained"
            color={primaryButtonColor ? primaryButtonColor : "primary"}
            disabled={primaryButtonDisabled}
            loader={primaryButtonLoader}
            onClickWithLoader={primaryButtonHandler}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full text-xss">
        <ButtonBox
          className={` ${primaryButtonStyles ?? ""}`}
          label={primaryButtonText}
          variant="contained"
          color={primaryButtonColor ? primaryButtonColor : "primary"}
          disabled={primaryButtonDisabled}
          onClickWithLoader={primaryButtonHandler}
        />
      </div>
      <div className="w-full text-xss">
        <ButtonBox
          className={` ${secondaryButtonStyles ?? ""}`}
          label={secondaryButtonText}
          variant={`${secondaryButtonVariant ? secondaryButtonVariant : "outlined"}`}
          disabled={secondaryButtonDisabled}
          onClickWithLoader={secondaryButtonHandler}
        />
      </div>
    </div>
  );

  return (
    <div className="group grid">
      <Dialog
        className={`mx-12 p-9 bg-white w-full rounded-xl gap-9 sm:m-0 ${dialogClassName ?? ""}`}
        contentClassName={`!overflow-y-hidden text-center ${contentClassName ?? ""}`}
        headerClassName={`text-h4 font-BrownMedium justify-center ${headerClassName ?? ""}`}
        visible={visible}
        header={headerVariant === "secondary" ? title : <Text label={title} />}
        style={style}
        footer={footerContent}
        modal
        closable={false}
        {...PrimeReactProps}
      >
        {!isTertiaryMessage ? (
          <div className={`m-0 font-BrownLight text-body-copy-1 ${messageClassname ?? ""} `}>
            {messageVariant === "secondary" ? message : <Text label={message} />}
          </div>
        ) : (
          <>{message}</>
        )}
      </Dialog>
    </div>
  );
};

// ---------------------- exports -------------------------//

export default DialogBox;
