//List Actions
export const FETCH_LISTDEMO_BEGIN = "FETCH_LISTDEMO_BEGIN";
export const FETCH_LISTDEMO_SUCCESS = "FETCH_LISTDEMO_SUCCESS";
export const FETCH_LISTDEMO_FAILURE = "FETCH_LISTDEMO_FAILURE";
//Other Actions...

export const COUNT_BEGIN = "COUNT_BEGIN";
export const COUNT_SUCCESS = "COUNT_SUCCESS";
export const COUNT_FAILURE = "COUNT_FAILURE";

//Auth Actions

export const AUTH_EMAIL_SUCCESS = "AUTH_EMAIL_SUCCESS";

// Cart Actions
export const CART_COUNT_BEGIN = "CART_COUNT_BEGIN";
export const CART_COUNT_SUCCESS = "CART_COUNT_SUCCESS";
export const CART_COUNT_FAILURE = "CART_COUNT_FAILURE";

// User Profile Actions
export const USER_PROFILE_BEGIN = "USER_PROFILE_BEGIN";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAILURE = "USER_PROFILE_FAILURE";

// User Profile Actions
export const LEARNING_MENU_LIST_BEGIN = "LEARNING_MENU_LIST_BEGIN";
export const LEARNING_MENU_LIST_SUCCESS = "LEARNING_MENU_LIST_SUCCESS";
export const LEARNING_MENU_LIST_FAILURE = "LEARNING_MENU_LIST_FAILURE";

//Mapping related Actions
export const MAPPING_DATA_BEGIN = "MAPPING_DATA_BEGIN";
export const MAPPING_DATA_SUCCESS = "MAPPING_DATA_SUCCESS";
export const MAPPING_DATA_FAILURE = "MAPPING_DATA_FAILURE";

export const MAPPING_ELLIPSE_DATA_BEGIN = "MAPPING_ELLIPSE_DATA_BEGIN";
export const MAPPING_ELLIPSE_DATA_SUCCESS = "MAPPING_ELLIPSE_DATA_SUCCESS";
export const MAPPING_ELLIPSE_DATA_FAILURE = "MAPPING_ELLIPSE_DATA_FAILURE";
