/* eslint-disable */
import React from "react";
import { createRoot } from "react-dom/client";
//import './index.css';
import "./assets/main.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import "primereact/resources/themes/md-light-indigo/theme.css";
import "./assets/custom-theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./assets/custom.css";
import "./i18n";
import { getEnvVariableValue } from "utils/CommonUtil";

const log = console.log.bind(console, "%c >>KAIJU CONSOLE", "color:red;font-weight:bolder");
window.console.log = log;

// to hide console in prod
if (getEnvVariableValue("REACT_APP_SHOW_LOGS") === "false") console.log = () => {};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <>
    <div id="errorDiv12399" className="" />
    <div id="notificationBanner8328" className="sticky z-50" style={{ top: "0px" }} />
    <App />
    <div id="notificationBannerBottom98328" className="sticky z-40" style={{ bottom: "0px" }} />
  </>
);

if (process.env.REACT_APP_ENABLE_PWA_MODE) {
  // to enable PWA mode uncomment the following
  serviceWorker.register({
    onSuccess: () => {
      console.log("SW >>> SUCCESSFULLY REGISTERED!!!");
    },
    onUpdate: (reg: any) => {
      console.log("SW >>> NEW UPDATE IS AVAILABLE!!!");
    },
  });
} else {
  // standard mode service workers are off i.e not PWA mode
  serviceWorker.unregister();
}
