import backIconHover from "assets/img/common/backIconHoverBlue.svg";
import backIconDefault from "assets/img/common/backIconNormal.svg";
import backIconSecondaryHover from "assets/img/common/backIconHoverWhite.svg";
import Text from "components/Text";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IPageHeadingProps {
  variant?: "default" | "secondary";
  badgeValue?: string;
  label: string;
  className?: string;
  onBackButtonClick?: any;
  onClickBack?: () => void;
}

function PageHeading(props: IPageHeadingProps) {
  const { className, variant, label, onBackButtonClick, badgeValue, onClickBack } = props;
  const navigate = useNavigate();
  const [imageHover, setImageHover] = useState(backIconDefault);

  const defaultVariant = () => {
    return (
      <>
        {onBackButtonClick ? (
          <div
            className="w-9 h-9 mr-3 rounded-full bg-white hover:bg-secondary-baseConcrete flex justify-center items-center cursor-pointer"
            onMouseOver={() => setImageHover(backIconHover)}
            onMouseOut={() => setImageHover(backIconDefault)}
            onClick={() => {
              onClickBack ? onClickBack() : navigate(-1);
            }}
          >
            <img src={imageHover} alt="back-icon" className="w-3" />
          </div>
        ) : null}

        <div className="flex flex-col">
          {badgeValue ? (
            <div className="font-BrownLight max-w-fit text-xxs text-primary-gray-700 mb-2 px-1.5 py-0.5 bg-primary-gray-100 rounded-lg">
              <Text label={badgeValue} />
            </div>
          ) : null}

          <div className="">
            <Text label={label} />
          </div>
        </div>
      </>
    );
  };

  const secondaryVariant = () => {
    return (
      <>
        {onBackButtonClick ? (
          <div
            className="w-9 h-9 mr-3 rounded-full bg-mapping-secondary-surface hover:bg-mapping-secondary-tileBackground flex justify-center items-center cursor-pointer"
            onMouseOver={() => setImageHover(backIconSecondaryHover)}
            onMouseOut={() => setImageHover(backIconDefault)}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={imageHover} alt="back-icon" className="w-3" />
          </div>
        ) : null}

        <div className="flex flex-col">
          {badgeValue ? (
            <div className="font-BrownLight max-w-fit text-xxs text-white mb-2 px-1.5 py-0.5">
              <Text label={badgeValue} />
            </div>
          ) : null}

          <div className="text-white">
            <Text label={label} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={`font-FreightProBook text-3xl text-left flex items-center ${className}`}>
      {variant === "secondary" ? secondaryVariant() : defaultVariant()}
    </div>
  );
}

export default PageHeading;
