//  Author : Harshavardhan P
//  Created on : 20 Jan, 2023

import {
  RADIO_BUTTON_PRIMARY_STYLE_CHECKED,
  RADIO_BUTTON_LIMITED_STYLE_CHECKED,
  RADIO_BUTTON_POTENTIAL_STYLE_CHECKED,
  RADIO_BUTTON_STYLES,
  RADIO_BUTTON_USUAL_STYLE_CHECKED,
} from "assets/base-theme";
import BaseRadioButton, { IBaseRadioButtonProps } from "components/base/BaseRadioButton";
import { IInputCommonStyleProps } from "interface/component/Input";
import React from "react";
import { generateClasses } from "utils/Util";

interface IRadioButtonProps extends IBaseRadioButtonProps, IInputCommonStyleProps {}

const RadioButtonWithoutMemo: React.FC<IRadioButtonProps> = (props: IRadioButtonProps) => {
  const { variant, className, styleObj, ...BaseRadioButtonProps } = props;

  const getClassNames = () => {
    let styleObject = {
      ...RADIO_BUTTON_STYLES,
      overrideStyle: styleObj,
      overrideClasses: props.className,
      isDisabled: props.disabled,
      removeStyleProperty: [],
      removeClasses: [],
    };

    if (props.checked && variant === "potential") {
      styleObject = { ...styleObject, potentialStyle: RADIO_BUTTON_POTENTIAL_STYLE_CHECKED };
      return generateClasses(styleObject, "POTENTIAL");
    } else if (props.checked && variant === "usual") {
      styleObject = { ...styleObject, usualStyle: RADIO_BUTTON_USUAL_STYLE_CHECKED };
      return generateClasses(styleObject, "USUAL");
    } else if (props.checked && variant === "limited") {
      styleObject = { ...styleObject, limitedStyle: RADIO_BUTTON_LIMITED_STYLE_CHECKED };
      return generateClasses(styleObject, "LIMITED");
    } else if (props.checked) {
      styleObject = { ...styleObject, primaryStyle: RADIO_BUTTON_PRIMARY_STYLE_CHECKED };
      return generateClasses(styleObject, "PRIMARY");
    } else {
      return generateClasses(styleObject, variant);
    }
  };

  return (
    <>
      <BaseRadioButton {...BaseRadioButtonProps} className={getClassNames()} />
    </>
  );
};

// ---------------------  memoization ----------------------------- //
const RadioButton = React.memo(RadioButtonWithoutMemo);

// --------------------- redux wiring ----------------------------- //

// ------------------------ exports ------------------------------- //
export default RadioButton;
