import Text from "components/Text";
import { ISimpleToolTipProps } from "interface/component";
import { Tooltip, TooltipProps } from "primereact/tooltip";
import React from "react";

interface IToolTipProps extends TooltipProps, ISimpleToolTipProps {
  ref?: any;
}

const SimpleToolTipWithoutMemo: React.FC<IToolTipProps> = React.forwardRef(
  (props: any, ref: any) => {
    const { content, htmlContent, ...primeReactProps } = props;
    const { target, position, className } = primeReactProps;
    const classArrow = " w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent ";
    const classArrowUp = classArrow + " border-b-4 border-b-secondary-shipClove-50 ";
    const classArrowDown = classArrow + " border-t-4 border-t-secondary-shipClove-50";

    return (
      <Tooltip
        target={target}
        position={position}
        className={className ?? ""}
        autoHide
        ref={ref}
        // hideDelay="3000000"
        {...primeReactProps}
      >
        <div className="flex flex-col justify-center items-center">
          {position === "bottom" ? <div className={classArrowUp} /> : null}
          {position === "top" ? <div className={classArrowDown + " left-1/2"} /> : null}
          {htmlContent !== undefined ? (
            htmlContent
          ) : (
            <div
              className={`bg-secondary-shipClove-50 rounded-lg px-3 py-1 font-BrownLight text-center absolute tooltip-text ${
                position === "bottom" ? "top-2" : ""
              } ${position === "top" ? "bottom-2" : ""}`}
            >
              <Text label={content} className="text-primary-pText-900 text-xs" />
              {/* {position === "top" ? <div className={classArrowDown + " absolute -bottom-1"} /> : null} */}
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
);

// ----------- memoization -------------------- //
const SimpleToolTip = React.memo(SimpleToolTipWithoutMemo);

// ----------- redux wiring ------------------- //

// ----------- exports ------------------------ //
export default SimpleToolTip;
