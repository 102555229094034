import InputError from "components/InputError";
import Label from "components/Label/Label";
import { IBaseInputCommonProps } from "interface/component/Input";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import React from "react";

export interface IBaseInputNumberProps extends InputNumberProps, IBaseInputCommonProps {
  onlyNumeric?: any;
  imgValue?: any;
  toolTipContent?: string;
  toolTipClassName?: string;
}
const BaseInputNumber: React.FC<IBaseInputNumberProps> = React.forwardRef(
  (props: IBaseInputNumberProps, ref: any) => {
    const {
      hideLabel,
      hideErrorRow,
      label,
      labelClassNames,
      labelStyleObj,
      errorClassNames,
      validation,
      imgValue,
      toolTipContent,
      toolTipClassName,
      ...PrimeReactProps
    } = props;

    const { placeholder, className, value, style, name, onChange } = PrimeReactProps;
    return (
      <div className="group grid">
        <span className="p-float-label">
          <InputNumber
            placeholder={placeholder}
            style={style}
            value={value}
            name={name}
            ref={ref}
            className={className}
            onChange={onChange}
            {...PrimeReactProps}
          />
          {!hideLabel && (
            <div className="inputLabel">
              <Label
                label={label}
                labelClassNames={labelClassNames}
                labelStyleObj={labelStyleObj}
                isInvalid={validation}
                toolTipClassName={toolTipClassName}
                toolTipContent={toolTipContent}
              />
            </div>
          )}
        </span>
        {!hideErrorRow && (
          <InputError
            validation={validation}
            hideErrorRow={hideErrorRow}
            errorClassNames={errorClassNames}
          />
        )}
      </div>
    );
  }
);

BaseInputNumber.defaultProps = {
  imgValue: undefined,
};

export default BaseInputNumber;
