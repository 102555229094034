import {
  USER_PROFILE_BEGIN,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
} from "../actions/1_ActionConstants";

const initialState = {
  userProfile: {},
  loading: false,
  error: null,
};

export default function CartReducer(state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case USER_PROFILE_SUCCESS:
      console.log(state);
      return {
        ...state,
        loading: false,
        userProfile: action.payload,
      };

    case USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "FAILED TO LOAD,..,.", //action.payload.error,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
