import ButtonBox from "components/ButtonBox";
import { FileUploadDemo } from "components/FileUpload";
import InputBox from "components/InputBox";
import InputNumber from "components/InputNumber";
import InputTextArea from "components/InputTextArea";
import RadioButton from "components/RadioButton";
import SidebarDialog from "components/SidebarDialog";
import Text from "components/Text";
import ToastCustom from "components/Toast";
import { RouteProps } from "hoc/withRouter";
import { Component } from "react";
// import React from "react";
// import RichTextEditor from "components/RichTextEditor/RichTextEditor";
import FileUploadService from "services/FileUploadService";
import ModuleService from "services/ModuleService";
import TopicService from "services/TopicService";
import SimpleReactValidator from "simple-react-validator";
import {
  getStandardValidator,
  messageReplace,
  parseStandardAPIErrorMessageArray,
  validateGetAPIResponse,
  validatePostAPIResponse,
} from "utils/CommonUtil";
import { CATEGORY_VALUE, DEFAULT_CURRENCY, SECTION_PRICE, TOAST_STYLE_TYPE } from "utils/Constant";

interface IProps extends RouteProps {
  visible: boolean;
  onHide: any;
  isEditModule: boolean;
  editModuleDetails: any;
}

// interface IEditorRef {
//   editor: {
//     getContent: () => void;
//     plugins: {
//       wordcount: {
//         body: {
//           getCharacterCount: () => any;
//         };
//       };
//     };
//   };
// }

interface IState {
  pageObj: {
    seriesTheme: string;
    seriesNumber: string;
    orderNo: any;
    description: string;
    imageUrl: string;
    streamingVideoUrl: string;
    signedVideoUrl?: any;
    isPaid: boolean;
    fullPrice: any;
    discountPrice: any;
    currency: string;
  };
  toastStyle: string;
  toastMessage: string;
  isVideoUploading?: boolean;
  isImageUploading?: boolean;
  imageUploadMessage: string;
  errorInUploadImage?: string;
}

class ExtendedComponent extends Component<IProps, IState> {
  [key: string]: any;
}

class PageCreateTutorial extends ExtendedComponent {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pageObj: {
        seriesTheme: "",
        seriesNumber: "",
        description: "",
        imageUrl: "",
        streamingVideoUrl: "",
        signedVideoUrl: "",
        isPaid: false,
        fullPrice: null,
        discountPrice: null,
        orderNo: null,
        currency: "GBP",
      },
      toastMessage: "",
      toastStyle: TOAST_STYLE_TYPE.PRIMARY,
      isVideoUploading: false,
      isImageUploading: false,
      imageUploadMessage: "",
      errorInUploadImage: "",
    };

    // this.editorRef = React.createRef<IEditorRef>();

    this.handleChange = this.handleChange.bind(this);
    this.getSignedURL = this.getSignedURL.bind(this);
    this.handleChangeForRadio = this.handleChangeForRadio.bind(this);
    this.handleChangeOrderNo = this.handleChangeOrderNo.bind(this);
    this.handleChangeFullPrice = this.handleChangeFullPrice.bind(this);
    this.handleChangeDiscountedPrice = this.handleChangeDiscountedPrice.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.enrichForCreateTutorial = this.enrichForCreateTutorial.bind(this);
    this.validate = this.validate.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: { ...getStandardValidator() },
      ...messageReplace(),
    });
  }

  /* 
    -----------------------------------------------------------------------------------------------------------------------
    Validators:
    -----------------------------------------------------------------------------------------------------------------------
  */
  validate(fieldName: string) {
    this.validator.showMessageFor(fieldName);
  }
  async validateForm() {
    let isValid: boolean = false;
    isValid = this.validator?.allValid() ?? false;
    if (isValid) {
      return isValid;
    } else {
      this.validator?.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  onShow = () => {
    this.setEditModuleDetails(this.props.editModuleDetails);
  };

  setEditModuleDetails = (moduleDetails: any) => {
    if (this.props.isEditModule) {
      ModuleService.getAdminModuleInformationWithPlan(moduleDetails?.uid)
        .then((res) => {
          if (validateGetAPIResponse(res)) {
            console.log(res.data);
            this.setState(
              {
                pageObj: {
                  seriesTheme: res.data.topicName,
                  seriesNumber: res.data.seriesNo,
                  orderNo: res.data.orderNo,
                  description: res.data.description,
                  imageUrl: res.data.imageUrl,
                  streamingVideoUrl: res.data.streamingVideoUrl,
                  isPaid: !res.data.topicPriceDTOs[0].isFree,
                  fullPrice: res.data.topicPriceDTOs[0].price ?? 0,
                  discountPrice: res.data.topicPriceDTOs[0].discountPrice ?? 0,
                  currency: res?.data?.topicPriceDTOs
                    ? res?.data?.topicPriceDTOs[0]?.currency?.toUpperCase() ?? "GBP"
                    : "GBP",
                },
              },
              () => {
                this.getSignedURL();
              }
            );
          }
        })
        .catch((err) => {
          return [];
        });
    }
  };

  handleChange(e: any) {
    this.setState({
      pageObj: {
        ...this.state.pageObj,
        [e.target.name]: e.target.value,
      },
    });
  }
  handleChangeOrderNo(e: any) {
    this.setState({
      pageObj: { ...this.state.pageObj, orderNo: e.value },
    });
  }
  handleChangeForRadio(e: any) {
    this.setState({
      pageObj: {
        ...this.state.pageObj,
        isPaid: SECTION_PRICE.PAID === e.value,
      },
    });
  }
  handleChangeDiscountedPrice = (e: any) => {
    if (e.value <= this.state.pageObj.fullPrice) {
      this.setState({
        pageObj: { ...this.state.pageObj, discountPrice: e.value },
      });
    } else {
      this.setState({
        pageObj: { ...this.state.pageObj, discountPrice: null },
      });
    }
  };

  // handleEditorChange = (content: string) => {};

  handleChangeFullPrice(e: any) {
    this.setState({
      pageObj: { ...this.state.pageObj, fullPrice: e.value },
    });
  }
  handlePaste(e: any) {
    const clipboardData = e.clipboardData;
    const pastedData = clipboardData.getData('Text');

    if (e.target.name === "description") {
      e.preventDefault();
      const currentText = this.state.pageObj.description;
      const newText = (currentText + pastedData).slice(0, 300);
      this.setState({
        pageObj: { ...this.state.pageObj, description: newText },
      });
    }
  }

  uploadImage = (e: any) => {
    this.setState({
      isImageUploading: true,
    });
    let file = e.files[0];
    FileUploadService.saveImage(file)
      .then((res) => {
        if (validatePostAPIResponse(res)) {
          this.setState(
            {
              isImageUploading: false,
              imageUploadMessage: "Image Uploaded Successfully",
              pageObj: {
                ...this.state.pageObj,

                imageUrl: res.data?.imageUrl,
              },
            },
            () => {
              setTimeout(() => {
                this.setState({
                  imageUploadMessage: "",
                });
              }, 3000);
            }
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isImageUploading: false,
            imageUploadMessage: "Image Upload Failed",
          },
          () => {
            setTimeout(() => {
              this.setState({
                imageUploadMessage: "",
              });
            }, 3000);
          }
        );
      });
  };

  uploadVideo = (e: any) => {
    this.setState({
      isVideoUploading: true,
    });
    let file = e.files[0];
    FileUploadService.saveVideo(file)
      .then((res) => {
        console.log(res);
        if (validatePostAPIResponse(res)) {
          this.setState(
            {
              pageObj: {
                ...this.state.pageObj,
                streamingVideoUrl: res.data?.videoUrl?.objectUrl,
                signedVideoUrl: res.data?.videoUrl?.signedUrl,
              },
            },
            () => {
              this.setState({
                isVideoUploading: false,
              });
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          isVideoUploading: false,
        });
      });
  };

  getSignedURL() {
    FileUploadService.getSignedURL(this.state.pageObj.streamingVideoUrl, 46466)
      .then((res) => {
        console.log(res);
        if (validateGetAPIResponse(res)) {
          this.setState({
            pageObj: {
              ...this.state.pageObj,
              signedVideoUrl: res.data.SignedUrl,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onSave = async () => {
    if (!(await this.validateForm())) {
      return;
    }
    let enrichedObj = this.enrichForCreateTutorial();

    if (this.props.isEditModule) {
      return TopicService.updatePublishTopic(enrichedObj, this.props.editModuleDetails?.uid)
        .then((res: any) => {
          if (validateGetAPIResponse(res)) {
            this.setState(
              {
                toastStyle: TOAST_STYLE_TYPE.PRIMARY,
                toastMessage: "Updated Tutorial Archives",
              },
              () => {
                this.props.onHide(true);
                this.toastRef.showFunction();
              }
            );
          }
        })
        .catch((err: any) => {
          this.setState(
            {
              toastStyle: TOAST_STYLE_TYPE.QUATERNARY,
              toastMessage: parseStandardAPIErrorMessageArray(err),
            },
            () => {
              this.toastRef.showFunction();
            }
          );
        });
    } else {
      return TopicService.createPublishTopic(enrichedObj)
        .then((res) => {
          this.props.onHide(true);
          this.setState({
            pageObj: {
              seriesTheme: "",
              seriesNumber: "",
              description: "",
              imageUrl: "",
              streamingVideoUrl: "",
              isPaid: false,
              fullPrice: 0,
              discountPrice: 0,
              orderNo: 0,
              currency: "GBP",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState(
            {
              toastStyle: TOAST_STYLE_TYPE.QUATERNARY,
              toastMessage: parseStandardAPIErrorMessageArray(err),
            },
            () => {
              this.toastRef.showFunction();
            }
          );
        });
    }
  };

  /**
   *
   * @returns
   */
  enrichForCreateTutorial() {
    let updatedTopicPriceDTOs = [];

    if (!this.state.pageObj.isPaid) {
      updatedTopicPriceDTOs.push({ isFree: true });
    } else {
      if (this.state.pageObj.discountPrice) {
        updatedTopicPriceDTOs.push({
          price: this.state.pageObj.fullPrice,
          discountPrice: this.state.pageObj.discountPrice,
          currency: DEFAULT_CURRENCY.GBP,
        });
      } else {
        updatedTopicPriceDTOs.push({
          price: this.state.pageObj.fullPrice,
          currency: DEFAULT_CURRENCY.GBP,
        });
      }
    }
    return {
      imageUrl: this.state.pageObj.imageUrl,
      thumbImageUrl: this.state.pageObj.imageUrl,
      description: this.state.pageObj.description,
      // description: this.editorRef.current?.editor?.getContent() ?? "",
      topicName: this.state.pageObj.seriesTheme.trim(),
      groupL0Code: "library",
      groupL1Code: CATEGORY_VALUE.TUTORIAL_ARCHIVE,
      streamingVideoUrl: this.state.pageObj.streamingVideoUrl,
      durationInSec: 0,
      seriesNo: this.state.pageObj.seriesNumber,
      orderNo: this.state.pageObj.orderNo,
      topicPriceDTOs: updatedTopicPriceDTOs,
    };
  }

  renderImageUploadStatus = () => {
    if (this.state.isImageUploading) {
      return <div className="font-BrownRegular text-base">Uploading...</div>;
    } else if (this.state.imageUploadMessage) {
      return <div className="font-BrownRegular text-base">{this.state.imageUploadMessage}</div>;
    } else {
      return <></>;
    }
  };

  render() {
    const { pageObj } = this.state;
    return (
      <div>
        <ToastCustom
          ref={(el) => (this.toastRef = el)}
          variant={this.state.toastStyle}
          position="bottom-right"
          toastText={this.state.toastMessage}
        />
        <SidebarDialog
          visible={this.props.visible}
          closable={false}
          modal
          className=" w-5/6 sm:w-1/2 "
          onHide={this.props.onHide}
          onShow={this.onShow}
        >
          <div className="flex flex-col md:flex-row justify-between items-center mt-12">
            <Text
              label={
                this.props.isEditModule
                  ? "label.admin.portal.button.tutorial.edit"
                  : "label.admin.portal.button.tutorial.create"
              }
              className="text-h2 font-BrownMedium"
            />
            <div className="flex flex-col md:flex-row items-center mt-4 md:mt-0">
              <div className="w-32 pr-3">
                <ButtonBox
                  label="Cancel"
                  onClick={() => {
                    this.setState(
                      {
                        pageObj: {
                          ...this.state.pageObj,
                        },
                      },
                      () => {
                        this.validator.hideMessages();
                        this.props.onHide("");
                      }
                    );
                  }}
                />
              </div>
              <div className="w-32">
                <ButtonBox
                  label="Save"
                  variant="contained"
                  color="primary"
                  onClickWithLoader={this.onSave}
                  disabled={this.state.isVideoUploading || this.state.isImageUploading}
                />
              </div>
            </div>
          </div>
          <div className=" flex flex-col md:flex-row mt-12 w-full">
            <div className="mx-0 w-1/2 mr-12">
              <InputNumber
                label={"No. *"}
                value={pageObj.orderNo}
                name="orderNo"
                onBlur={(e) => {
                  this.validate("orderNo");
                  this.setState({
                    pageObj: {
                      ...this.state.pageObj,
                      orderNo: e.target.value !== "" ? parseInt(e.target.value) : null,
                    },
                  });
                }}
                onChange={this.handleChangeOrderNo}
                validation={this.validator?.message("orderNo", pageObj.orderNo, "mandatory")}
                className="!flex"
              />
            </div>
            <div className="mx-0 w-1/2">
              <InputBox
                label={"label.admin.portal.tutorial.seriesNo"}
                value={pageObj.seriesNumber}
                name="seriesNumber"
                onBlur={() => {
                  this.validate("seriesNumber");
                }}
                onChange={this.handleChange}
                validation={this.validator?.message(
                  "seriesNumber",
                  pageObj.seriesNumber,
                  "mandatory"
                )}
                className="!flex"
              />
            </div>
          </div>
          <div className=" flex flex-col md:flex-row mt-8 w-full">
            <div className="mx-0 w-1/2 mr-12">
              <InputBox
                label={"label.admin.portal.tutorial.seriesTheme"}
                value={pageObj.seriesTheme}
                name="seriesTheme"
                onBlur={() => {
                  this.validate("seriesTheme");
                }}
                onChange={this.handleChange}
                validation={this.validator?.message(
                  "seriesTheme",
                  pageObj.seriesTheme,
                  "mandatory"
                )}
              />
            </div>
          </div>

          <div className=" flex flex-col md:flex-row w-full mt-8">
            <div className="w-full">
              <InputTextArea
                label="Description *"
                value={pageObj.description}
                name="description"
                onChange={this.handleChange}
                placeholder=""
                variant={"secondary"}
                maxCount={300}
                onPaste={this.handlePaste}
              />
              {/* <RichTextEditor
                label="Description *"
                ref={this.editorRef}
                initialValue={pageObj.description}
                onChange={this.handleEditorChange}
              /> */}
            </div>
          </div>
          <div className=" flex flex-col md:flex-row w-full mt-8">
            <FileUploadDemo
              uploadHandler={this.uploadImage}
              infoText="Make sure the size of image is 600px * 240px"
              accept="image/*"
              label="Image *"
              onSelect={this.uploadImage}
              onClear={() => {
                this.setState({
                  pageObj: {
                    ...this.state.pageObj,
                    imageUrl: "",
                  },
                });
              }}
              buttonVariant="chooseOnly"
              imageSrcOnLoad={pageObj.imageUrl}
              className="!m-0 !w-full !my-2 !h-48 !border !border-secondary-blueAzure-100 !bg-secondary-blueAzure-50 !rounded-lg"
              validation={this.validator?.message(
                "imageUrl",
                this.state.pageObj.imageUrl,
                "mandatory"
              )}
            />
          </div>
          {this.renderImageUploadStatus()}
          <div className=" flex flex-col md:flex-row w-full mt-8">
            <FileUploadDemo
              uploadHandler={this.uploadVideo}
              infoText="Make sure the aspect ratio of video is 16 : 9"
              accept="video/*"
              label="Video"
              onSelect={this.uploadVideo}
              buttonVariant="chooseOnly"
              videoUrlOnLoad={this.state.pageObj.signedVideoUrl}
              signedUrlOnLoad={this.state.pageObj.signedVideoUrl}
              className="!m-0 !w-85 !my-2 !h-48 !border !border-secondary-blueAzure-100 !bg-secondary-blueAzure-50 !rounded-lg"
              uploadType="video"
              onClear={() => {
                this.setState({
                  pageObj: {
                    ...this.state.pageObj,
                    streamingVideoUrl: "",
                    signedVideoUrl: "",
                  },
                });
              }}
            />
          </div>
          {this.state.isVideoUploading && (
            <p className="font-BrownRegular text-base" id="progress">
              Uploading...
            </p>
          )}
          <div className="flex flex-col md:flex-row w-full mt-12">
            <div className="w-60 flex justify-between">
              <RadioButton
                value={SECTION_PRICE.PAID}
                label={"label.admin.portal.session.paid"}
                name="sectionPrice"
                checked={pageObj.isPaid}
                onChange={this.handleChangeForRadio}
              />
              <RadioButton
                value={SECTION_PRICE.FREE}
                label={"label.admin.portal.session.free"}
                name="sectionPrice"
                checked={!pageObj.isPaid}
                onChange={this.handleChangeForRadio}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row w-full mt-8">
            <div className="mx-0 sm:w-66 w-76 mb-10.5 mr-12">
              <InputNumber
                label={"label.admin.portal.input.fullPrice"}
                value={pageObj.fullPrice}
                name="fullPrice"
                onBlur={(e: any) => {
                  this.validate("fullPrice");
                  this.setState({
                    pageObj: {
                      ...this.state.pageObj,
                      fullPrice:
                        e.target.value !== "" ? parseFloat(e.target.value.substring(1)) : null,
                    },
                  });
                }}
                onChange={this.handleChangeFullPrice}
                validation={this.validator?.message(
                  "fullPrice",
                  pageObj.fullPrice,
                  this.state.pageObj.isPaid ? "mandatory" : "numeric"
                )}
                mode="currency"
                currency={pageObj.currency}
                className="!flex !mt-3"
                disabled={!pageObj.isPaid}
              />
            </div>
            <div className="mx-0 sm:w-66 w-76 mb-10.5 mr-8">
              <InputNumber
                label={"label.admin.portal.input.discountedPrice"}
                value={pageObj.discountPrice}
                name="discountedPrice"
                onChange={this.handleChangeDiscountedPrice}
                onBlur={(e: any) => {
                  this.setState({
                    pageObj: {
                      ...this.state.pageObj,
                      discountPrice:
                        e.target.value !== "" ? parseFloat(e.target.value.substring(1)) : null,
                    },
                  });
                }}
                mode="currency"
                currency={pageObj.currency}
                className="!flex !mt-3"
                disabled={!pageObj.isPaid}
              />
            </div>
          </div>
        </SidebarDialog>
      </div>
    );
  }
}

export default PageCreateTutorial;
