import { IRouteObj } from "interface/common";
import React from "react";

//base
export const linkBasePath = "/dashboard";

/* 
    -----------------------------------------------------------------------------------------------------------------------
    No Auth routes : 
    -----------------------------------------------------------------------------------------------------------------------
*/
export const linkPageBase = "/";
export const linkPageLogin = "/login";
export const linkPageSignUpIndividual = "/signup/individual";
export const linkPageSignUpOrganisation = "/signup/organisation";
export const linkPageVerifyOTP = "/verifyOTP";
export const linkPageBillingIndividual = "/billingIndividual";
export const linkPageBillingOrganisation = "/billingOrganisation";
export const linkPageForgotPassword = "/recoverPassword";
export const linkPageResetPassword = "/resetPassword";
export const linkPageChangePassword = "/changePassword";
export const linkPagePaymentSuccessful = "/billing/paymentSuccessInfo";
export const linkPageUnsubscribeSuccessful = "/unsubscribed";

/* 
    -----------------------------------------------------------------------------------------------------------------------
    Links : 
    -----------------------------------------------------------------------------------------------------------------------
*/
export const linkAuthRoute = "/console";
export const linkPageView1 = "/view1";
export const linkPageView2 = "/view2";
export const linkPageView3 = "/view3";
export const linkPageView4 = "/view4";

// ------------------------- OnBoarding Links ------------------------------------ //
export const linkOnboardingBasePath = "/onboarding";
export const linkCorporateBasePath = linkOnboardingBasePath + "/corporate";
export const linkIndividualBasePath = linkOnboardingBasePath + "/individual";

// ----------->>>>> Corporate admin <<<<<<<--------------- //
export const linkPageCorporateAdmin = linkCorporateBasePath + "/admin";
export const linkPageCorporateAdminSetupProfile = linkPageCorporateAdmin + "/setupProfile";
export const linkPageCorporateAdminInviteTeam = linkPageCorporateAdmin + "/inviteTeam";
export const linkPageCorporateAdminOverview = linkPageCorporateAdmin + "/overview";

// ----------->>>>> Corporate learner <<<<<<<------------- //
export const linkPageCorporateLearner = linkCorporateBasePath + "/learner";
export const linkPageCorporateLearnerSetupProfile = linkPageCorporateLearner + "/setupProfile";
export const linkPageCorporateLearnerOverview = linkPageCorporateLearner + "/overview";

// ----------->>>>> Corporate Admin and learner <<<<<<<------------- //
export const linkPageCorporateAdminLearner = linkCorporateBasePath + "/adminLearner";
export const linkPageCorporateAdminLearnerSetupProfile =
  linkPageCorporateAdminLearner + "/setupProfile";
export const linkPageCorporateAdminLearnerOverview = linkPageCorporateAdminLearner + "/overview";

// ----------->>>>> Individual learner <<<<<<<------------- //
export const linkPageIndividualLearnerSetupProfile = linkIndividualBasePath + "/setupProfile";
export const linkPageIndividualLearnerOverview = linkIndividualBasePath + "/overview";

export const linkOasis = "/oasis";

// ------------------------- Home ------------------------------------ //
export const linkHomePath = linkOasis + "/home";
export const linkPageFoundation = linkOasis + "/foundation";
export const linkPageModules = linkOasis + "/modules";
export const linkPageModuleDetails = linkPageModules + "/moduleDetails";

//----------------------------Learn Topics-------------------------------//
export const linkLearningBasePath = "/learning";
export const linkPageLearningGuide = linkLearningBasePath + "/guide";
export const linkPageLearningConcept = linkLearningBasePath + "/concept";
export const linkPageLearningPractice = linkLearningBasePath + "/practice";
export const linkPageLearningConceptSelfReflection = linkPageLearningConcept + "/selfReflection";
export const linkPageLearningPracticeSelfReflection = linkPageLearningPractice + "/selfReflection";
export const linkPageLearningApplication = linkLearningBasePath + "/application";

export const linkPageHomeCurriculum = linkHomePath + "/curriculum";
export const linkPageCurriculum = linkPageModules + "/curriculum";

export const linkPageCart = "/cart";

export const linkPageBillingCart = linkPageCart + "/billing";

export const linkPageBillingCartSuccessInfo = linkPageBillingCart + "/paymentSuccess";

export const linkPageTutorials = linkOasis + "/tutorials";

export const linkPageLibrary = linkOasis + "/library";

export const linkPageLibrarySection = linkPageLibrary + "/section";

export const linkPageLibraryCardDetails = linkPageLibrarySection + "/cardDetails";

export const linkPageMyStudy = linkOasis + "/myStudy";

export const linkPageMyStudySection = linkPageMyStudy + "/section";

export const linkPageMyStudyCardDetails = linkPageMyStudySection + "/cardDetails";

export const linkPageAnalytics = linkOasis + "/analytics";

export const linkPageFoundationReport = linkPageAnalytics + "/foundationReport";

export const linkPageModulesReport = linkPageAnalytics + "/modulesReport";

// ADMIN
export const linkOrgAdmin = "/organisationAdmin";
export const linkPageTeam = linkOrgAdmin + "/team";

export const linkPageTeamAnalytics = linkOrgAdmin + "/adminTeamAnalytics";

export const linkPageTeamFoundationReport = linkPageTeamAnalytics + "/teamFoundationReport";

export const linkPageTeamModulesReport = linkPageTeamAnalytics + "/teamModulesReport";

export const linkPageTeamFoundationScore = linkPageTeamFoundationReport + "/teamFoundationScore";

export const linkPageTeamModulesScore = linkPageTeamModulesReport + "/teamModulesScore";

//--------------------------user settings------------------------//

export const linkUserAccountBasePath = "/user/account";
export const linkPageUserProfile = linkUserAccountBasePath + "/profile";
export const linkPageUserSubscription = linkUserAccountBasePath + "/subscription";
export const linkPageUpdateCardInfo = linkPageUserSubscription + "/updateCard";
export const linkPageUserChangePassword = linkPageUserProfile + "/changePassword";

export const linkPageContent = "/content";
export const linkPageVideo = linkPageContent + "/video";

export const linkPageMyStudyLearning = "/learn";
export const linkPageVideoMyStudy = linkPageMyStudyLearning + "/video/myStudy";

export const linkPageArticle = linkPageMyStudyLearning + "/article";

//--------------------------Mapping------------------------//

export const linkPageMapping = linkOasis + "/mapping";

export const linkPageMappingOverview = linkPageMapping + "/overview";

export const linkPageMappingResults = linkPageMapping + "/results";

export const linkPageMappingAllMaps = linkPageMappingResults + "/allMaps";

export const linkPageMappingAllMapsCardInfo = linkPageMappingAllMaps + "/cardInfo";

export const linkPageMappingExploration = linkPageMappingResults + "/exploration";

export const linkPageMappingExercise = linkPageMapping + "/exercise";

export const linkPageMappingInstructions = linkPageMappingOverview + "/instructions";
/* 
    -----------------------------------------------------------------------------------------------------------------------
    LinkNames : 
    -----------------------------------------------------------------------------------------------------------------------
*/

export const linkNamePageLogin = "Login";
export const linkNamePageView1 = "View1";
export const linkNamePageView2 = "View2";
export const linkNamePageView3 = "View3";
export const linkNamePageView4 = "View4";
export const linkNamePageSetupProfile = "Setup Profile";
export const linkNamePageInviteTeam = "Invite Team";
export const linkNamePageOverview = "Quick Overview";
export const linkNamePageHome = "Home";
export const linkNamePageFoundation = "Foundation";
export const linkNamePageModules = "Modules";
export const linkNamePageModuleDetails = "Individual Module Details";
export const linkNamePageCurriculum = "Curriculum";
export const linkNamePageHomeCurriculum = "The OASIS Curriculum";
export const linkNamePageLibrary = "Library";
export const linkNamePageLearningGuide = "LearningGuide";
export const linkNamePageLearningConcept = "LearningConcept";
export const linkNamePageLearningPractice = "LearningPractice";
export const linkNamePageLearningSelfReflection = "LearningSelfReflection";
export const linkNamePageLearningApplication = "LearningApplication";
export const linkNamePageTutorials = "Tutorials";
export const linkNamePageAnalytics = "Analytics";
export const linkNamePageFoundationReport = "Foundation Report";
export const linkNamePageModulesReport = "Module Report";
export const linkNamePageLibrarySection = "Library Individual Section";
export const linkNamePageLibraryCardDetails = "Library Individual Section Card Details";
export const linkNamePageCart = "Cart";
export const linkNamePageBillingCart = "Billing Cart";
export const linkNamePageBillingCartSuccessInfo = "Billing Cart Success Info";
export const linkNamePageMyStudy = "My Study";
export const linkNamePageMyStudySection = "My Study Section";
export const linkNamePageMyStudyCardDetails = "My Study Card Details";
export const linkNamePageUserProfile = "User Profile";
export const linkNamePageUserSubscription = "User Subscription";
export const linkNamePageUpdateCardInfo = "User Card Update";
export const linkNamePageUserChangePassword = "Account Password Change";
export const linkNamePageVideo = "Watch video";
export const linkNamePageArticle = "Read Article";
export const linkNamePageTeamAnalytics = "Team Analytics";
export const linkNamePageTeamFoundationReport = "Team Foundation Report";
export const linkNamePageTeamModulesReport = "Team Module Report";
export const linkNamePageTeamFoundationScore = "Team score in Foundation";
export const linkNamePageTeamModulesScore = "Team score in Modules";
export const linkNamePageTeam = "Team";

//--------------------------Mapping------------------------//
export const linkNamePageMapping = "Mapping";
export const linkNamePageMappingOverview = "Mapping overview";
export const linkNamePageMappingResults = "Mapping results";
export const linkNamePageMappingAllMaps = "Mapping results all maps";
export const linkNamePageMappingAllMapsCardInfo = "Mapping results all maps card info";
export const linkNamePageMappingExploration = "Mapping 3D exploration";
export const linkNamePageMappingExercise = "Mapping Exercise";
export const linkNamePageMappingInstructions = "Mapping Exercise Instructions";
/* 
    -----------------------------------------------------------------------------------------------------------------------
    Imports : import files from views
    -----------------------------------------------------------------------------------------------------------------------
*/
const View1 = React.lazy(() => import("./views/View1"));
const View2 = React.lazy(() => import("./views/View2"));
const View3 = React.lazy(() => import("./views/View3"));
const View4 = React.lazy(() => import("./views/View4"));

const PageCorporateAdminSetupProfile = React.lazy(
  () => import("./views/onboarding/corporateAdmin/PageSetupProfile")
);

const PageCorporateAdminInviteTeam = React.lazy(
  () => import("./views/onboarding/corporateAdmin/PageInviteTeam/PageInviteTeam")
);

const PageCorporateAdminOverview = React.lazy(
  () => import("./views/onboarding/corporateAdmin/PageOverview")
);

const PageCorporateLearnerSetupProfile = React.lazy(
  () => import("./views/onboarding/corporateLearner/PageSetupProfile")
);

const PageCorporateLearnerOverview = React.lazy(
  () => import("./views/onboarding/corporateLearner/PageOverview")
);

const PageCorporateAdminLearnerSetupProfile = React.lazy(
  () => import("./views/onboarding/corporateAdminLearner/PageSetupProfile")
);

const PageCorporateAdminLearnerOverview = React.lazy(
  () => import("./views/onboarding/corporateAdminLearner/PageOverview")
);

const PageIndividualLearnerSetupProfile = React.lazy(
  () => import("./views/onboarding/individualLearner/PageSetupProfile")
);

const PageIndividualLearnerOverview = React.lazy(
  () => import("./views/onboarding/individualLearner/PageOverview")
);

const PageFoundation = React.lazy(() => import("./views/PageFoundation/PageFoundation"));

const PageModules = React.lazy(() => import("./views/PageModules/PageModules"));

const PageModuleDetails = React.lazy(() => import("./views/PageModuleDetails/PageModuleDetails"));

const PageCurriculum = React.lazy(() => import("./views/PageCurriculum/PageCurriculum"));

const PageCart = React.lazy(() => import("./views/PageCart/PageCart"));

const PageBillingCart = React.lazy(() => import("./views/PageBillingCart/PageBillingCart"));

const PageBillingCartSuccessInfo = React.lazy(
  () => import("./views/PageBillingCartSuccessInfo/PageBillingCartSuccessInfo")
);

const PageLibrary = React.lazy(() => import("./views/PageLibrary/PageLibrary"));

const PageLibrarySection = React.lazy(
  () => import("./views/PageLibrarySection/PageLibrarySection")
);

const PageLibraryCardDetails = React.lazy(
  () => import("./views/PageLibraryCardDetails/PageLibraryCardDetails")
);

const PageTutorials = React.lazy(() => import("./views/PageTutorials/PageTutorials"));

const PageAnalytics = React.lazy(() => import("./views/analytics/PageAnalytics/PageAnalytics"));

const PageFoundationReport = React.lazy(
  () => import("./views/analytics/PageFoundationReport/PageFoundationReport")
);

const PageModulesReport = React.lazy(
  () => import("./views/analytics/PageModulesReport/PageModulesReport")
);

const PageTeamAnalytics = React.lazy(
  () => import("./views/analytics/PageTeamAnalytics/PageTeamAnalytics")
);

const PageTeamFoundationReport = React.lazy(
  () => import("./views/analytics/PageTeamFoundationReport/PageTeamFoundationReport")
);

const PageTeamModulesReport = React.lazy(
  () => import("./views/analytics/PageTeamModulesReport/PageTeamModulesReport")
);

const PageTeamFoundationScore = React.lazy(
  () => import("./views/analytics/PageTeamFoundationScore/PageTeamFoundationScore")
);

const PageTeamModulesScore = React.lazy(
  () => import("./views/analytics/PageTeamModulesScore/PageTeamModulesScore")
);

const PageTeam = React.lazy(() => import("./views/analytics/PageTeam/PageTeam"));

const PageLearningGuide = React.lazy(() => import("./views/PageLearningGuide/PageLearningGuide"));

const PageLearningConcept = React.lazy(
  () => import("./views/PageLearningConcept/PageLearningConcept")
);
const PageLearningPractice = React.lazy(
  () => import("./views/PageLearningPractice/PageLearningPractice")
);
const PageLearningSelfReflection = React.lazy(
  () => import("./views/PageLearningSelfReflection/PageLearningSelfReflection")
);
const PageLearningApplication = React.lazy(
  () => import("./views/PageLearningApplication/PageLearningApplication")
);
const PageMyStudy = React.lazy(() => import("./views/PageMyStudy/PageMyStudy"));

const PageMyStudySection = React.lazy(
  () => import("./views/PageMyStudySection/PageMyStudySection")
);

const PageMyStudyCardDetails = React.lazy(
  () => import("./views/PageMyStudyCardDetails/PageMyStudyCardDetails")
);

const PageUserProfile = React.lazy(() => import("./views/user/PageUserProfile/PageUserProfile"));

const PageUserSubscription = React.lazy(
  () => import("./views/user/PageUserSubscription/PageUserSubscription")
);

const PageUpdateCardInfo = React.lazy(
  () => import("./views/user/PageUpdateCardInfo/PageUpdateCardInfo")
);

const PageUserChangePassword = React.lazy(
  () => import("./views/user/PageUserChangePassword/PageUserChangePassword")
);

const PageVideo = React.lazy(() => import("./views/PageVideo/PageVideo"));
const PageVideoMyStudy = React.lazy(() => import("./views/PageVideoMyStudy"));

const PageArticle = React.lazy(() => import("./views/PageArticle/PageArticle"));

// ----- testing ------ //
const PageHome = React.lazy(() => import("./views/home/PageHome"));

//--------------------------Mapping------------------------//

const PageMappingHome = React.lazy(() => import("./views/mapping/PageMappingHome/PageMappingHome"));

const PageMappingOverview = React.lazy(
  () => import("./views/mapping/PageMappingOverview/PageMappingOverview")
);

const PageMappingResults = React.lazy(
  () => import("./views/mapping/PageMappingResults/PageMappingResults")
);

const PageMappingAllMaps = React.lazy(
  () => import("./views/mapping/PageMappingResultsMaps/PageMappingResultsMaps")
);

const PageMappingAllMapsCardInfo = React.lazy(
  () => import("./views/mapping/PageAllMapsCardInfo/PageAllMapsCardInfo")
);

const PageMappingExploration = React.lazy(
  () => import("./views/mapping/PageMappingExploration/PageMappingExploration")
);

const PageMappingExercise = React.lazy(
  () => import("./views/mapping/PageMappingExercise/PageMappingExercise")
);

const PageMappingInstructions = React.lazy(
  () => import("./views/mapping/PageMappingInstructions/PageMappingInstructions")
);
/* 
    -----------------------------------------------------------------------------------------------------------------------
    Routes : All auth routes
    -----------------------------------------------------------------------------------------------------------------------
*/
const routes: IRouteObj[] = [
  { path: linkPageView1, exact: true, name: linkNamePageView1, component: View1 },
  { path: linkPageView2, name: linkNamePageView2, component: View2 },
  { path: linkPageView3, name: linkNamePageView3, component: View3 },
  { path: linkPageView4, name: linkNamePageView4, component: View4 },

  // Corporate Admin
  {
    path: linkPageCorporateAdminSetupProfile,
    name: linkNamePageSetupProfile,
    component: PageCorporateAdminSetupProfile,
  },
  {
    path: linkPageCorporateAdminInviteTeam,
    name: linkNamePageInviteTeam,
    component: PageCorporateAdminInviteTeam,
  },

  {
    path: linkPageCorporateAdminOverview,
    name: linkNamePageOverview,
    component: PageCorporateAdminOverview,
  },

  // Corporate Admin Learner
  {
    path: linkPageCorporateAdminLearnerSetupProfile,
    name: linkNamePageSetupProfile,
    component: PageCorporateAdminSetupProfile,
  },
  {
    path: linkPageCorporateAdminLearnerOverview,
    name: linkNamePageOverview,
    component: PageCorporateAdminLearnerOverview,
  },

  // Corporate Learner
  {
    path: linkPageCorporateLearnerSetupProfile,
    name: linkNamePageSetupProfile,
    component: PageCorporateAdminSetupProfile,
  },
  {
    path: linkPageCorporateLearnerOverview,
    name: linkNamePageOverview,
    component: PageCorporateLearnerOverview,
  },

  //  Individual Learner
  {
    path: linkPageIndividualLearnerSetupProfile,
    name: linkNamePageSetupProfile,
    component: PageCorporateAdminSetupProfile,
  },
  {
    path: linkPageIndividualLearnerOverview,
    name: linkNamePageOverview,
    component: PageIndividualLearnerOverview,
  },

  // dashboard
  {
    path: linkHomePath,
    name: linkNamePageHome,
    component: PageHome,
  },
  {
    path: linkPageFoundation,
    name: linkNamePageFoundation,
    component: PageFoundation,
  },
  {
    path: linkPageModules,
    name: linkNamePageModules,
    component: PageModules,
  },
  {
    path: linkPageModuleDetails,
    name: linkNamePageModuleDetails,
    component: PageModuleDetails,
  },
  {
    path: linkPageCurriculum,
    name: linkNamePageCurriculum,
    component: PageCurriculum,
  },

  {
    path: linkPageHomeCurriculum,
    name: linkNamePageHomeCurriculum,
    component: PageCurriculum,
  },

  {
    path: linkPageCart,
    name: linkNamePageCart,
    component: PageCart,
  },
  {
    path: linkPageBillingCart,
    name: linkNamePageBillingCart,
    component: PageBillingCart,
  },
  {
    path: linkPageBillingCartSuccessInfo,
    name: linkNamePageBillingCartSuccessInfo,
    component: PageBillingCartSuccessInfo,
  },
  {
    path: linkPageLibrary,
    name: linkNamePageLibrary,
    component: PageLibrary,
  },
  {
    path: linkPageLibrarySection,
    name: linkNamePageLibrarySection,
    component: PageLibrarySection,
  },
  {
    path: linkPageLibraryCardDetails,
    name: linkNamePageLibraryCardDetails,
    component: PageLibraryCardDetails,
  },
  {
    path: linkPageTutorials,
    name: linkNamePageTutorials,
    component: PageTutorials,
  },
  {
    path: linkPageAnalytics,
    name: linkNamePageAnalytics,
    component: PageAnalytics,
  },
  {
    path: linkPageFoundationReport,
    name: linkNamePageFoundationReport,
    component: PageFoundationReport,
  },
  {
    path: linkPageModulesReport,
    name: linkNamePageModulesReport,
    component: PageModulesReport,
  },
  {
    path: linkPageTeamAnalytics,
    name: linkNamePageTeamAnalytics,
    component: PageTeamAnalytics,
  },
  {
    path: linkPageTeamFoundationReport,
    name: linkNamePageTeamFoundationReport,
    component: PageTeamFoundationReport,
  },
  {
    path: linkPageTeamModulesReport,
    name: linkNamePageTeamModulesReport,
    component: PageTeamModulesReport,
  },
  {
    path: linkPageTeamFoundationScore,
    name: linkNamePageTeamFoundationScore,
    component: PageTeamFoundationScore,
  },
  {
    path: linkPageTeamModulesScore,
    name: linkNamePageTeamModulesScore,
    component: PageTeamModulesScore,
  },
  {
    path: linkPageTeam,
    name: linkNamePageTeam,
    component: PageTeam,
  },
  {
    path: linkPageLearningGuide,
    name: linkNamePageLearningGuide,
    component: PageLearningGuide,
  },
  {
    path: linkPageLearningConcept,
    name: linkNamePageLearningConcept,
    component: PageLearningConcept,
  },
  {
    path: linkPageLearningPractice,
    name: linkNamePageLearningPractice,
    component: PageLearningPractice,
  },
  {
    path: linkPageLearningConceptSelfReflection,
    name: linkNamePageLearningSelfReflection,
    component: PageLearningSelfReflection,
  },
  {
    path: linkPageLearningPracticeSelfReflection,
    name: linkNamePageLearningSelfReflection,
    component: PageLearningSelfReflection,
  },
  {
    path: linkPageLearningApplication,
    name: linkNamePageLearningPractice,
    component: PageLearningApplication,
  },
  {
    path: linkPageMyStudy,
    name: linkNamePageMyStudy,
    component: PageMyStudy,
  },
  {
    path: linkPageMyStudySection,
    name: linkNamePageMyStudySection,
    component: PageMyStudySection,
  },
  {
    path: linkPageMyStudyCardDetails,
    name: linkNamePageMyStudyCardDetails,
    component: PageMyStudyCardDetails,
  },
  {
    path: linkPageUserProfile,
    name: linkNamePageUserProfile,
    component: PageUserProfile,
  },
  {
    path: linkPageUserSubscription,
    name: linkNamePageUserSubscription,
    component: PageUserSubscription,
  },
  {
    path: linkPageUserChangePassword,
    name: linkNamePageUserChangePassword,
    component: PageUserChangePassword,
  },
  {
    path: linkPageUpdateCardInfo,
    name: linkNamePageUpdateCardInfo,
    component: PageUpdateCardInfo,
  },
  {
    path: linkPageVideo,
    name: linkNamePageVideo,
    component: PageVideo,
  },
  {
    path: linkPageVideoMyStudy,
    component: PageVideoMyStudy,
  },
  {
    path: linkPageArticle,
    name: linkNamePageArticle,
    component: PageArticle,
  },
  //--------------------------Mapping------------------------//
  {
    path: linkPageMapping,
    name: linkNamePageMapping,
    component: PageMappingHome,
  },
  {
    path: linkPageMappingOverview,
    name: linkNamePageMappingOverview,
    component: PageMappingOverview,
  },
  {
    path: linkPageMappingResults,
    name: linkNamePageMappingResults,
    component: PageMappingResults,
  },
  {
    path: linkPageMappingAllMaps,
    name: linkNamePageMappingAllMaps,
    component: PageMappingAllMaps,
  },
  {
    path: linkPageMappingAllMapsCardInfo,
    name: linkNamePageMappingAllMapsCardInfo,
    component: PageMappingAllMapsCardInfo,
  },
  {
    path: linkPageMappingExploration,
    name: linkNamePageMappingExploration,
    component: PageMappingExploration,
  },
  {
    path: linkPageMappingExercise,
    name: linkNamePageMappingExercise,
    component: PageMappingExercise,
  },
  {
    path: linkPageMappingInstructions,
    name: linkNamePageMappingInstructions,
    component: PageMappingInstructions,
  },
];

export default routes;
